import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from '../../axios/axios';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { storage } from '../../firebase/firebase';
import DefaultImage from '../../images/default.jpg';
import Snackbar, { openSnackbar } from '../utility/Notifier';
import { getQueryStringValue } from '../../functions';
import 'react-quill/dist/quill.snow.css'
import Wysiwyg from './Wysiwyg'
import moment from 'moment';

const formatDate = function (date) {

    let year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    if (month.length < 2) {
        month = '0' + month
    }
    if (day.length < 2) {
        day = '0' + day
    }
    return year + "-" + month + "-" + day;
}

const styles = theme => ({
    root: {

    },
    container: {
        width: '75%',
        margin: '10px auto'
    },
    blogImage: {
        width: '100%'
    },
    textField: {
        margin: '10px 0'
    },
    headerText: {
        margin: '10px 0',
        fontSize: '50px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px'
        }
    },
    sectionList: {
        margin: '0 20px',
        height: '30px',
        borderRadius: '2px',
    },
    sectionAndAuthor: {
        textAlign: 'center',
        margin: '20px 0'
    },
    button: {
        textAlign: 'right',
        margin: '10px 0',
        marginLeft: '10px'
    },
    tag: {
        display: 'inline-block',
        margin: '10px',
        padding: '5px',
        fontSize: '16px',
        backgroundColor: '#D3D3D3',
        borderRadius: '3px',
        border: '1px solid #29B5C3'
    }
});

class BlogPost extends Component {

    state = {
        selectedFile: '',
        imageUrl: DefaultImage,
        title: '',
        content: '',
        token: sessionStorage.getItem('jwtToken'),
        imageChanged: false,
        theme: 'snow',
        sectionName: '',
        createdAt: formatDate(new Date()),

        sections: [],
        sectionId: '',

        authors: [],
        author: '',
        tags: [],
        tag: '',
        disableSubmit: false
    }

    fileSelectorHandler = (event) => {
        this.setState({
            imageChanged: true,
            selectedFile: event.target.files[0],
            imageUrl: URL.createObjectURL(event.target.files[0])
        });
    }

    handleChange = html => {
        this.setState({ content: html })
    }

    onInpuFieldChange = (event) => {
        if (event.target.name === "createdAt") {
            this.setState({
                createdAt: event.target.value
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    componentDidMount() {
        const updateId = getQueryStringValue("blog")
        if (updateId) {
            this.fetchBlog(updateId);
        }
        this.fetchSections();
        this.fetchAuthors();
    }

    fetchBlog = (id) => {
        axios.get('blog/blog', { params: { blog: id }, headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState({
                            title: result.data.blog.title,
                            content: result.data.blog.content,
                            author: result.data.blog.author,
                            tags: result.data.blog.tags,
                            sectionName: result.data.blog.sectionName,
                            sectionId: {
                                id: result.data.blog.section,
                                name: result.data.blog.sectionName
                            },
                            imageUrl: result.data.blog.imageURL,
                            createdAt: formatDate(new Date(result.data.blog.createdAt))
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
    }

    onTagAdd = () => {
        const { tags, tag } = this.state;
        let blogTag = tag.trim();

        blogTag = blogTag.replace(/,/g, "");

        if (!blogTag) {
            return;
        }

        this.setState({
            tags: Array.from(new Set([...tags, blogTag.toLowerCase()])),
            tag: ""
        });
    }

    deleteTag = (i) => {
        let oldTags = [...this.state.tags];
        oldTags.splice(i, 1);
        this.setState({
            tags: oldTags
        });
    }

    fetchSections = () => {
        axios.get('blog/fetchSection', { headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState({
                            sections: result.data.sections
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
    }

    fetchAuthors = () => {
        axios.get('blog/fetchAuthor', { headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState({
                            authors: result.data.authors
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
    }

    onSectionChange = (e) => {
        this.setState({
            sectionId: JSON.parse(e.target.value)
        });
    }

    onAuthorSelect = (e) => {
        this.setState({
            author: JSON.parse(e.target.value)
        });
    }

    saveBlog = (e) => {
        e.preventDefault();
        this.setState({
            disableSubmit: true
        });
        let BLOG_POST_URL = "blog/insert";

        if (this.state.title === "") {
            this.setState({
                disableSubmit: true
            });
            openSnackbar({ message: "Please enter title", variant: "info" });
        } else if (this.state.content === "") {
            this.setState({
                disableSubmit: true
            });
            openSnackbar({ message: "Please enter blog content", variant: "info" });
        } else {

            const updateId = getQueryStringValue("blog");
            if (updateId) {
                BLOG_POST_URL = "blog/update";
            }
            if (!this.state.imageChanged) {
                let data = {
                    id: updateId,
                    title: this.state.title,
                    imageURL: this.state.imageUrl,
                    content: this.state.content,
                    section: this.state.sectionId.id,
                    sectionName: this.state.sectionId.name,
                    author: this.state.author,
                    tags: this.state.tags,
                    createdAt: moment(this.state.createdAt).format()
                }

                axios.post('blog/update', data, {
                    headers: {
                        authorization: `Bearer ${this.state.token}`
                    }
                }).then(result => {
                    if (result.status === 200) {
                        if (result.data.success) {
                            this.props.history.goBack();
                        } else {
                            this.setState({
                                disableSubmit: false
                            });
                            openSnackbar({ message: result.data.msg, variant: "error" });
                        }
                    } else {
                        this.setState({
                            disableSubmit: false
                        });
                        openSnackbar({ message: "Something went wrong", variant: "error" });
                    }
                }).catch(exception => {
                    this.setState({
                        disableSubmit: false
                    });
                    if (exception.msg === undefined) {
                        openSnackbar({ message: "Something went wrong", variant: "error" });
                    } else {
                        openSnackbar({ message: exception.msg, variant: "error" });
                    }
                });
            } else {
                const currentDate = new Date();

                const image = this.state.selectedFile;
                const uploadFile = storage.ref(`blog_images/${image.name + "-" + currentDate}`).put(image);
                uploadFile.on('state_changed', (snapshot) => {
                    openSnackbar({ message: "Uploading Image", variant: "info" });
                }, (err) => {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                    this.setState({
                        disableSubmit: false
                    });
                }, () => {
                    storage.ref('blog_images').child(image.name + "-" + currentDate).getDownloadURL()
                        .then(url => {
                            let data = {
                                title: this.state.title,
                                imageURL: url,
                                content: this.state.content,
                                section: this.state.sectionId.id,
                                sectionName: this.state.sectionId.name,
                                author: this.state.author,
                                tags: this.state.tags,
                                createdAt: moment(this.state.createdAt).format()
                            }
                            if (updateId) {
                                data.id = updateId;
                            }

                            axios.post(BLOG_POST_URL, data, {
                                headers: {
                                    authorization: `Bearer ${this.state.token}`
                                }
                            }).then(result => {
                                if (result.status === 200) {
                                    if (result.data.success) {
                                        this.props.history.goBack();
                                    } else {
                                        this.setState({
                                            disableSubmit: false
                                        });
                                        openSnackbar({ message: result.data.msg, variant: "error" });
                                    }
                                } else {
                                    this.setState({
                                        disableSubmit: false
                                    });
                                    openSnackbar({ message: "Something went wrong", variant: "error" });
                                }
                            }).catch(exception => {
                                this.setState({
                                    disableSubmit: false
                                });
                                if (exception.msg === undefined) {
                                    openSnackbar({ message: "Something went wrong", variant: "error" });
                                } else {
                                    openSnackbar({ message: exception.msg, variant: "error" });
                                }
                            });
                        });
                });
            }


        }
    }

    render() {
        const { classes } = this.props;
        // let formClasses = [classes.containerInput, "container"].join(" ");
        return (
            <section className={classes.container}>
                <Snackbar />
                <Typography variant="h1" className={classes.headerText}>New Blog</Typography>
                <div className={classes.sectionAndAuthor}>
                    <span>{this.state.sectionName}</span>
                    <select className={classes.sectionList} onChange={this.onSectionChange} defaultValue={JSON.stringify(this.state.sectionId)}>
                        <option value="" hidden>Select Blog Section</option>
                        {
                            this.state.sections.map(section => {
                                return (
                                    <option key={section.id} value={JSON.stringify(section)}>{section.name}</option>
                                )
                            })
                        }
                    </select>

                    <br />
                    <br />

                    <span>{this.state.author.name}</span>
                    <select className={classes.sectionList} onChange={this.onAuthorSelect} value={JSON.stringify(this.state.author)}>
                        <option value="" hidden>Select Author</option>
                        {
                            this.state.authors.map(author => {
                                return (
                                    <option key={author.id} value={JSON.stringify(author)}>{author.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div>
                    <img src={this.state.imageUrl} alt="blog" className={classes.blogImage} />
                    <br />
                    <input onChange={this.fileSelectorHandler} type="file" />
                </div>
                <Fragment>
                    <TextField
                        label="Title"
                        name="title"
                        type="text"
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                        value={this.state.title}
                        onChange={this.onInpuFieldChange}
                    />
                    <Wysiwyg
                        theme={this.state.theme}
                        onChange={this.handleChange}
                        value={this.state.content}
                        placeholder="Start writing something beautiful here"
                    />
                    <br />
                    <br />
                    {
                        this.state.tags.length === 0 ? null :
                            this.state.tags.map((tag, index) => {
                                return (
                                    <span key={index} className={classes.tag}>
                                        <span>{tag}</span>
                                        <span style={{ marginLeft: '10px', color: 'red', cursor: 'pointer' }} onClick={() => {
                                            this.deleteTag(index);
                                        }}>x</span>
                                    </span>
                                )
                            })
                    }
                    <br />
                    <br />
                    <TextField
                        label="Tag"
                        name="tag"
                        type="text"
                        variant="outlined"
                        value={this.state.tag}
                        className={classes.textField}
                        onChange={this.onInpuFieldChange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                this.onTagAdd();
                            }
                        }}
                    />
                    <Button onClick={this.onTagAdd} variant="outlined" color="primary" className={classes.button}>
                        Add Tag</Button>
                    <br />
                    <TextField
                        id="date"
                        label="Blog Date"
                        type="date"
                        name="createdAt"
                        value={this.state.createdAt}
                        onChange={this.onInpuFieldChange}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br />
                    <div className={classes.button}>
                        <Button disabled={this.state.disableSubmit} onClick={this.saveBlog} variant="outlined" color="primary" >
                            Save</Button>
                    </div>
                </Fragment>
            </section>
        );
    }
}

BlogPost.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(BlogPost));
import React from 'react';

const style = {
    root: {
        style: {
            marginTop: '15px',
            position: 'relative',
            textAlign: 'center',
            color: '#FFFFFF',
            width: '100%'
        }
    },
    img: {
        style: {
            width: '100%',
        }
    },
    h1: {
        style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
        }
    }
}

const Hero = (props) => {
    return (
        <div style={style.root.style}>
            <img src={require(`../../../images/${props.image}`)} style={style.img.style} />
            <h1 style={style.h1.style}>{props.text}</h1>
        </div>
    );
}

export default Hero;
import React, { Component } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import axios from '../../../axios/axios';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Notifier, { openSnackbar } from '../../utility/Notifier';
import Aggresive from '../../../images/AggInv.png';
import Conservative from '../../../images/ConsInv.png';
import Moderate from '../../../images/ModInv.png';
import { Grid, MuiThemeProvider } from '@material-ui/core';
import { firebase } from '../../../firebase/firebase';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import Typography from '@material-ui/core/Typography'
import './RiskProfile.css';
import { Link } from 'react-router-dom';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#495C6F',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Lato',
            'sans-serif',
        ].join(','),
        htmlFontSize: '12',
        body2: {
            fontSize: '16px',
            marginBottom: '10px',
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                color: '#7B818C !important'
            }
        },
        MuiFormLabel: {
            root: {
                color: '#000000',
                margin: '10px 0',
                fontWeight: 'bold'
            }
        },

        MuiFormControlLabel: {
            label: {
                fontSize: '14px',
                marginTop: '7px',
            }
        },
        MuiSvgIcon: {
            root: {
                width: '20px',
            }
        },
        MuiStepIcon: {
            text: {
                fill: '#FFFFFF',
                fontSize: '14px',
            },
        },
    }
});


const styles = theme => ({
    container: {
        backgroundColor: '#CCDBDE',
        padding: '1em 0',
    },
    breadcrumbs: {
        width: '70%',
        paddingBottom: '1em',
        margin: '0 auto 1em auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    riskProfiler: {
        width: '70%',
        backgroundColor: '#FFF',
        borderRadius: '5px',
        padding: '1em 0',
        margin: '2em auto',
        webkitBoxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        mozBoxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    heading: {
        color: '#444',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '0.07em',
        wordSpacing: '0.3em',
    },
    subheading: {
        fontSize: '16px',
        color: '#666',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        marginBottom: '1.5em',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        color: '#fff',
        background: '#495C6F',
        letterSpacing: '0.4em',
        margin: '1em 0',
    },
    link: {
        textDecoration: 'none',
        letterSpacing: '0.1em',
        color: '#7B818C',
    },
});

class RiskProfile extends Component {

    state = {
        activeStep: 0,

        financialRisk: null,
        financialContext: null,
        financialDecision: null,
        investmentReturn: null,
        investmentPerfomance: null,
        investMoney: null,
        hypotheticalInvestment: null,
        portfolios: null
    };


    onHandleChangeFinancialRisk = (e) => {
        let val = parseInt(e.target.value);
        this.setState({ financialRisk: val });
    }

    onChangeFinancialContext = e => {
        let val = parseInt(e.target.value);
        this.setState({ financialContext: val });
    }

    onChangeFinancialDecision = e => {
        let val = parseInt(e.target.value);
        this.setState({ financialDecision: val });
    }

    onChangeInvestmentReturn = e => {
        let val = parseInt(e.target.value);
        this.setState({ investmentReturn: val });
    }

    onChangeInvestmentPerfomance = e => {
        let val = parseInt(e.target.value);
        this.setState({ investmentPerfomance: val });
    }

    onChangeInvestMoney = e => {
        let val = parseInt(e.target.value);
        this.setState({ investMoney: val });
    }

    onChangePortfolios = e => {
        let val = parseInt(e.target.value);
        this.setState({ portfolios: val });
    }

    onChangeHypotheticalInvestment = e => {
        let val = parseInt(e.target.value);
        this.setState({ hypotheticalInvestment: val });
    }

    calculateTotalScore = () => {
        let score = 0;
        let stateValue = Object.values(this.state);
        stateValue.forEach(value => {
            if (isNaN(value)) {
                console.log(value);
            } else {
                score += value
            }
        });

        score -= this.state.activeStep;
        return score / 100;
    }


    saveResultToServer = (user, resultScore) => {

        axios.post('user/saveRiskProfileResult', {
            uid: user.uid,
            resultScore: resultScore
        }).then(result => {
            if (result.status === 200) {

            } else {

            }
        }).catch(exception => {

        });
    };

    scoreResult = (result) => {
        let user = firebase.auth().currentUser;
        if (user) {
            this.saveResultToServer(user, result);
        }
        if (result <= 40) {
            //this.saveData(this.totalScore());
            return (
                <Grid container justify="center">
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={5}
                        lg={5}
                    >
                        <div>
                            <img style={{
                                width: '100%',
                                height: 'auto',
                                textAlign: 'center',
                                margin: '10px 0'
                            }} src={Conservative} alt="Conservative" />
                        </div>
                    </Grid>
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={12}
                        lg={8}
                    >
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="body2" align="center">
                                <br />
                                <br />
                                Now that you know the risk levels that you’re comfortable with, it’s time to figure out your goals and investments with our <Link to="/moneyMap">Money Maps</Link>
                                <br />
                            </Typography>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            )
        } else if (result > 40 && result <= 70) {
            //this.saveData(this.totalScore());
            return (
                <Grid container justify="center">
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={5}
                        lg={5}
                    >
                        <div>
                            <img style={{
                                width: '100%',
                                height: 'auto',
                                textAlign: 'center',
                                margin: '10px 0'
                            }} src={Moderate} alt="Moderate" />
                        </div>
                    </Grid>
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={12}
                        lg={8}
                    >
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="body2" align="center">
                                <br />
                                <br />
                                Now that you know the risk levels that you’re comfortable with, it’s time to figure out your goals and investments with our <Link to="/moneyMap">Money Maps</Link>
                                <br />
                            </Typography>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            )
        } else {
            //this.saveData(this.totalScore());
            return (
                <Grid container justify="center">
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={5}
                        lg={5}
                    >
                        <div>
                            <img style={{
                                width: '100%',
                                height: 'auto',
                                textAlign: 'center',
                                margin: '10px 0'
                            }} src={Aggresive} alt="Aggresive" />
                        </div>
                    </Grid>
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={12}
                        lg={8}
                    >
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="body2" align="center">
                                <br />
                                <br />
                                Now that you know the risk levels that you’re comfortable with, it’s time to figure out your goals and investments with our <Link to="/moneyMap">Money Maps</Link>
                                <br />
                            </Typography>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            )
        }
    }

    getSteps = () => {
        return ['Compared to others, how do you rate your willingness to take financial risks?',
            'When you think of the word \'risk\' in a financial context, which of the following words come to mind first?',
            'When faced with a major financial decision, are you most concerned about the possible losses or the possible gains?',
            'Think of the average return you would expect to earn on an investment portfolio over a fixed deposit the next ten years.',
            'Which of the following statement best describes your attitude about the next six month\'s performance of the investment?',
            'What is the first thought to cross your mind when you invest your money?',
            ' Review the chart below. We’ve outlined the most likely best-case and worst-case annual returns of five hypothetical investment plans. Which range of possible outcomes is most acceptable to you? The figures are hypothetical and do not represent the performance of any particular investment.',
            'Which of the following portfolios would you prefer?'];
    }

    getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            {/* <FormLabel component="legend"></FormLabel> */}
                            <RadioGroup
                                aria-label="financialRisk"
                                name="financialRisk"
                                value={this.state.financialRisk + ""}
                                onChange={this.onHandleChangeFinancialRisk}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="Low risk taker" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="Average risk taker" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="High risk taker" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            case 1:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            <RadioGroup
                                aria-label="financialContext"
                                name="financialContext"
                                value={this.state.financialContext + ""}
                                onChange={this.onChangeFinancialContext}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="Danger" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="Uncertainty" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="Opportunity" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            case 2:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            <RadioGroup
                                aria-label="financialDecision"
                                name="financialDecision"
                                value={this.state.financialDecision + ""}
                                onChange={this.onChangeFinancialDecision}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="Always the possible losses" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="Usually the possible losses" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="Always the possible gains" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            case 3:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            <RadioGroup
                                aria-label="investmentReturn"
                                name="investmentReturn"
                                value={this.state.investmentReturn + ""}
                                onChange={this.onChangeInvestmentReturn}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="About the same rate as from bank fixed deposits" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="About one and a half times the rate from bank fixed deposits" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="About twice the rate from bank fixed deposits" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            case 4:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            <RadioGroup
                                aria-label="investmentPerfomance"
                                name="investmentPerfomance"
                                value={this.state.investmentPerfomance + ""}
                                onChange={this.onChangeInvestmentPerfomance}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="I would have a concern with any sort of loss" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="I'd get concerned if the loss was greater than 10%" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="I wouldn't worry about losses in that time frame" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            case 5:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            {/* <FormLabel component="legend">What is the first thought to cross your mind when you invest your money?</FormLabel> */}
                            <RadioGroup
                                aria-label="investMoney"
                                name="investMoney"
                                value={this.state.investMoney + ""}
                                onChange={this.onChangeInvestMoney}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="I should not lose my money" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="This should not turn out to be a bad investment" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="This should turn out to be a good investment" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            case 6:
                let { classes } = this.props;
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">



                            <table className="table-container">
                                <tbody className="table">
                                    <tr>
                                        <th className="table-heading">Options</th>
                                        <th className="table-heading">Avg annual return</th>
                                        <th className="table-heading">Best Case</th>
                                        <th className="table-heading">Worst Case</th>
                                    </tr>
                                    <tr>
                                        <td className="table-data">1</td>
                                        <td className="table-data">9%</td>
                                        <td className="table-data">12%</td>
                                        <td className="table-data">-7%</td>
                                    </tr>
                                    <tr>
                                        <td className="table-data">2</td>
                                        <td className="table-data">12%</td>
                                        <td className="table-data">25%</td>
                                        <td className="table-data">-14%</td>
                                    </tr>
                                    <tr>
                                        <td className="table-data">3</td>
                                        <td className="table-data">15%</td>
                                        <td className="table-data">40%</td>
                                        <td className="table-data">-25%</td>
                                    </tr>
                                </tbody>
                            </table>


                            <RadioGroup
                                className={classes.marginTop}
                                aria-label="hypotheticalInvestment"
                                name="hypotheticalInvestment"
                                value={this.state.hypotheticalInvestment + ""}
                                onChange={this.onChangeHypotheticalInvestment}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="Option 1" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="Option 2" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="Option 3" />
                            </RadioGroup>

                        </section >
                    </MuiThemeProvider>
                );
            case 7:
                return (
                    <MuiThemeProvider theme={theme}>
                        <section className="riskProfile-section--questions">
                            <RadioGroup
                                aria-label="portfolios"
                                name="portfolios"
                                value={this.state.portfolios + ""}
                                onChange={this.onChangePortfolios}
                            >
                                <FormControlLabel value="250" control={<Radio color="primary" />} label="Only debt and hybrid funds" />
                                <FormControlLabel value="625" control={<Radio color="primary" />} label="A mix of equity and debt funds" />
                                <FormControlLabel value="938" control={<Radio color="primary" />} label="Portfolio of equity funds, sector funds and debt funds" />
                            </RadioGroup>
                        </section>
                    </MuiThemeProvider>
                );
            default:
                return 'Unknown step';
        }
    }

    handleNext = () => {
        if (this.state.activeStep === 0) {
            if (this.state.financialRisk === null || this.state.financialRisk === undefined || this.state.financialRisk === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 1) {
            if (this.state.financialContext === null || this.state.financialContext === undefined || this.state.financialContext === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 2) {
            if (this.state.financialDecision === null || this.state.financialDecision === undefined || this.state.financialDecision === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 3) {
            if (this.state.investmentReturn === null || this.state.investmentReturn === undefined || this.state.investmentReturn === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 4) {
            if (this.state.investmentPerfomance === null || this.state.investmentPerfomance === undefined || this.state.investmentPerfomance === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 5) {
            if (this.state.investMoney === null || this.state.investMoney === undefined || this.state.investMoney === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 6) {
            if (this.state.hypotheticalInvestment === null || this.state.hypotheticalInvestment === undefined || this.state.hypotheticalInvestment === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 7) {
            if (this.state.portfolios === null || this.state.portfolios === undefined || this.state.portfolios === null) {
                openSnackbar({ message: "Please Select Option" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
            }));
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    render() {
        const { classes } = this.props;
        let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'Risk Profile', to: '' }];

        const steps = this.getSteps();
        const { activeStep } = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.breadcrumbs}>
                    <Breadcrumbs list={breadcrumbsList} />
                </div>

                <Notifier />

                <Grid container
                    className={classes.riskProfiler}
                >
                    <Grid item
                        xs={12}
                    >
                        <Typography component="h2" variant="h4" className={classes.heading} gutterBottom>RISK PROFILER</Typography>
                        <Typography component="h3" variant="body2" className={classes.subheading} gutterBottom>Got questions like, is this the right fund for me or how much risk can I take? Find the answers here</Typography>
                    </Grid>
                    <Grid item
                        xs={12}
                    >
                        <MuiThemeProvider theme={theme}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                            <StepContent>
                                                <div>{this.getStepContent(index)}</div>
                                                <div className={classes.actionsContainer}>
                                                    <div className={classes.buttonContainer}>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={this.handleBack}
                                                            className={classes.button}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.handleNext}
                                                            className={classes.button}
                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </MuiThemeProvider>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                {/* <Typography>All steps completed - you&quot;re finished</Typography> */}
                                {this.scoreResult(this.calculateTotalScore())}
                                {/* <Button onClick={this.handleReset} className={classes.button}>
                            Reset
                            </Button> */}
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
};

RiskProfile.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(RiskProfile);
import React, { Fragment } from 'react'
import { Grid, Typography, withStyles, Button } from '@material-ui/core'
import PropTypes from 'prop-types';
import axios from 'axios';
import Notifier, { openSnackbar } from '../utility/Notifier';

const styles = () => ({
  page: {
    margin: '0 auto',
    width: '80%',
    paddingTop: '4rem'
  },
  heading: {
    fontSize: '35px',
    letterSpacing: '1.2px'
  },
  button: {
    margin: '127px 0',
  }
})

const Planner = (props) => {
  const { classes } = props
  return (
    <Fragment>
      <Notifier />
      <Grid container spacing={8} className={classes.page}>
        <Grid item xs={12}>
          <Typography align="center" variant="h1" className={classes.heading}>
            Download report for Planners
      </Typography>
        </Grid>
        <Grid container justify="center" className={classes.button} item xs={12} xl={3} lg={3} md={2} sm={12}>
          <Button onClick={async () => {
            let token = await `Bearer ${sessionStorage.getItem('jwtToken')}`;
            axios({
              url: 'https://us-central1-investography-2a6de.cloudfunctions.net/app/admin/fetchMoneyMapResult',
              method: 'GET',
              responseType: 'blob', // important
              headers: { authorization: token }
            }).then((response) => {
              let date = new Date();
              openSnackbar({ message: "Downloading" });
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `moneymap-${date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear()}.csv`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }).catch(err => {
              openSnackbar({ message: "Somewthing went wrong" });
            });
          }}>Money Maps</Button>
        </Grid>
        <Grid container justify="center" className={classes.button} item xs={12} xl={3} lg={3} md={2} sm={12}>
          <Button onClick={async () => {
            let token = await `Bearer ${sessionStorage.getItem('jwtToken')}`;
            axios({
              url: 'https://us-central1-investography-2a6de.cloudfunctions.net/app/admin/retirementPlannerResult',
              method: 'GET',
              responseType: 'blob', // important
              headers: { authorization: token }
            }).then((response) => {
              let date = new Date();
              openSnackbar({ message: "Downloading" });
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `retirement_planner-${date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear()}.csv`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }).catch(err => {
              openSnackbar({ message: "Somewthing went wrong" });
            });
          }}>Retirement Planner</Button>
        </Grid>
        <Grid container justify="center" className={classes.button} item xs={12} xl={3} lg={3} md={2} sm={12}>
          <Button onClick={async () => {
            let token = await `Bearer ${sessionStorage.getItem('jwtToken')}`;
            axios({
              url: 'https://us-central1-investography-2a6de.cloudfunctions.net/app/admin/financialHealthResult',
              method: 'GET',
              responseType: 'blob', // important
              headers: { authorization: token }
            }).then((response) => {
              let date = new Date();
              openSnackbar({ message: "Downloading" });
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `financialHealth_result-${date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear()}.csv`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }).catch(err => {
              openSnackbar({ message: "Somewthing went wrong" });
            });
          }}>Financial Checkup</Button>
        </Grid>
        <Grid container justify="center" className={classes.button} item xs={12} xl={3} lg={3} md={2} sm={12}>
          <Button onClick={async () => {
            let token = await `Bearer ${sessionStorage.getItem('jwtToken')}`;
            axios({
              url: 'https://us-central1-investography-2a6de.cloudfunctions.net/app/admin/fetchRiskProfileResult',
              method: 'GET',
              responseType: 'blob', // important
              headers: { authorization: token }
            }).then((response) => {
              let date = new Date();
              openSnackbar({ message: "Downloading" });
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `riskProfile_result-${date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear()}.csv`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }).catch(err => {
              openSnackbar({ message: "Somewthing went wrong" });
            });
          }}>Risk Profile</Button>
        </Grid>
      </Grid>

    </Fragment>
  )
}

Planner.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Planner)
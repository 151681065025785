import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';
import './admin.css';


class Dashboard extends Component {
    constructor(props) {
        super();
    }

    events = () => {
        this.props.history.push("/dashboard/events");
        return <Redirect to="/dashboard/events"></Redirect>
    }

    quote = () => {
        this.props.history.push("/dashboard/quote");
        return <Redirect to="/dashboard/quote"></Redirect>
    }

    blog = () => {
        this.props.history.push("/dashboard/manageBlog");
        return <Redirect to="/dashboard/manageBlog"></Redirect>
    }

    testimonials = () => {
        this.props.history.push("/dashboard/testimonials");
    }

    sessions = () => {
      this.props.history.push("/dashboard/sessions");
  }

    render() {
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav logout={() => {
                        sessionStorage.clear('jwtToken');
                        this.props.history.push("/admin");
                        return <Redirect to="/admin" />
                    }} />
                </div>

                <div className="dashboard">
                    <h1>Welcome to Investography Dashboard</h1>
                    <div className="dashboard-menu">
                        <div className="menu-box card" onClick={this.events}>
                            <img src="https://image.flaticon.com/icons/svg/123/123392.svg" alt="Event" />
                            <p><strong>Event Management</strong></p>
                        </div>

                        <div className="menu-box card" onClick={this.quote}>
                            <img src="https://image.flaticon.com/icons/svg/148/148815.svg" alt="Announcement" />
                            <p><strong>Banner Annoucement</strong></p>
                        </div>

                        <div className="menu-box card" onClick={this.blog}>
                            <img src="https://image.flaticon.com/icons/svg/1260/1260175.svg" alt="Blog" />
                            <p><strong>Manage Blogs</strong></p>
                        </div>
                        <div className="menu-box card" onClick={this.testimonials}>
                            <img src="https://image.flaticon.com/icons/svg/485/485313.svg" alt="Testimonials" />
                            <p><strong>Testimonials</strong></p>
                        </div>
                        <div className="menu-box card" onClick={this.sessions}>
                            <img src="https://image.flaticon.com/icons/svg/485/485313.svg" alt="Testimonials" />
                            <p><strong>Sessions</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
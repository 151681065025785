import React, { Fragment } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { TextField } from '@material-ui/core';

const styles = theme => ({
    header: {
        textAlign: "center",
    },
    footer: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
    },
    registerButton: {
        fontSize: '16px',
        color: "blue",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    addIcon: {
        marginRight: "5px",
    },
    facebookButton: {
        backgroundColor: "#3B5998",
        color: "#FFF",
        fontSize: '16px',
        letterSpacing: '1px',
        fontWeight: 700,
        marginRight: "15px",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "10px",
        },
        "&:hover": {
            backgroundColor: "#3B5998",
        },
    },
    googleButton: {
        backgroundColor: "#DD4B39",
        color: "#FFF",
        fontSize: '16px',
        letterSpacing: '1px',
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "#DD4B39",
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-15px",
        },
    },
    center: {
        textAlign: "center",
        padding: '10px 0',
    },
    inputField: {
        width: "100%",
        margin: "10px 0",
        "&:first-child": {
            marginTop: 0,
        },
    },
    socialButton: {
        textAlign: "center",
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    loginButton: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#FFFFFF',
        letterSpacing: '1px',
    },
});

const Login = (props) => {
    const { classes } = props;
    return (
        <Fragment>
            <DialogContent>
                <div className={ classes.header }>
                    <h3 className={ classes.heading }>Sign In to Investography</h3>
                    <p className={ classes.center }>Easy login with</p>
                </div>
                <div className={ classes.socialButton }>
                    <Button onClick={ props.onFacebookClick } className={ classes.facebookButton }>Facebook</Button>
                    <Button onClick={ props.onGoogleClick } className={ classes.googleButton }>Google</Button>
                </div>
                <p className={ classes.center }>Or with email</p>
                <form autoComplete="on" onSubmit={ props.onLoginSubmit }>
                    <TextField
                        className={ classes.inputField }
                        type="email" 
                        label="Email"
                        value={ props.email }
                        onChange={ props.onEmailChange }
                    />
                    <TextField
                        className={ classes.inputField }
                        type="password" 
                        label="Password"
                        value={ props.password }
                        onChange={ props.onPasswordChange }
                    />
                    <div className={ classes.footer }>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={ classes.loginButton }
                        >
                            login
                        </Button>
                        <Button
                            onClick={ props.registerButtonClicked }
                            className={ classes.registerButton }
                        >
                            <Add className={ classes.addIcon } />
                            create an account
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Fragment>
    );
};

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
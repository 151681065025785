import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    button: {
        background: 'linear-gradient(45deg, #26D0CE 20%, #1A2980 100%)',
        color: '#FFFFFF',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '1px',
        padding: '0 16px',
        margin: '0 15px',
        '&:hover': {
            background: 'linear-gradient(135deg, #26D0CE 20%, #1A2980 80%)',
        }
    },
});

class GetStartedButton extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Button
                    variant="contained"
                    className={ classes.button }
                    size="small"
                    href="http://fundnow.investography.in"
                    target="_blank"
                >
                    INVEST NOW
                </Button>
            </React.Fragment>
        );
    }
};

GetStartedButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GetStartedButton);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Input, FormControl, Select, MenuItem, Button, Typography } from '@material-ui/core';
import Notifier, { openSnackbar } from '../../../utility/Notifier';

import start from '../../../../images/start.png';
import end from '../../../../images/end.png';
import map1 from '../../../../images/map1.png';
import Breadcrumbs from '../../../breadcrumbs/Breadcrumbs';

import './MoneyMaps.css';
import { firebase } from '../../../../firebase/firebase';
import axios from '../../../../axios/axios';



const styles = theme => ({
    moneyMaps: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FEDFDE',
        width: '100%',
    },
    moneyMapsContainer: {
        width: '70%',
        backgroundColor: '#FFF',
        borderRadius: '5px',
        padding: '1em 0',
        margin: '2em 0',
        webkitBoxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        mozBoxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    breadcrumbContainer: {
        width: '70%',
        borderRadius: '5px',
        margin: '1em 0',
    },
    heading: {
        color: '#444',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '0.07em',
        wordSpacing: '0.3em',
    },
    subheading: {
        fontSize: '16px',
        color: '#666',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        marginBottom: '1.5em',
    },
    inputLabel: {
        fontSize: '16px',
        color: '#888',
        marginTop: '0.5em',
        marginBottom: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dropdownLabel: {
        fontSize: '16px',
        color: '#888',
        marginTop: '1.5em',
        marginBottom: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    input: {
        width: '60%',
        margin: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    formControl: {
        width: '60%',
        margin: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        display: 'block',
        width: '90%',
        color: '#fff',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        textAlign: 'center',
        letterSpacing: '0.4em',
        marginTop: '3em',
        marginBottom: '6em',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '25%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        flex: '1',
        width: '16px',
        margin: '0.9em 0.5em 0.3em 0.5em',

    },
    stateDisplay: {
        flex: '15',
    },
    locationDisplay: {
        fontFamily: 'Lato, sans-serif',
        letterSpacing: '0.1em',
        wordSpacing: '0.3em',
        width: '100%',
        backgroundColor: '#DFDFDF',
        padding: '0.5em',
        margin: '0.3em',
    },
    debtSip: {
        color: '#fff',
        backgroundColor: '#13B6FD',
        textAlign: 'center',
        width: '100%',
        padding: '0.5em',
        borderRadius: '10px',
        margin: '0.5em',
    },
    aaSip: {
        color: '#fff',
        backgroundColor: '#8BC53E',
        textAlign: 'center',
        width: '100%',
        padding: '0.5em',
        borderRadius: '10px',
        margin: '0.5em',
    },
    equitySip: {
        color: '#fff',
        backgroundColor: '#FD8C10',
        textAlign: 'center',
        width: '100%',
        padding: '0.5em',
        borderRadius: '10px',
        margin: '0.5em',
    },
    mapContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '0.5em',
    },
    map: {
        width: '500px',
        height: '600px',
        [theme.breakpoints.down('sm')]: {
            width: '600px',
            height: '600px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '290px',
            height: '450px',
        },
    },
    tip: {
        width: '0px',
        height: '0px',
        position: 'absolute',
        background: 'transparent',
        border: '10px solid #fff',
    },
    output: {
        position: 'relative',
        width: '150px',
        height: '75px',
        /* minWidth: '70px',
        maxWidth: '300px',
        height: 'auto', */
        margin: '20px 10px',
        padding: '5px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        border: '5px solid #fff',
        webkitBoxShadow: '10px 5px 5px rgba(0, 0, 0, 0.3)',
        mozBoxShadow: '10px 5px 5px rgba(0, 0, 0, 0.3)',
        boxShadow: '10px 5px 5px rgba(0, 0, 0, 0.3)',
        [theme.breakpoints.down('xs')]: {
            width: '120px',
            height: '100px',
            /* minWidth: '40px',
            maxWidth: '120px', */
            wordBreak: 'keep-all',
        },
    },
    right: {
        webkitBoxShadow: '- 10px 5px 5px rgba(0, 0, 0, 0.3)',
        mozBoxShadow: '-10px 5px 5px rgba(0, 0, 0, 0.3)',
        boxShadow: '-10px 5px 5px rgba(0, 0, 0, 0.3)',
    },
    outputMessage: {
        minHeight: '15px',
        borderRadius: '3px',
        fontFamily: 'Arial',
        fontSize: '14px',
        lineHeight: 1.5,
        textAlign: 'center',
    },
    debt: {
        position: 'absolute',
        bottom: '90px',
        left: '65px',
        [theme.breakpoints.down('sm')]: {
            bottom: '90px',
            left: '100px',
        },
        [theme.breakpoints.down('xs')]: {
            bottom: '15px',
            left: '2px',
        },
    },
    tipRight: {
        top: '5px',
        right: '-25px',
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
    },
    aa: {
        position: 'absolute',
        top: '220px',
        left: '85px',
        [theme.breakpoints.down('sm')]: {
            top: '215px',
            left: '135px',
        },
        [theme.breakpoints.down('xs')]: {
            top: '150px',
            left: '10px',
        },
    },
    equity: {
        position: 'absolute',
        top: '70px',
        right: '10px',
        [theme.breakpoints.down('sm')]: {
            top: '70px',
            right: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            top: '0px',
            right: '-7px',
        },
    },
    tipLeft: {
        top: '70px',
        left: '25px',
        transform: 'rotate(0.75turn)',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        [theme.breakpoints.down('xs')]: {
            top: '95px',
            left: '20px',
        },
    },
    textBold: {
        fontWeight: 600,
    },
    box: {
        border: '1px solid #000',
    },
    moneyMapsCont: {
        width: '70%',
        backgroundColor: '#FFF',
        borderRadius: '5px',
        margin: '1em 0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    link: {
        textDecoration: 'none',
        letterSpacing: '0.1em',
        color: '#fff',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        padding: '0.5em 0.8em 0.5em 0.8em',
        borderRadius: '7px',
    }
});

class MoneyMaps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            presentValue: 0,
            goal: '',
            years: 0,
            inflate: 0.06,
            debt: 0.08,
            assetAllocation: 0.1,
            equity: 0.12,
        }
    }

    /* numberFormat function for displaying outputs in Indian currency format  */
    numberFormat = (value) => {
        let x = value;
        x = x.toString();
        let afterPoint = '';
        if (x.indexOf('.') > 0)
            afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        let lastThree = x.substring(x.length - 3);
        let otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers !== '')
            lastThree = ',' + lastThree;
        let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
        return res;
    }

    presentValue = (e) => {
        e.preventDefault();
        const presentValue = parseInt(e.target.value);
        this.setState(() => ({ presentValue }));
    };

    goal = (e) => {
        e.preventDefault();
        const goal = e.target.value;
        this.setState(() => ({ goal }));
        switch (goal) {
            case "EDUCATION":
                this.setState(() => ({ inflate: 0.1 }));
                break;
            case "HEALTH":
                this.setState(() => ({ inflate: 0.1 }));
                break;
            case "BUSINESS":
                this.setState(() => ({ inflate: 0.06 }));
                break;
            case "VEHICLE":
                this.setState(() => ({ inflate: 0.06 }));
                break;
            case "HOME":
                this.setState(() => ({ inflate: 0.06 }));
                break;
            case "HOLIDAY":
                this.setState(() => ({ inflate: 0.06 }));
                break;
            case "MARRIAGE":
                this.setState(() => ({ inflate: 0.06 }));
                break;
            case "OTHER":
                this.setState(() => ({ inflate: 0.06 }));
                break;
            default:
                this.setState(() => ({ inflate: 0.06 }));
                break;
        }
    };

    years = (e) => {
        e.preventDefault();
        const years = parseInt(e.target.value);
        this.setState(() => ({ years }));
        /* if (years <= 5) {
            this.setState(() => ({ debt: 0.08 }));
            this.setState(() => ({ assetAllocation: 0.1 }));
            this.setState(() => ({ equity: 0.12 }));
        } else {
            this.setState(() => ({ debt: 0.09 }));
            this.setState(() => ({ assetAllocation: 0.12 }));
            this.setState(() => ({ equity: 0.15 }));
        } */
    };

    futureValue = () => {
        let futureValue = this.state.presentValue * Math.pow(
            (1 + this.state.inflate), this.state.years);
        futureValue = 500 * Math.ceil(
            (futureValue.toFixed(0) / 500)
        );
        return futureValue;
    };

    debtSip = () => {
        let debtSip = this.futureValue() * this.state.debt / 12 / (Math.pow(
            (1 + this.state.debt / 12), (this.state.years * 12)
        ) - 1);
        debtSip = 500 * Math.ceil(
            (debtSip.toFixed(0) / 500)
        );
        return debtSip;
    };

    assetAllocationSip = () => {
        let assetAllocationSip = this.futureValue() * this.state.assetAllocation / 12 / (Math.pow(
            (1 + this.state.assetAllocation / 12), (this.state.years * 12)
        ) - 1);
        assetAllocationSip = 500 * Math.ceil(
            (assetAllocationSip.toFixed(0) / 500)
        );
        return assetAllocationSip;
    };

    equitySip = () => {
        let equitySip = this.futureValue() * this.state.equity / 12 / (Math.pow(
            (1 + this.state.equity / 12), (this.state.years * 12)
        ) - 1);
        equitySip = 500 * Math.ceil(
            (equitySip.toFixed(0) / 500)
        );
        return equitySip;
    };

    result = () => {
        const result = document.getElementById('result');
        result.classList.remove('hide-result');

        const inputDisplay = document.getElementById('inputDisplay');
        inputDisplay.innerHTML = this.state.goal + ": ₹" + this.numberFormat(this.futureValue()) + " AFTER " + this.state.years + " YEARS";

        const debtSipDisplay = document.getElementById('debtSipDisplay');
        debtSipDisplay.innerHTML = "Debt: " + this.numberFormat(this.debtSip());

        const aaSipDisplay = document.getElementById('aaSipDisplay');
        aaSipDisplay.innerHTML = "AA*: " + this.numberFormat(this.assetAllocationSip());

        const equitySipDisplay = document.getElementById('equitySipDisplay');
        equitySipDisplay.innerHTML = "Equity: " + this.numberFormat(this.equitySip());

        const mapDebt = document.getElementById('mapDebt');
        mapDebt.innerHTML = this.numberFormat(this.debtSip());

        const mapAssetAllocation = document.getElementById('mapAssetAllocation');
        mapAssetAllocation.innerHTML = this.numberFormat(this.assetAllocationSip());

        const mapEquity = document.getElementById('mapEquity');
        mapEquity.innerHTML = this.numberFormat(this.equitySip());
    };

    validation = (e) => {
        e.preventDefault();
        if (this.state.presentValue < 1) {
            openSnackbar({ message: "Present value should be greater than 0" });
        } else if (this.state.goal === '' || this.state.goal === undefined) {
            openSnackbar({ message: "Please select a goal" });
        } else if (this.state.years < 1) {
            openSnackbar({ message: "No. of years should be greater than 0" });
        } else {
            this.result();

            let user = firebase.auth().currentUser;
            if (user) {
                axios.post("user/saveMoneyMap", {
                    uid: user.uid,
                    presentValue: this.state.presentValue,
                    goal: this.state.goal,
                    years: this.state.years
                }).then(result => {

                }).catch(exception => {
                    openSnackbar({ message: "Something went wrong" });
                });
            }
        }
    };


    render() {
        const { classes } = this.props;
        let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'Money Maps', to: '' }];
        return (
            <div>

                <div className={classes.moneyMaps}>
                    <div className={classes.breadcrumbContainer}>
                        <Breadcrumbs list={breadcrumbsList} />
                    </div>
                </div>
                <div className={classes.moneyMaps}>

                    <div className={classes.moneyMapsContainer}>
                        <Notifier />
                        <Grid container>
                            <Grid item
                                xs={12}
                            >
                                <form onSubmit={this.validation}>
                                    <Grid container
                                        justify="center"
                                    >
                                        <Grid item
                                            container
                                            justify="center"
                                            xs={12}
                                            md={7}
                                            lg={7}
                                        >
                                            <Typography component="h2" variant="h4" className={classes.heading} gutterBottom>Money Maps</Typography>
                                            <Grid item
                                                xs={12}
                                                md={12}
                                                lg={12}
                                            >
                                                <Typography component="h3" variant="body2" className={classes.subheading} gutterBottom>Our calculator will help you to visualise the path which you need to take to achieve your most desired dreams</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item
                                            container
                                            xs={12}
                                            md={7}
                                            lg={7}
                                        >
                                            <Grid item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                            >
                                                <Typography variant="body2" className={classes.inputLabel}>Present value of the goal</Typography>
                                            </Grid>
                                            <Grid item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                            >
                                                <Input
                                                    className={classes.input}
                                                    onChange={this.presentValue}
                                                    value={this.state.presentValue}
                                                    inputProps={{
                                                        'aria-label': 'Present value of the goal',
                                                    }}
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item
                                            container
                                            xs={12}
                                            md={7}
                                            lg={7}
                                        >
                                            <Grid item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                            >
                                                <Typography variant="body2" className={classes.dropdownLabel}>Define your goal</Typography>
                                            </Grid>
                                            <Grid item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                            >
                                                <FormControl className={classes.formControl}>
                                                    <Select
                                                        className={classes.selectEmpty}
                                                        value={this.state.goal}
                                                        onChange={this.goal}
                                                        name="goal"
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select
                                                        </MenuItem>
                                                        <MenuItem value="EDUCATION">Education</MenuItem>
                                                        <MenuItem value="HEALTH">Health</MenuItem>
                                                        <MenuItem value="BUSINESS">Business</MenuItem>
                                                        <MenuItem value="VEHICLE">Vehicle</MenuItem>
                                                        <MenuItem value="HOME">Home</MenuItem>
                                                        <MenuItem value="HOLIDAY">Holiday</MenuItem>
                                                        <MenuItem value="MARRIAGE">Marriage</MenuItem>
                                                        <MenuItem value="OTHER">Any other goals</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item
                                            container
                                            xs={12}
                                            md={7}
                                            lg={7}
                                        >
                                            <Grid item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                            >
                                                <br />
                                                <Typography variant="body2" className={classes.inputLabel}>No. of years</Typography>
                                            </Grid>
                                            <Grid item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                            >
                                                <br />
                                                <Input
                                                    className={classes.input}
                                                    onChange={this.years}
                                                    value={this.state.years}
                                                    inputProps={{
                                                        'aria-label': 'Present value of the goal',
                                                    }}
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item
                                            container
                                            justify="flex-end"
                                            xs={12}
                                            md={7}
                                            lg={7}
                                        >
                                            <Grid item
                                                container
                                                justify="center"
                                                xs={12}
                                                md={8}
                                                lg={8}
                                            >
                                                <Grid item
                                                    container
                                                    justify="center"
                                                    xs={12}
                                                    md={6}
                                                    lg={6}
                                                >
                                                    <Button type="submit" variant="contained" className={classes.button}>
                                                        Submit
                                                </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item
                                            container
                                            xs={12}
                                            md={7}
                                            lg={7}
                                        >
                                            <Grid item
                                                xs={12}
                                                md={12}
                                                lg={12}
                                            >
                                                <div id="result" className="hide-result">
                                                    <Grid item
                                                        className={classNames(classes.flex)}
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <span>
                                                            <img src={start} className={classNames(classes.icon)} alt="Source" />
                                                        </span>
                                                        <span className={classNames(classes.locationDisplay, classes.stateDisplay)}>
                                                            YOUR LOCATION
                                                    </span>
                                                    </Grid>
                                                    <Grid item
                                                        className={classNames(classes.flex)}
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <span>
                                                            <img src={end} className={classNames(classes.icon)} alt="Destination" />
                                                        </span>
                                                        <span id="inputDisplay" className={classNames(classes.locationDisplay, classes.stateDisplay)}></span>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <Grid item
                                                            xs={12}
                                                        >
                                                            <Typography component="h5" variant="body2" className={classes.subheading}><br /><br /><b>MONTHLY INVESTMENT (SIP)  REQUIRED</b></Typography>
                                                        </Grid>
                                                        <Grid item
                                                            container
                                                            justify="center"
                                                            xs={4}
                                                        >
                                                            <span id="debtSipDisplay" className={classes.debtSip}></span>
                                                        </Grid>
                                                        <Grid item
                                                            container
                                                            justify="center"
                                                            xs={4}
                                                        >
                                                            <span id="aaSipDisplay" className={classes.aaSip}></span>
                                                        </Grid>
                                                        <Grid item
                                                            container
                                                            justify="center"
                                                            xs={4}
                                                        >
                                                            <span id="equitySipDisplay" className={classes.equitySip}></span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <div className={classes.mapContainer}>
                                                            <br />
                                                            <img src={map1} className={classes.map} alt="Money Map" />
                                                            <div className={classNames(classes.debt, "debt")}>
                                                                <div className={classes.output}>
                                                                    <span className={classNames(classes.tip, classes.tipRight)}></span>
                                                                    <div className={classes.outputMessage}>
                                                                        <Typography variant="caption">
                                                                            {this.state.years <= 3 ? "Suggested Route:" : "Debt:"}
                                                                            <br />
                                                                            <span id="mapDebt" className={classes.textBold}></span>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={classNames(classes.aa, "aa")}>
                                                                <div className={classes.output}>
                                                                    <span className={classNames(classes.tip, classes.tipRight)}></span>
                                                                    <div className={classes.outputMessage}>
                                                                        <Typography variant="caption">
                                                                            {(this.state.years >= 4 && this.state.years <= 7) ? "Suggested Route:" : "Asset Allocation:"}
                                                                            <br />
                                                                            <span id="mapAssetAllocation" className={classes.textBold}></span>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={classNames(classes.equity, "equity")}>
                                                                <div className={classNames(classes.output, classes.right)}>
                                                                    <span className={classNames(classes.tip, classes.tipLeft)}></span>
                                                                    <div className={classes.outputMessage}>
                                                                        <Typography variant="caption">{this.state.years > 7 ? "Suggested Route:" : "Equity:"}
                                                                            <br />
                                                                            <span id="mapEquity" className={classes.textBold}></span>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <Typography variant="caption" align="center">
                                                            <br />
                                                            <div><b>**Assumptions: </b>Future value of goal adjusted for inflation at {`${this.state.inflate * 100}%`}.</div>
                                                            <div>Returns from debt at 8%, Asset Allocation (AA*) at 10% and Equity at 12% annually</div>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <Typography variant="subtitle1" align="center">
                                                            <br />
                                                            <br />
                                                            Now that you’ve set your goals and know how much you need to invest, stop delaying
                                                            <div>
                                                                <br />
                                                                <a href="http://fundnow.investography.in/" target="_blank" className={classes.link}>Start Investing</a>
                                                            </div>
                                                            <br />
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
};

MoneyMaps.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MoneyMaps);
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';
import AddAuthor from './AddAuthor';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from '../../axios/axios';
import Notifier, { openSnackbar } from '../utility/Notifier';
import Button from '@material-ui/core/Button';
import './admin.css';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});

class ManageAuthor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            component: 'fetch',
            authors: [],
            author: '',
            open: false,
            token: sessionStorage.getItem('jwtToken')
        }
    }

    fetchAuthors = () => {
        axios.get('blog/fetchAuthor',
            { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState({
                            authors: result.data.authors
                        });
                    } else {
                        openSnackbar({ message: "Something went wrong" });
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: exception });
            });
    }

    componentDidMount() {
        this.fetchAuthors();
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    addAuthor = () => {
        this.setState({ component: 'AddAuthor' });
    }

    fetchAuthorComponent = () => {
        this.setState({ component: '' });
        this.fetchAuthors();
    }

    render() {
        const { classes } = this.props;
        let component = (
            <Fragment>
                <Notifier />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>Name</CustomTableCell>
                                <CustomTableCell>Description</CustomTableCell>
                                <CustomTableCell>Actions</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.authors.map((author) => {
                                return <TableRow className={classes.row} key={author.id}>
                                    <CustomTableCell>{author.name}</CustomTableCell>
                                    <CustomTableCell>{author.description}</CustomTableCell>
                                    <CustomTableCell>
                                        <div>
                                            <button style={{
                                                border: 'none',
                                                background: 'transparent',
                                                textDecoration: 'underline',
                                                textTransform: 'uppercase',
                                                color: '#29B5C3',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => {
                                                    this.setState({ author: author, open: true });
                                                }}>Delete</button>
                                        </div>
                                    </CustomTableCell>
                                </TableRow>
                            })}
                            {this.state.author !== null ?
                                <Dialog
                                    open={this.state.open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="scroll-dialog-title">Author Details</DialogTitle>
                                    <DialogContent>

                                        <div className="modal-content">
                                            <strong><p>Are you sure?</p></strong>

                                            <button className="modalFetchEventBT addButton" onClick={() => {
                                                axios.post('blog/deleteAuthor', {
                                                    id: this.state.author.id
                                                },
                                                    { headers: { authorization: `Bearer ${this.state.token}` } })
                                                    .then(result => {
                                                        if (result.status === 200) {
                                                            if (result.data.success) {
                                                                this.setState({
                                                                    open: false
                                                                }, () => {
                                                                    this.fetchAuthors();
                                                                });
                                                                openSnackbar({ message: result.data.msg });
                                                            } else {
                                                                openSnackbar({ message: result.data.msg });
                                                            }
                                                        }
                                                    }).catch(exception => {
                                                        openSnackbar({ message: exception });
                                                    });
                                            }}>Delete</button>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} color="primary">
                                            Cancel</Button>
                                    </DialogActions>
                                </Dialog>
                                : null}
                        </TableBody>
                    </Table>
                </Paper>
                <button className="addButton" onClick={this.addAuthor}>Add Author</button>
            </Fragment>
        );
        if (this.state.component === 'AddAuthor') {
            component = <AddAuthor fetchAuthorComponent={this.fetchAuthorComponent} />
        }

        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }}
                    />
                </div>
                <div id="Author">
                    {component}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ManageAuthor);
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownWhat from './NavbarDropdownWhat';

const styles = theme => ({
    navlist: {
        padding: 0,
        display: 'flex',
        aligntItems: 'center',
    },
    navlink: {
        margin: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        float: "left",
        display: "block",
        position: "relative",
        width: "auto",
        '&:first-child': {
            paddingLeft: '60px',
        },   
    },
    link: {
        textDecoration: "none",
        color: "#29B5C3",
    },  
    dropdownLink: {
        textDecoration: 'none',
        color: '#29B5C3',
        display: 'flex',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        padding: 0,
        minHeight: 0,
        marginTop: '-4px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

const NavbarLink = (props) => {
    const { classes } = props;

    return (
        <List className={ classes.navlist }>
            <ListItem className={ classes.navlink }>
                <NavbarDropdownWhat 
                    className={ classes.dropdownLink }
                />
            </ListItem>
            <ListItem className={ classes.navlink }>
                <NavbarDropdown 
                    className={ classes.dropdownLink }
                />
            </ListItem>
            <ListItem className={ classes.navlink }>
                <Link className={ classes.link } to="/media">MEDIA</Link>
            </ListItem>
            <ListItem className={ classes.navlink }>
                <Link className={ classes.link } to="/events">EVENTS</Link>
            </ListItem>
            <ListItem className={ classes.navlink }>
                <Link className={ classes.link } to="/magazine">MAGAZINE</Link>
            </ListItem>
            <ListItem className={ classes.navlink }>
                <Link className={ classes.link } to="/about">OUR STORY</Link>
            </ListItem>
        </List>
    );
};

NavbarLink.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavbarLink);
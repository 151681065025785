import React from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import './admin.css';
import AdminNav from './AdminNav';
import { Redirect } from 'react-router-dom';
import Notifier, { openSnackbar } from '../utility/Notifier';
import Button from '@material-ui/core/Button'
import axios from '../../axios/axios';

class UpdateSession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      session: '',
      months: '',
      people: '',
      token: sessionStorage.getItem('jwtToken')
    }
  }

  onHandleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    this.fetchSession();
  }

  fetchSession = () => {
    axios.get("admin/fetchSessionInfo").then(result => {
      if (result.status === 200) {
        if (result.data.success) {
          this.setState({
            id: result.data.sessions[0].id,
            session: result.data.sessions[0].sessions,
            months: result.data.sessions[0].months,
            people: result.data.sessions[0].people,
          });
        } else {
          openSnackbar({ message: result.data.msg });
        }
      } else {
        openSnackbar({ message: result.data.msg });
      }
    }).catch(exception => {
      openSnackbar({ message: exception.message });
    });
  }

  updateSession = () => {
    axios.post("admin/updateSessionInfo", {
      id: this.state.id,
      sessions: this.state.session,
      months: this.state.months,
      people: this.state.people
    }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
      if (result.status === 200) {
        if (result.data.success) {
          openSnackbar({ message: result.data.msg });
        } else {
          openSnackbar({ message: result.data.msg });
        }
      } else {
        openSnackbar({ message: result.data.msg });
      }
    }).catch(exception => {
      openSnackbar({ message: exception.message });
    });
  }

  saveSession = () => {
    axios.post("admin/saveSessionInfo", {
      sessions: this.state.session,
      months: this.state.months,
      people: this.state.people
    }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
      if (result.status === 200) {
        if (result.data.success) {
          openSnackbar({ message: result.data.msg });
        } else {
          openSnackbar({ message: result.data.msg });
        }
      } else {
        openSnackbar({ message: result.data.msg });
      }
    }).catch(exception => {
      openSnackbar({ message: exception.message });
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.id === "" || this.state.id === undefined || this.state.id === null) {
      this.saveSession();
    } else {
      this.updateSession();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Notifier />
        <div className="containeradmin">
          <div className="admin-sidebar">
            <AdminNav
              logout={() => {
                sessionStorage.clear('jwtToken');
                this.props.history.push("/admin");
                return <Redirect to="/admin" />
              }}
              dashboard={() => {
                this.props.history.push("/dashboard");
                return <Redirect to="/dashboard" />
              }} />
          </div>
          <div className="session-container">
            <form>
              <Grid container spacing={24}>
                <Grid item sm={12} md={2} lg={3}>
                  <TextField
                    required
                    name="session"
                    value={this.state.session}
                    onChange={this.onHandleChange}
                    placeholder="Session"
                  />
                </Grid>
                <Grid item sm={12} md={2} lg={3}>
                  <TextField
                    required
                    name="months"
                    value={this.state.months}
                    onChange={this.onHandleChange}
                    placeholder="Months"
                  />
                </Grid>
                <Grid item sm={12} md={2} lg={3}>
                  <TextField
                    required
                    name="people"
                    value={this.state.people}
                    onChange={this.onHandleChange}
                    placeholder="People"
                  />
                </Grid>
                <Grid item sm={12} md={2} lg={3}>
                  <Button
                    color="primary"
                    onClick={this.onFormSubmit}
                  >Submit</Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </React.Fragment >
    )
  }
}

export default UpdateSession
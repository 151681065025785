import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import InvestographyMobile from '../../images/investography-mobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownWhat from './NavbarDropdownWhat';

const Brand = <a href="/"><img src={InvestographyMobile} alt="investography logo" /></a>

const styles = theme => ({
    navList: {
        width: '270px',
    },
    link: {
        textDecoration: 'none',
        color: '#29B5C3',
        fontFamily: 'Lato',
        fontSize: '13px',
        fontWeight: '700',
        letterSpacing: '1px',
    },
    socialLink: {
        paddingRight: '15px',
        fontSize: '25px',
        color: '#29B5C3',
    },
    dropdownLink: {
        textDecoration: 'none',
        color: '#29B5C3',
        display: 'flex',
        fontFamily: 'Lato',
        fontSize: '13px',
        fontWeight: '700',
        letterSpacing: '1px',
        [theme.breakpoints.down('md')]: {
            padding: 0,
            minHeight: 0,
        },
    },
    brand: {
        display: 'flex',
        justifyContent: 'center',
    },
});

const NavbarLinkMobile = props => {
    const { classes } = props;
    return (
        <Drawer
            anchor="left"
            variant="temporary"
            open={props.open}
            onClose={props.onClose}
        >
            <List className={classes.navList}>
                <ListItem className={classes.brand}>
                    {Brand}
                </ListItem>
                <Divider />
                <ListItem>
                    <NavbarDropdownWhat
                        className={classes.dropdownLink}
                    />
                </ListItem>
                <ListItem>
                    <NavbarDropdown
                        className={classes.dropdownLink}
                    />
                </ListItem>
                <ListItem>
                    <Link className={classes.link} to="/media">MEDIA</Link>
                </ListItem>
                <ListItem>
                    <Link className={classes.link} to="/events">EVENTS</Link>
                </ListItem>
                <ListItem>
                    <Link className={classes.link} to="/magazine">MAGAZINE</Link>
                </ListItem>
                <ListItem>
                    <Link className={classes.link} to="/about">OUR STORY</Link>
                </ListItem>
                <ListItem>
                    <a className={classes.socialLink} href="https://www.facebook.com/investography.pvt.ltd/" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
                    <a className={classes.socialLink} href="https://twitter.com/i4investography?lang=en" target="_blank"><FontAwesomeIcon icon={['fab', 'twitter']} /> </a>
                    <a className={classes.socialLink} href="https://in.linkedin.com/company/investography" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                    <a className={classes.socialLink} href="https://www.instagram.com/investography/" target="_blank"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                </ListItem>
            </List>
        </Drawer>
    );
};

NavbarLinkMobile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavbarLinkMobile);
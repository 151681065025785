import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faHome, faSignOutAlt, faArchive, faUsers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Notifier, { openSnackbar } from '../utility/Notifier';
import { withRouter } from 'react-router-dom'

library.add(fab, faHome, faSignOutAlt, faUsers);
const AdminNav = (props) => {
    return (
        <ul>
            <Notifier />
            <li onClick={props.dashboard}>
                <FontAwesomeIcon icon={faHome} style={{ color: '#2F4F4F' }}></FontAwesomeIcon> Dashboard
            </li>
            <li onClick={async () => {
                let token = await `Bearer ${sessionStorage.getItem('jwtToken')}`;
                axios({
                    url: 'https://us-central1-investography-2a6de.cloudfunctions.net/app/admin/getSubscribers',
                    method: 'GET',
                    responseType: 'blob', // important
                    headers: { authorization: token }
                }).then((response) => {
                    let date = new Date();
                    openSnackbar({ message: "Downloading" });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `subscribers-${date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear()}.csv`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }).catch(err => {
                    openSnackbar({ message: "Somewthing went wrong" });
                });
            }}>
                <FontAwesomeIcon icon={faUsers} style={{ color: '#2F4F4F' }}></FontAwesomeIcon>  Subscribers
            </li>
            <li onClick={ () => {
              props.history.push("/dashboard/planner")
            }}>
              <FontAwesomeIcon icon={faArchive} style={{ color: '#2F4F4F' }}></FontAwesomeIcon> Planners Report
            </li>
            <li onClick={props.logout}>
                <FontAwesomeIcon icon={faSignOutAlt} style={{ color: '#2F4F4F' }}></FontAwesomeIcon>  Logout
            </li>
        </ul>
    );
}

export default withRouter(AdminNav);
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Banner from '../../images/event-banner.jpg';
import axios from '../../axios/axios';
import Notifier, { openSnackbar } from '../utility/Notifier';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  banner: {
    background: `url(${Banner})`,
    height: 300,
    backgroundSize: 'cover',
    width: '100%',
    backgroundPosition: 'center',
    position: 'relative'
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'absolute',
    margin: '0 auto',
    padding: '0 15px',
    bottom: 0,
    height: 300,
    color: '#fff'
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  numbers: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '30px',
  },
  text: {
    color: '#FFF',
    fontSize: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  number: {
    fontSize: 40,
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  }
})



class EventSession extends React.Component {
  constructor(props) {
    super(props) 

      this.state = {
        session: '',
        months: '',
        people: ''
      }
  }

  fetchSessions = () => {
    axios.get('admin/fetchSessionInfo')
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({
              session: result.data.sessions.length === 0 ? "" : result.data.sessions[0].sessions,
              months: result.data.sessions.length === 0 ? "" : result.data.sessions[0].months,
              people: result.data.sessions.length === 0 ? "" : result.data.sessions[0].people,
            },()=>{
              console.log(this.state)
            });
          } else {
            openSnackbar({ message: result.data.msg });
          }
        } else {
          openSnackbar({ message: "Something went wrong" });
        }
      }).catch(exception => {
        openSnackbar({ message: exception });
      });
  }

  componentDidMount() {
    this.fetchSessions();
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Notifier />
        <div className={ classes.banner }>
          <div className={ classes.overlay }>
              <div className={ classes.overlayContainer}>
                <h1 className={classes.heading}>Achievements</h1>
                <div className={ classes.numbers }>
                  <Grid item lg={4} sm={12}>
                    <Typography className={ classes.number }>{ this.state.session }</Typography>
                    <Typography className={ classes.text }>SESSIONS</Typography>
                  </Grid>
                  <Grid item lg={4} sm={12}>
                    <Typography className={ classes.number }>{ this.state.months }</Typography>
                    <Typography className={ classes.text }>MONTHS</Typography>
                  </Grid>
                  <Grid item lg={4} sm={12}>
                    <Typography className={ classes.number }>{ this.state.people }</Typography>
                    <Typography className={ classes.text }>PEOPLE</Typography>
                </Grid>
                </div>
              </div>
          </div>
        </div>
      </Grid>
    )
  }
}

export default withStyles(styles)(EventSession);
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import BlogAuthor from '../../images/blog_image.jpg'
import Abhishek from '../../images/abhishek.jpg'
import Anisha from '../../images/Anisha.jpg'
import Chirag from '../../images/chirag.jpg'
import Nithin from '../../images/nithin_blog.jpg'
import Shweta from '../../images/shweta_blog.jpg'


const styles = theme => ({
  pageHeading: {
    fontSize: '42px',
    fontFamily: 'Playfair Display',
    color: '#fff'
  },
  bannerContainer: {
    width: '100%',
    background: '#000',
    textAlign: 'center',
    padding: '40px 10px',
    color: '#fff'
  },
  mainArticle: {
    marginTop: '50px',
    width: '70%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    }

  },
  smallHeading: {
    fontSize: '15px',
    fontFamily :'Merriweather',
    letterSpacing: '2px',
    color: '#29B5C3',
    margin: '1.113rem 0',
  },
  bigHeading: {
    fontSize: '42px',
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    }
  },
  authorStyles: {
    marginTop: '30px',
    marginBottom: '50px',
    display: 'flex'
  },
  authorImage: {
    width: '55px',
    borderRadius: '50%',
    marginRight: '25px'
  },
  authorTitle: {
    fontWeight: '400',
    fontSize: '1.2rem',
    letterSpacing: '2.5px'
  },
  bodyText: {
    fontSize: '18px',
    lineHeight: '1.5',
    fontFamily: 'Lato',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    }
  },
  innerHeading: {
    fontSize: '18px',
    color: '#000',
    letterSpacing: '2px',
    marginTop: '40px',
    marginBottom: '20px',
    fontFamily: 'Lato',
    textTransform: 'uppercase'
  },
  innerImages: {
    width: '300px',
    height: '300px',
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      width: '350px',
      height: '350px'
    }
  },
  innerImagesContainer: {
    textAlign: 'center',
  },
  quote: {
    fontWeight: 300,
    fontSize: '18px',
    color: '#000',
    borderLeft: '8px solid #29B5C3',
    paddingLeft: '15px',
    fontFamily: 'Lato',
    letterSpacing: '2px',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    }
    
  }
})

class BlogPost extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Grid container className={classes.bannerContainer}>
          <Grid item xs={12}>
            <Typography className={classes.pageHeading} variant="h1" align="center">Magazine</Typography>
          </Grid>
        </Grid>
        <Grid container className={ classes.mainArticle}>
          <Grid item xs={ 12 }>
            <Typography className={ classes.smallHeading} variant="h6">MOTHER'S DAY 2019</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bigHeading} variant="h2">Money lessons we learned from our mothers</Typography>
          </Grid>
          <Grid item xs={12} className={ classes.authorStyles }>
            <Grid>
              <img className={classes.authorImage} src={BlogAuthor} alt=""/>
            </Grid>
            <Grid>
                <Typography className={classes.authorTitle} variant="h5">BY INVESTOGRAPHY</Typography>
                <time dateTime="2019-05-12">May, 12 2019</time>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bodyText} variant="body1">Mothers and life lessons go hand in hand but knowingly or unknowingly, they’ve played a significant role in imparting money management lessons to us as well. While they may not have been finance ninjas, there’s no one who can handle money truly like they do. So, in order to thank them for everything and more this Mother’s Day, we asked our team to share money lessons that they learned from their mothers. And we got some great pictures to go with it so you can go awwww..  </Typography>
            <Typography className={classes.innerHeading} variant="h6">Abhishek Kumar, Financial Coach in Mumbai</Typography>
            <Grid className={classes.innerImagesContainer}>
              <img className={classes.innerImages} src={Abhishek} alt="Abhishek Kumar"/>
            </Grid>
            <Typography className={classes.quote} variant="subheading">She told your own money gives you strength. Save money for a purpose in life and be happy that you don't have to ask anyone for it. Depending on others to fulfil your wishes isn't the right way to live.</Typography>
            <hr className="shine" />
            <Typography className={classes.innerHeading} variant="h6">Anisha Jain, Asst Financial Coach in Bengaluru</Typography>
            <Grid className={classes.innerImagesContainer}>
              <img className={classes.innerImages} src={Anisha} alt="Anisha Jain"/>
            </Grid>
            <Typography className={classes.quote} variant="subheading">My mom rewarded me for saving pocket money which gave me the motivation to save more.</Typography>
            <hr className="shine" />
            <Typography className={classes.innerHeading} variant="h6">Chirag Sanghvi, Financial Coach in Ahmedabad</Typography>
            <Grid className={classes.innerImagesContainer}>
              <img className={classes.innerImages} src={Chirag} alt="Chirag Sanghvi"/>
            </Grid>
            <Typography className={classes.quote} variant="subheading">Use your money wisely. How much you earn does not matter but how much you save from that money matters more. She always tells me to invest for growth.</Typography>
            <hr className="shine" />
            <Typography className={classes.innerHeading} variant="h6">Nithin Sasikumar, Co-Founder</Typography>
            <Grid className={classes.innerImagesContainer}>
              <img className={classes.innerImages} src={Nithin} alt="Nithin Sasikumar"/>
            </Grid>
            <Typography className={classes.quote} variant="subheading">You can’t live without a budget. Little things do add up. Not only does writing down some of these things teach you about where you’re spending but it also makes for memories later. </Typography>
            <hr className="shine" />
            <Typography className={classes.innerHeading} variant="h6">Shweta Jain, Founder</Typography>
            <Grid className={classes.innerImagesContainer}>
              <img className={classes.innerImages} src={Shweta} alt="Shweta Jain"/>
            </Grid>
            <Typography className={classes.quote} variant="subheading">My mum taught me how to save. Saving small amounts and stashing them for a rainy day is really important. Things could go wrong and when they do, this stash helps us more than you think.</Typography>
            <hr className="shine" />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

BlogPost.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BlogPost)
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import axios from '../../axios/axios'
import Notifier, { openSnackbar } from '../utility/Notifier'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { cleanUrl } from '../../functions'
import Header from './Header'

const styles = theme => ({
  container: {
    width: '80%',
    marginTop: '3rem',
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '80px',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    }
  },
  sectionHeadline: {
    fontSize: '15px',
    lineHeight: 1,
    letterSpacing: '2px',
    marginBottom: '50px',
  },
  video: {
    paddingTop: '25px',
    paddingBottom: '20px',
    maxWidth: '575px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #CBC8C5'
    },
  },
  videoSection: {
    display: 'flex',
    '& figure': {
      width: '90px',
      height: '90px',
      marginRight: '15px',
      flexShrink: 0,
      '& img': {
        width: '100%',
        maxWidth: '100%',
        height: '100%'
      }
    },
    '& div': {
      '& a': {
        textDecoration: 'none',
        color: '#29B5C3',
        textTransform: 'uppercase',
        lineHeight: 1,
        letterSpacing: '2px',
      },
      '& h3': {
        marginTop: '10px',
        '& a': {
          color: '#000',
          letterSpacing: 0,
          fontSize: '18px',
          fontWeight: 'normal',

        }
      }
    }
  }
})

class VideoPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videoBlogs: [],
    }
  }

  componentDidMount() {
    this.fetchAllVideoBlogs()
  }

  fetchAllVideoBlogs = () => {
    const body = {
      tag: "video",
      offset: 0,
      limit: 200
    }
    axios.post('/blog/byTag', body)
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({ videoBlogs: result.data.blogs })
          } else {
            openSnackbar({ message: result.data.message })
          }
        } else {
          openSnackbar({ message: result.data.message })
        }
      })
      .catch(err => openSnackbar({ message: err.message }))
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Header />
        <Notifier />
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <p className={classes.sectionHeadline}>{this.state.videoBlogs.length} ARTICLES TAGGED "VIDEO"</p>
          </Grid>
          {
            this.state.videoBlogs.map(videoBlog => {
              return (
                <Grid key={videoBlog.id} item xs={12} className={classes.video} >
                  <article className={classes.videoSection}>
                    <figure>
                      <Link to={`/magazine/blog-details?title=${cleanUrl(videoBlog.title)}&blog=${videoBlog.id}`}>
                        <img src={videoBlog.imageURL} alt="" />
                      </Link>
                    </figure>
                    <div className={classes.videoSectionText}>
                      <Link to={`/magazine/section?section=${videoBlog.section}`}>{videoBlog.sectionName}</Link>
                      <h3 style={{ cursor: 'pointer' }} onClick={() => {
                        const URL = `/magazine/blog-details?title=${cleanUrl(videoBlog.title)}&blog=${videoBlog.id}`
                        this.props.history.push(URL)
                      }}>{videoBlog.title}</h3>
                    </div>
                  </article>
                </Grid>
              )
            })
          }
        </Grid>
      </React.Fragment>
    )
  }
}

VideoPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VideoPage)
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InvestographyLogo from '../../images/investography-footer.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = theme => ({
    copyright: {
        backgroundColor: '#29B5C3',
        padding: '15px 0',
    },
    copyrightText: {
        color: '#FFFFFF',
        fontSize: '.8rem',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    container: {
        width: '80%',
        margin: '15px auto',
        padding: '0 15px',
        [theme.breakpoints.up('xl')]: {
            width: '70%',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
    },
    logo: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        marginBottom: '20px',
        justifyContent: 'center',
    },
    about: {
        fontSize: '.8rem',
        letterSpacing: '1px',
        [theme.breakpoints.up('xl')]: {
            fontSize: '1rem',
        },
    },
    socialText: {
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: '1px',
        padding: '5px 0',
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.2rem',
        },
    },
    social: {
        marginBottom: '30px',
        '& a': {
            marginRight: '15px',
            fontSize: '1.5rem',
        }
    },
    facebook: {
        color: '#4267b2',
    },
    twitter: {
        color: '#38A1F3',
    },
    linkedin: {
        color: '#0077B5',
    },
    youtube: {
        color: '#ED3833',
    },
    instagram: {
        color: '#e1306c',
    },
    navLink: {
        '& a': {
            textDecoration: 'none',
            fontFamily: 'Lato',
            fontSize: '.7rem',
            fontWeight: 700,
            color: '#29B5C3',
            display: 'block',
            padding: '8px 0',
            letterSpacing: '1px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '.8rem',
            },
        },
    },
    navigation: {
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: '1px',
        marginBottom: '10px',
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.2rem',
        },
    },
});

const Footer = props => {
    const { classes } = props;
    return (
        <footer>
            <Grid container className={classes.container} spacing={16}>
                <Grid container item lg={12} justify="center" >
                    <div className={classes.logo}>
                        <img src={InvestographyLogo} alt="investography logo" />
                    </div>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography variant="h6" className={classes.navigation}>
                        ABOUT
                    </Typography>
                    <Typography variant="body2" paragraph className={classes.about}>
                        Investography was born with a simple objective – to spread awareness about money and to enable wealth creation through quality goal-based investment planning.
                    </Typography>
                    <div className={classes.social}>
                        <Typography variant="h6" className={classes.socialText}>CONNECT</Typography>
                        <a className={classes.facebook} target="_blank" href="https://www.facebook.com/investography.pvt.ltd/"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
                        <a className={classes.twitter} target="_blank" href="https://twitter.com/i4investography?lang=en"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                        <a className={classes.linkedin} target="_blank" href="https://in.linkedin.com/company/investography"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                        <a className={classes.youtube} target="_blank" href="https://youtu.be/-UkuypFGXWo"><FontAwesomeIcon icon={['fab', 'youtube']} /></a>
                        <a className={classes.instagram} target="_blank" href="https://www.instagram.com/investography/"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                    </div>
                </Grid>
                <Grid item lg={2} xs={4}>
                    <div>
                        <Typography variant="h6" className={classes.navigation}>
                            COMPANY
                        </Typography>
                        <div className={classes.navLink}>
                            <Link to="/about">ABOUT US</Link>
                            <Link to="/media">MEDIA</Link>
                            <Link to="/events">EVENTS</Link>
                            <Link to="/coming-soon">BLOG</Link>
                            <Link to="/contact-us">CONTACT US</Link>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={2} xs={4}>
                    <div>
                        <Typography variant="h6" className={classes.navigation}>
                            SERVICES
                        </Typography>
                        <div className={classes.navLink}>
                            <Link to="/financial-wellness">FINANCIAL WELLNESS</Link>
                            <Link to="/financialEducation">FINANCIAL EDUCATION</Link>
                            <Link to="/diy">DIY</Link>
                            <Link to="/womenInvestment">WOMEN & INVESTING</Link>
                            <Link to="/wealth">PRIVATE WEALTH</Link>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={2} xs={4}>
                    <div>
                        <Typography variant="h6" className={classes.navigation}>
                            IMPORTANT
                        </Typography>
                        <div className={classes.navLink}>
                            <Link to="/termCondition">T & C</Link>
                            <Link to="/faq">FAQ</Link>
                            <Link to="/privacyPolicy">PRIVACY POLICY</Link>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <div>
                        <Typography variant="h6" className={classes.navigation}>
                            ADDRESS
                        </Typography>
                        <div className={classes.address}>
                            <Typography variant="body2" paragraph className={classes.about}>
                                91Springboard<br /> Residency Road, <br /> Bengaluru, India -560025
                            </Typography>
                            <Typography variant="body2" paragraph className={classes.about}>
                                wecare@investography.in
                            </Typography>
                            <Typography variant="body2" paragraph className={classes.about}>
                                +91 9880 511 320
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={12}
                justify="center"
                className={classes.copyright}
            >
                <Typography variant="body2" className={classes.copyrightText}>© 2018 - { new Date().getFullYear() }. All Rights Reserved</Typography>
            </Grid>
        </footer>
    );
};

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '@material-ui/core';

const styles = ({
    header: {
        textAlign: "center",
        marginBottom: '10px',
    },
    inputField: {
        width: "100%",
        margin: "10px 0",
    },
    footer: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
    },
    loginButton: {
        color: "blue",
        fontSize: '16px',
        fontWeight: 700,
        letterSpacing: '1px',
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    registerButton: {
        fontSize: '16px',
        fontWeight: 700,
        letterSpacing: '1px',
        color: '#FFFFFF',
    },
});

const Register = (props) => {
    const { classes } = props;
    return (
        <Fragment>
            <DialogContent>
                <div className={ classes.header }>
                    <h3 className={ classes.heading }>Sign Up with Investography</h3>
                    <p>Easily create an account with us, it would not take more than couple of minutes.</p>
                </div>
                <form autoComplete="on" onSubmit={ props.onRegisterSubmit }> 
                    <TextField
                        className={ classes.inputField }
                        type="email" 
                        label="Email"
                        value={ props.email }
                        onChange={ props.onEmailChange }
                    />
                    <TextField
                        className={ classes.inputField }
                        type="password" 
                        label="Password"
                        value={ props.password }
                        onChange={ props.onPasswordChange }
                    />
                    <TextField
                        className={ classes.inputField }
                        type="password" 
                        label="Confirm Password"
                        value={ props.confirmPassword }
                        onChange={ props.onConfirmPasswordChange }
                    />
                    <div className={ classes.footer }>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={ classes.registerButton }
                        >
                            Register
                        </Button>
                        <Button
                            onClick={ props.loginButtonClicked }
                            className={ classes.loginButton }
                        >
                            Already have an account
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Fragment>
    );
};

Register.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Register);
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import App from '../../images/app.png';
import Retirement from '../../images/retirement.png';
import Money from '../../images/money.png';
import Financial from '../../images/financial.svg';
import Risk from '../../images/risk.png';
import Cards from './Cards';
import { withRouter } from 'react-router-dom';
import { firebase } from '../../firebase/firebase';
import LoginContainer from '../account/LoginContainer';
import Notifier, { openSnackbar } from '../utility/Notifier';

const style = theme => ({
    container: {
        margin: '0 auto',
        padding: '0 15px',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    planner: {
        backgroundColor: '#FFFFFF',
        padding: '4rem 0',

        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    heading: {
        paddingBottom: '3rem',
        '&:after': {

        }
    },
    headingColor: {
        color: '#000000',
        fontWeight: 400,
        fontSize: '2.5rem',
        letterSpacing: '1px',
    },
    box: {
        transition: 'all .3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
        },
    },
});

class Planners extends Component {

    linkToApp = (link) => {
        this.props.history.push(link);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.planner} >
                <Grid className={classes.heading} item xs={12} container justify="center">
                    <Typography className={classes.headingColor} variant="h4">PLANNERS</Typography>
                </Grid>
                <Grid
                    container
                    className={classes.container}
                >
                    <Grid item xs={12} container justify="center" alignItems="center" spacing={8}>
                        <Grid item xs={12} lg={4} >
                            <img className={classes.image} src={App} alt=" " />
                        </Grid>
                        <Grid item xs={12} lg={8} container justify="center" spacing={16}>
                            <Grid item sm={7} lg={6} className={classes.box} onClick={() => {
                                this.props.history.push('/retirement-planner')
                            }}>
                                <Cards
                                    image={Retirement}
                                    heading="RETIREMENT PLANNER"
                                    text="Calculate how much you need to grow your wealth to ensure a smooth and hassle free post retirement life."
                                />
                            </Grid>
                            <Grid item sm={7} lg={6} className={classes.box} onClick={() => {
                                this.props.history.push('/moneyMap')
                            }}>
                                <Cards
                                    image={Money}
                                    heading="MONEY MAPS"
                                    text="Get a map with paths to achieve your dreams just by telling us what matters to you. Let us just be your guide."
                                />
                            </Grid>
                            <Grid item sm={7} lg={6} className={classes.box} onClick={() => {
                                this.props.history.push('/risk-profile')
                            }}>
                                <Cards
                                    image={Risk}
                                    heading="RISK PROFILE"
                                    text="Understand your attitude to security and the level of risk you are prepared to accept for your investments."
                                />
                            </Grid>
                            <Grid item sm={7} lg={6} className={classes.box} onClick={() => {
                                this.props.history.push('/financial-health')
                            }}>
                                <Cards
                                    image={Financial}
                                    heading="FINANCIAL CHECKUP"
                                    text="Find out your own financial health and pinpoint areas where you need more work to achieve your Goals."
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Planners.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(style)(Planners));
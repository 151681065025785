import React from 'react'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'


const Wysiwyg = props => {
    return (
        <React.Fragment>
            <ReactQuill
                theme={props.theme}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                modules={Wysiwyg.modules}
                formats={Wysiwyg.formats}
            />
        </React.Fragment>
    )
}

export default Wysiwyg

Wysiwyg.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video', 'code'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

Wysiwyg.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'code'
]

Wysiwyg.propTypes = {
    placeholder: PropTypes.string,
}
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#29B5C3',
        },
        secondary: {
            main: '#FEBE16',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Lato',
            'sans-serif',
        ].join(','),
        htmlFontSize: '12',
        body2: {
            fontSize: '16px',
            marginBottom: '10px',
        },
        h6: {
            fontSize: '16px',
            letterSpacing: '2px',
            fontWeight: 400,
            textTransform: 'uppercase',
        },
        h4: {
            letterSpacing: '1px',
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: '2.5rem',
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                transition: 'top 0.3s',
            },
        },
        MuiToolbar: {
            root: {
                backgroundColor: '#FFFFFF',
                fontFamily: 'Lato',
                fontWeight: 700,
                letterSpacing: 2,
                fontSize: 13,
                minHeight: '50px !important'
            }
        },
    },
});

export {
    theme,
}
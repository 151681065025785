import React from 'react';
import axios from '../../axios/axios';
import { Redirect } from 'react-router-dom';
import './admin.css';
import AdminNav from './AdminNav';
import Notifier, { openSnackbar } from '../utility/Notifier';

class Annoucement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            id: '',
            update: false,
            token: sessionStorage.getItem('jwtToken')
        }

        this.annoucementRef = React.createRef();
    }

    componentDidMount() {
        axios.get('admin/fetchBannerTexts', { headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.data) {
                    this.annoucementRef.current.focus();
                    if (result.data.texts.length !== 0) {
                        this.setState(() => ({
                            text: result.data.texts[0].text,
                            id: result.data.texts[0].id,
                            update: true
                        }));
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: exception });
            });
    }

    handleChange = (e) => {
        this.setState({
            text: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.update) {
            axios.post('admin/updateBannerText', this.state, { headers: { authorization: `Bearer ${this.state.token}` } })
                .then(result => {
                    openSnackbar({ message: result.data.msg });
                }).catch(exception => {
                    openSnackbar({ message: exception });
                });
        } else {
            axios.post('admin/saveBannerText', { text: this.state.text }, { headers: { authorization: `Bearer ${this.state.token}` } })
                .then(result => {
                    window.Materialize.toast(result.data.msg, 3000);
                }).catch(exception => {
                    window.Materialize.toast(exception, 3000);
                });
        }
    }

    render() {
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }} />
                </div>
                <div id="annoucement-content">
                    <Notifier />
                    <div className="container">
                        <h4>Announcement Manage Section</h4>
                        <br />
                        <form id="annoucementForm" onSubmit={this.handleSubmit}>
                            <div>
                                <label>Announcement</label>
                                <textarea onChange={this.handleChange} name="description" value={this.state.text} ref={this.annoucementRef} />
                            </div>
                            <button className="addButton" onClick={this.handleSubmit}>{this.state.update ? 'Update' : 'Save'}</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Annoucement;
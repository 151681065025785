import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const styles = theme => ({
    linksItem: {
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        textDecoration: 'none',
        color: '#29B5C3',
    },
    links: {
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    popper: {
        zIndex: 9999,
    },
});

class NavbarDropdownWhat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    };

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleClose = event => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <Button
                    className={this.props.className}
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    aria-owns={open ? "menu-list" : undefined}
                    aria-haspopup="true"
                    aria-label="Dropdown"
                    onClick={this.handleClick}
                >
                    WHAT WE DO

                    <ArrowDropDown />
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    className={classes.popper}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list"
                            style={{ transformOrigin: placement === 'bottom' ? 'center-top' : 'center-bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <MenuItem className={classes.links}>
                                            <Link onClick={this.handleClose} className={classes.linksItem} to="/financial-wellness">FINANCIAL WELLNESS</Link>
                                        </MenuItem>
                                        <MenuItem className={classes.links}>
                                            <Link onClick={this.handleClose} className={classes.linksItem} to="/financialEducation">FINANCIAL EDUCATION</Link>
                                        </MenuItem>
                                        <MenuItem className={classes.links}>
                                            <Link onClick={this.handleClose} className={classes.linksItem} to="/diy">DIY</Link>
                                        </MenuItem>
                                        <MenuItem className={classes.links}>
                                            <Link onClick={this.handleClose} className={classes.linksItem} to="/womenInvestment">WOMEN & INVESTING</Link>
                                        </MenuItem>
                                        <MenuItem className={classes.links}>
                                            <Link onClick={this.handleClose} className={classes.linksItem} to="/wealth">PRIVATE WEALTH</Link>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
};

NavbarDropdownWhat.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavbarDropdownWhat);
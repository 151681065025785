import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Transition } from 'react-transition-group';
import Login from './Login';
import Register from './Register';
import { auth, FacebookAuthProviderLogin, GoogleAuthProviderLogin } from '../../firebase/firebase';
import Notifier, { openSnackbar } from '../utility/Notifier';

const styles = ({
    button: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 100%)',
        color: '#FFFFFF',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '1px',
        padding: '0 16px',
        '&:hover': {
            background: 'linear-gradient(135deg, #FE6B8B 30%, #FF8E53 90%)',
        }
    },
});

class LoginContainer extends React.Component {
    state = {
        open: false,
        showComponent: false,
        email: "",
        password: "",
        confirmPassword: ""
    }

    onClick = () => {
        this.setState({ open: !this.state.open });
    };

    onClose = () => {
        this.setState({ open: !this.state.open })
    };

    registerButtonClicked = () => {
        this.setState({ showComponent: !this.state.showComponent });
    };

    loginButtonClicked = () => {
        this.setState({ showComponent: !this.state.showComponent });
    };

    onLoginSubmit = (e) => {
        e.preventDefault();
        auth.signInWithEmailAndPassword(this.state.email, this.state.password)
            .then((result) => {
                this.setState({ open: false })
            }).catch((error) => {
                openSnackbar({ message: error.message });
            });
    }

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
    };

    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({ password }));
    };

    onConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        this.setState(() => ({ confirmPassword }));
    };

    onRegisterSubmit = (e) => {
        e.preventDefault();
        if (this.state.password === this.state.confirmPassword) {
            auth.createUserWithEmailAndPassword(this.state.email, this.state.password).then(() => {
                this.setState({ open: false })
            }).catch((error) => {
                openSnackbar({ message: error });
            })
        }
    }

    onFacebookClick = () => {
        auth.signInWithPopup(FacebookAuthProviderLogin).then(() => {
            this.setState({ open: false });
        }).catch(error => {
            openSnackbar({ message: error });
        })
    };

    onGoogleClick = () => {
        auth.signInWithPopup(GoogleAuthProviderLogin).then(() => {
            this.setState({ open: false })
        }).catch(error => {
            openSnackbar({ message: error });
        })
    };

    toggleModal = () => {
        const showComponent = this.state.showComponent;
        switch (showComponent) {
            case true:
                return <Register
                    loginButtonClicked={this.loginButtonClicked}
                    email={this.state.email}
                    password={this.state.password}
                    confirmPassword={this.state.confirmPassword}
                    onConfirmPasswordChange={this.onConfirmPasswordChange}
                    onEmailChange={this.onEmailChange}
                    onPasswordChange={this.onPasswordChange}
                    onRegisterSubmit={this.onRegisterSubmit}
                />;
            default:
                return <Login
                    registerButtonClicked={this.registerButtonClicked}
                    email={this.state.email}
                    password={this.state.password}
                    onLoginSubmit={this.onLoginSubmit}
                    onFacebookClick={this.onFacebookClick}
                    onGoogleClick={this.onGoogleClick}
                    onEmailChange={this.onEmailChange}
                    onPasswordChange={this.onPasswordChange}
                />
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Notifier />
                <Button
                    onClick={this.onClick}
                    variant="contained"
                    className={classes.button}
                    size="small"
                >
                    Login
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.onClose}
                    TransitionComponent={Transition}
                >

                    {this.toggleModal()}
                </Dialog>
            </React.Fragment>
        );
    }
};

LoginContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginContainer);
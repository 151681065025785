import axios from '../../axios/axios';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Notifier, { openSnackbar } from '../utility/Notifier';
import Hero from '../herohome/hero/Hero';


const styles = theme => ({
    contactUs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        marginTop: '20px',
    },
    contactUsContainer: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    textField: {
        width: '70%',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    button: {
        display: 'block',
        color: '#fff',
        background: '#3F51B5',
        textAlign: 'center',
        letterSpacing: '0.4em',
        marginTop: '2em',
        marginBottom: '2em',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    quote: {
        paddingLeft: '0.5rem',
        borderLeft: '1px solid #000000',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    width: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    margin: {
        margin: '1em 0',
    },
    fontColor: {
        color: '#777777',
    },
    headingFont: {
        fontSize: '28px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            marginTop: '2em',
        },
    },
});

class ContactPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            phone: "",
            companyName: "",
            message: ""
        }
    }

    name = (e) => {
        e.preventDefault();
        const name = e.target.value;
        this.setState(() => ({ name }));
    };

    email = (e) => {
        e.preventDefault();
        const email = e.target.value;
        this.setState(() => ({ email }));
    };

    phone = (e) => {
        e.preventDefault();
        const phone = e.target.value;
        this.setState(() => ({ phone }));
    };

    companyName = (e) => {
        e.preventDefault();
        const companyName = e.target.value;
        this.setState(() => ({ companyName }));
    };

    message = (e) => {
        e.preventDefault();
        const message = e.target.value;
        this.setState(() => ({ message }));
    };

    sendMail = (e) => {
        e.preventDefault();
        if (this.state.name === '' || this.state.name === undefined) {
            openSnackbar({ message: "Please Enter name" });
        } else if (this.state.email === '' || this.state.email === undefined) {
            openSnackbar({ message: "Please Enter Valid email" });
        } else if (this.state.phone === '' || this.state.phone === undefined) {
            openSnackbar({ message: "Please Enter Contact" });
        } else if (this.state.companyName === '' || this.state.companyName === undefined) {
            openSnackbar({ message: "Please Enter Company name" });
        } else if (this.state.message === '' || this.state.message === undefined) {
            openSnackbar({ message: "Please Enter your message" });
        } else {

            axios.post("contactUs/mail", this.state)
                .then((result) => {
                    if (result.data.success) {
                        openSnackbar({ message: 'Thank you for contacting us!' });
                        this.setState({
                            name: "",
                            email: "",
                            phone: "",
                            companyName: "",
                            message: ""
                        });
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                }).catch(exception => {
                    openSnackbar({ message: 'Something went wrong' });
                });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Hero image="contact-us.png" />
                <div className={classes.contactUs}>
                    <Notifier />
                    <div className={classes.contactUsContainer}>
                        <Grid container>
                            <Grid item
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Typography
                                    variant="body2"
                                    className={classNames(classes.quote, classes.width, classes.margin, classes.fontColor)}
                                >
                                    <b>Connect with us today </b><br /><span><i> - The "future you" will be grateful.</i></span>
                                </Typography>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={6}
                                lg={6}
                            >
                                <Typography
                                    variant="body2"
                                    className={classNames(classes.width, classes.margin)}
                                >
                                    Thank you for visiting Investography. Please complete the form below, so we can provide quick and effective service.
                            </Typography>
                                <form onSubmit={this.sendMail}>
                                    <TextField
                                        id="filled-name"
                                        label="Name"
                                        className={classes.textField}
                                        onChange={this.name}
                                        type="text"
                                        margin="normal"
                                    />
                                    <TextField
                                        id="filled-email-input"
                                        label="Email"
                                        className={classes.textField}
                                        onChange={this.email}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        margin="normal"
                                    />
                                    <TextField
                                        id="filled-number"
                                        label="Phone"
                                        className={classes.textField}
                                        onChange={this.phone}
                                        type="number"
                                        margin="normal"
                                    />
                                    <TextField
                                        id="filled-name"
                                        label="Company Name"
                                        className={classes.textField}
                                        onChange={this.companyName}
                                        type="text"
                                        margin="normal"
                                    />
                                    <TextField
                                        id="filled-name"
                                        label="Message"
                                        multiline
                                        rows="4"
                                        className={classes.textField}
                                        onChange={this.message}
                                        type="text"
                                        margin="normal"
                                    />
                                    <Grid item
                                        container
                                        justify="center"
                                        className={classes.width}
                                    >
                                        <Button type="submit" variant="contained" className={classes.button}>
                                            Submit
                                </Button>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={6}
                                lg={6}
                            >

                                <Typography
                                    component="h5"
                                    className={classNames(classes.headingFont, classes.fontColor)}
                                    gutterBottom
                                >
                                    Our Locations
                            </Typography>
                                <br />
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                >
                                    <b>ADDRESS</b>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    <i>Investography Pvt. Ltd. - Bengaluru</i>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    <i>91Springboard<br />Residency Road, <br />Bengaluru, India - 560025</i>
                                </Typography>
                                <br />
                                <br />
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                >
                                    <b>OTHER LOCATIONS</b>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    <i>Investography Pvt. Ltd. - Mumbai</i>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    <i>WeWork Raheja Platinum Tower<br />Sag Baug Road, Off Andheri Kurla Road, <br />Marol, Andheri East, Mumbai 400059</i>
                                </Typography>
                                <br />
                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    <i>Investography Pvt. Ltd. - Ahmedabad</i>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    <i>Flexi Business Hub, 2nd Floor, Madhur Complex,<br />Opposite Gwalia Sweets, NR Stadium Cross Road, <br />Navrangpura, Ahmedabad 380009</i>
                                </Typography>
                                <br />
                                <br />
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                >
                                    <b>Mail us at</b>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    wecare@investography.in
                            </Typography>
                                <br />
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                >
                                    <b>Call us at</b>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className={classes.fontColor}
                                >
                                    +91 9880 511 320
                            </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
};

ContactPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactPage);
import React, { Component } from 'react';
import axios from '../../axios/axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import AdminNav from './AdminNav';
import Notifier, { openSnackbar } from '../utility/Notifier';
import './admin.css';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});


class Testimonials extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testimonials: [],
            token: sessionStorage.getItem('jwtToken'),
            open: false
        }
    }

    componentDidMount() {
        axios.get('/admin/fetchTestimonials').then(result => {
            if (result.status === 200) {
                if (result.data.success) {
                    this.setState({
                        testimonials: result.data.testimonials
                    });
                }
            }
        }).catch(exception => {
            openSnackbar({ message: exception });
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                        }}
                    />
                </div>
                <Notifier />
                <div id="fetchEvents">
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell>Testimonial</CustomTableCell>
                                    <CustomTableCell>Name</CustomTableCell>
                                    <CustomTableCell>Designation/Company</CustomTableCell>
                                    <CustomTableCell>Actions</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.testimonials.map((testimonial) => {
                                    return <TableRow className={classes.row} key={testimonial.id}>
                                        <CustomTableCell>{testimonial.testimonial}</CustomTableCell>
                                        <CustomTableCell>{testimonial.name}</CustomTableCell>
                                        <CustomTableCell>{testimonial.by}</CustomTableCell>
                                        <CustomTableCell>
                                            <div>
                                                <button style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    textDecoration: 'underline',
                                                    textTransform: 'uppercase',
                                                    color: '#29B5C3',
                                                    cursor: 'pointer'
                                                }}
                                                    onClick={() => {
                                                        sessionStorage.setItem('testimonial', JSON.stringify(testimonial));
                                                        this.props.history.push('/dashboard/updateTestimonial');
                                                    }}>Update</button>
                                                    <span>  </span>
                                                <button style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    textDecoration: 'underline',
                                                    textTransform: 'uppercase',
                                                    color: '#29B5C3',
                                                    cursor: 'pointer'
                                                }}
                                                    onClick={() => {
                                                        this.setState({ testimonial: testimonial, open: true });
                                                    }}>Delete</button>
                                            </div>
                                        </CustomTableCell>
                                    </TableRow>
                                })}
                                {this.state.testimonial !== null ?
                                    <Dialog
                                        open={this.state.open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={this.handleClose}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle id="scroll-dialog-title">Event Details</DialogTitle>
                                        <DialogContent>

                                            <div className="modal-content">
                                                <strong><p>Are you sure?</p></strong>

                                                <button className="modalFetchEventBT addButton" onClick={() => {
                                                    axios.post('admin/deleteTestimonial/', {
                                                        id: this.state.testimonial.id
                                                    },
                                                        { headers: { authorization: `Bearer ${this.state.token}` } })
                                                        .then(result => {
                                                            if (result.status === 200) {
                                                                if (result.data.success) {
                                                                    this.setState({
                                                                        open: false
                                                                    }, () => { this.componentDidMount(); });
                                                                    openSnackbar({ message: result.data.msg });
                                                                } else {
                                                                    openSnackbar({ message: result.data.msg });
                                                                }
                                                            }
                                                        }).catch(exception => {
                                                            openSnackbar({ message: exception });
                                                        });
                                                }}>Delete</button>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} color="primary">
                                                Cancel</Button>
                                        </DialogActions>
                                    </Dialog>
                                    : null}
                            </TableBody>
                        </Table>
                    </Paper>
                    <button className="addButton" onClick={() => {
                        this.props.history.push('/dashboard/testimonial');
                    }}>Add Testimonial</button>
                </div>
            </div>

        );
    }
}

Testimonials.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Testimonials);
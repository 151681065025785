import React, { Component } from 'react';
import Intro from '../../images/intro.png';
import Women from '../../images/women.png';
import Elephant from '../../images/elephant.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withRouter } from 'react-router-dom';

class HeroHome extends Component {
    render() {
        return (
            <Carousel interval={5000} autoPlay infiniteLoop showThumbs={false}>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        this.props.history.push("/about");
                    }}>
                    <img src={Intro} />
                </div>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        this.props.history.push("/womenInvestment");
                    }}>
                    <img src={Women} />
                </div>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        this.props.history.push("/wealth");
                    }}>
                    <img src={Elephant} />
                </div>
            </Carousel>
        );
    }

};

export default withRouter(HeroHome);
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './media.css';

class Video extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            src: "https://www.youtube.com/embed/?listType=playlist&list=PLaZbCoEe3aTC2S8N4Vg8Eqn8-C9-gLJS9&rel=0",
        };
    }

    changeVideo = (url) => {
        this.setState(() => {
            return {
                src: url
            }
        });
    };

    render() {
        return (
            <div>
                <Grid container justify="center">
                    <Grid item container justify="center" lg={6} sm={10} xs={10}>
                        <iframe title="main-video" src={this.state.src} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen className="video"></iframe>
                        <Typography className="page-font">Curated list of videos featured in various news channel</Typography>
                    </Grid>
                </Grid>
                <br /><br /><br />
                <Grid container justify="center" spacing={8}>
                    <Grid className="video-list--item" item container justify="center" xs={6} sm={3} lg={3} onClick={() => this.changeVideo("https://www.youtube.com/embed/Zi_dVQM52PM?autoplay=1&rel=0")}>
                        <img src="http://i3.ytimg.com/vi/Zi_dVQM52PM/hqdefault.jpg" alt="shweta" className="image" />
                        <Grid item xs={12}>
                            <div className="description">
                                <div>must watch!</div>
                                <Typography className="title">Diversify Your Portfolio | Equity Sahi Hai</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className="video-list--item" item container justify="center" xs={6} sm={3} lg={3} onClick={() => this.changeVideo("https://www.youtube.com/embed/jqxbeKmITvE?autoplay=1&rel=0")} >
                        <img src="https://i.ytimg.com/vi/jqxbeKmITvE/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&amp;rs=AOn4CLAqwnMzKU78u_b7kGQB2b8Q9x8cnw" alt="mutual funds with shweta jain" className="image" />
                        <Grid item xs={12}>
                            <div className="description">
                                <div>must watch!</div>
                                <Typography className="title">Invesments in Volatile Markets | Equity Sahi Hai</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className="video-list--item" item container justify="center" xs={6} sm={3} lg={3} onClick={() => this.changeVideo("https://www.youtube.com/embed/ti5Z0OFQ3iQ?autoplay=1&rel=0")}>
                        <img src="https://i.ytimg.com/vi/ti5Z0OFQ3iQ/hqdefault.jpg?" alt="expert answer mutual fuunds queries with Shweta Jain" className="image" />
                        <Grid item xs={12}>
                            <div className="description">
                                <div>must watch!</div>
                                <Typography className="title">Understanding re-categorisation of mutual funds | ET Wealth</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className="video-list--item" item container justify="center" xs={6} sm={3} lg={3} onClick={() => this.changeVideo("https://www.youtube.com/embed/Fu468yAGdiQ?autoplay=1&rel=0")}>
                        <img src="https://i.ytimg.com/vi/hApMExHfFao/hqdefault.jpg" alt="In which fund you should invest with Shweta Jain" className="image" />
                        <Grid item xs={12}>
                            <div className="description">
                                <div>must watch!</div>
                                <Typography className="title">Mutual Fund Helpline | Zee Business</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
        );
    }
};

export default Video;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
// import financialEducation1 from '../../images/financialEducation.png';
import Hero from '../herohome/hero/Hero';
import ContactSmall from '../contact/contactSmall/ContactSmall';


const styles = theme => ({
    container: {
        width: '80%',
        padding: '1em 0',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    heading: {
        textAlign: 'center',
    },
    sectionPadding: {
        padding: '1em',
        [theme.breakpoints.down('sm')]: {
            padding: '0em 1em',
        }
    },
    margin: {
        margin: '1em 0',
    },
    backgroundGrey: {
        backgroundColor: '#595959',
    },
    subheading: {
        color: '#fff',
        textAlign: 'center',
        fontWeight: 'bold',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        margin: '1.5em 1em',
    },
    fontSizeHeading: {
        fontSize: '19px',
    },
    text: {
        color: '#fff',
        fontSize: '18px',
    },
    textBlack: {
        fontSize: '18px',
    },
    serviceBox: {
        margin: '1em',
        padding: '1em',
        height: '300px',
        [theme.breakpoints.down('lg')]: {
            height: '360px',
        },
        [theme.breakpoints.down('md')]: {
            height: '460px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '240px',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    box: {
        border: '1px solid #fff',
    },
    backgroundGray: {
        backgroundColor: '#D0CECE',
    },
    bannerText: {
        color: 'gray',
        letterSpacing: '0.1em',
        wordSpacing: '0.2em',
        textAlign: 'center',
        width: '80%',
        margin: '0.5em auto',
        [theme.breakpoints.down('sm')]: {
            letterSpacing: '0.1em',
            width: '100%',
            wordSpacing: '0.2em',
            lineHeight: '1.4em',
        },
    },
});

class WealthManagement extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Hero
                    image="elephant.png"
                />
                <Grid container
                    justify="center"
                    className={classes.container}
                >
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        {/* <Grid item
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                        >
                            <Typography variant="h4" className={classes.heading} gutterBottom>Wealth Management</Typography>
                        </Grid> */}

                        <Grid item
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                        >
                            <Typography variant="body2" className={classes.textBlack} gutterBottom>
                                Whether you’re looking to preserve your wealth, planning for future growth, or even if it’s about building a family legacy, we are here to provide guidance that touches every area of your life.
                            </Typography>
                            <Typography variant="body2" className={classes.textBlack} gutterBottom>
                                Benefit from our partnerships with elite investment fund providers and our relationships with global professionals across disciplines. Whatever you need to be confident about your future is here.
                            </Typography>
                            <Typography variant="body2" className={classes.textBlack} gutterBottom>
                                Let us make the most of what matters to you.
                            </Typography>
                        </Grid>

                        <Grid item
                            container
                            className={classes.backgroundGrey}
                            xs={12}
                        >
                            <Grid item
                                xs={12}
                            >
                                <Typography variant="h5" className={classes.subheading} gutterBottom>Go beyond the ordinary. Let the extraordinary define you</Typography>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={4}
                                lg={4}
                            >
                                <div className={classNames(classes.serviceBox, classes.box)}>
                                    <Typography variant="h6" className={classNames(classes.subheading, classes.fontSizeHeading)}>Responsible</Typography>
                                    <Typography variant="body2" className={classes.text}>
                                        With your wealth and with your information.
                                    <br />
                                        <br />
                                        We understand the responsibility that comes with being stewards of your hard-earned wealth. Capital preservation will remain our priority alongside confidentiality.
                            </Typography>
                                </div>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={4}
                                lg={4}
                            >
                                <div className={classNames(classes.serviceBox, classes.box)}>
                                    <Typography variant="h6" className={classNames(classes.subheading, classes.fontSizeHeading)}>Sophisticated</Typography>
                                    <Typography variant="body2" className={classes.text}>
                                        Sophisticated need not be complex.
                                    <br />
                                        <br />
                                        We bring a simple yet sophisticated approach to our investment planning. Whether it’s the humble mutual fund or a structured investment fund, overseas investments or legacy planning through trusts.
                            </Typography>
                                </div>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={4}
                                lg={4}
                            >
                                <div className={classNames(classes.serviceBox, classes.box)}>
                                    <Typography variant="h6" className={classNames(classes.subheading, classes.fontSizeHeading)}>Personal</Typography>
                                    <Typography variant="body2" className={classes.text}>
                                        As your goals change over time, so do the solutions we create. Our expert solutions will evolve to your needs to reflect shared values.
                                    <br />
                                        <br />
                                        What doesn’t change is our commitment to excellence and our deeply personal relationships.
                            </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container
                    xs={12}
                    className={classNames(classes.backgroundGray, classes.margin)}
                >
                    <Typography variant="subtitle1" className={classes.bannerText} gutterBottom>
                        <b>DON’T SETTLE FOR AVERAGE. SETTLE FOR THE BEST</b>
                    </Typography>
                </Grid>
                <ContactSmall title="Start a conversation and get to know us today" page="Wealth Management" />
            </div >
        );
    }
};


WealthManagement.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WealthManagement);
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Shweta from '../../images/shweta.jpg'
import Nithin from '../../images/nithin.jpg'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Hero from '../herohome/hero/Hero';
import { Link } from 'react-router-dom';
import './about.css';

library.add(fab, faTwitter, faLinkedinIn);

const styles = theme => ({
    aboutImage: {
        width: '100%',
        height: 'auto',
        marginBottom: '2em',
    },
    blueFont: {
        textDecoration: 'none',
        color: '#29B5C3',
    },
    link: {
        textDecoration: 'none',
        color: '#29B5C3',
    },
    wordWrap: {
        textAlign: 'justify',
    },
    quote: {
        fontSize: '1.5em',
        color: '#29B5C3',
        textAlign: 'center',
    }
});

class AboutInvestography extends React.Component {
    render() {
        const { classes } = this.props;
        let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'About', to: '' }]
        return (
            <div>
                <Hero image="ourStory.png" />
                <Grid container justify="center">
                    <Grid item lg={9} sm={9} xs={11} container>
                        <Breadcrumbs list={breadcrumbsList} />
                    </Grid>
                    <Grid item lg={9} sm={9} xs={11} container spacing={16}>
                        <br />
                        <Typography variant="body2" className={classes.wordWrap}>
                            Did we wake up one fine morning and say ‘this is it?’ We don’t wish to be that dramatic and say yes, but yes! We realised that if we wished to bridge the growing wealth gap in the country, we needed to start early and with the basics – through <Link to="/financialEducation" className={classes.blueFont}><b>educational institutions</b></Link> and through <Link to="/financial-wellness" className={classes.blueFont}><b>companies</b></Link>.
                        </Typography>
                        <Typography variant="body2" className={classes.wordWrap}>
                            <span className={classes.blueFont}><b>This is our revolution. Our revolution to bridge the wealth gap.</b></span>
                        </Typography>
                        <Typography variant="body2" className={classes.wordWrap}>
                            Investography was born with a simple objective - to have conversations about money. Conversations that help us articulate what it is that we really want from our money. More often than not, the only thing people talk about is where they should be investing and invest like friends and colleagues do. And that’s not the best recipe if you ask us.
                        </Typography>
                        <Typography variant="body2" className={classes.wordWrap}>
                            We take pride in the fact that we are all unique, why then should our investments be similar. Our portfolio should be a reflection of who we are and what we want from life. Growth or certainty? Long term or short term? This might sound a little strange but our conversations about money will be like coming out of the fog and into the sunlight.
                        </Typography>
                        <Typography variant="body2" className={classes.wordWrap}>
                            Founded by <span className={classes.blueFont}><b>Shweta Jain</b></span> and <span className={classes.blueFont}><b>Nithin Sasikumar</b></span> who have complimentary skills and are friends first and colleagues next, they are building a team that not only has expertise but more importantly prides itself on empathy.  Together, they have a shared passion for building an organization that is known for its creativity in designing solutions for financial wellness and education in the country. This is a team is committed to not just excellence but to do good and will work relentlessly to ensure everyone knows the “how” to manage money.
                        </Typography>
                        <Typography variant="body2" className={classes.wordWrap}>
                            With our HQ in Bengaluru, we also have a presence in Mumbai and Ahmedabad. But frankly, we don’t let these locations define our boundaries and we think the whole of the country (and even the world) is our oyster.
                        </Typography>
                        <br />
                        <br />
                    </Grid>
                    <Grid item lg={9} sm={9} xs={12}
                        container
                        spacing={16}
                        justify="space-evenly">
                        <Grid item lg={4} sm={5} xs={10}>
                            <Grid item container justify="center">
                                <div className="team-member">
                                    <div className="team-photo">
                                        <img src={Shweta} alt="Shweta Jain" />
                                    </div>
                                    <div className="team-hover">
                                        <div className="team-text">
                                            <h3>Shweta Jain</h3>
                                            <span>Founder</span>
                                            <div className="social-icons">
                                                <a href="https://twitter.com/shwetanjain" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a href="https://in.linkedin.com/in/shweta-jain-8b289713" tagret="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Typography variant="body2" className={classes.wordWrap}>
                                    <b>Shweta Jain - </b>Shweta is a Certified Financial Planner with over 15 years of experience across financial planning, research and client servicing.  She is also the author of <Link to="magazine" className={classes.link}><b>"My Conversations with Money" </b></Link>(out in January 2019).  With a passion for financial literacy, she has delivered over 1000 financial awareness programs for over 35000 individuals across companies like Google, HP, JP Morgan, and Visa; and has also conducted sessions on behalf of the Securities & Exchange Board of India (SEBI).
                                </Typography>
                                <Typography variant="body2" className={classes.wordWrap}>
                                    Shweta has done courses on behavioural finance and wealth management from IIM-A and IIM-B. She is a frequent contributor on <a href="http://www.moneycontrol.com/elite/profile/shweta-jain_4676.html" target="_blank" className={classes.link}><b>www.moneycontrol.com</b></a>, and is often quoted in print <b><i>(Economic Times, Mint, Outlook Money)</i></b> and a regular face on television <b><i>(Zee Business)</i></b>.
                                </Typography>
                                <Typography variant="body2" className={classes.wordWrap}>
                                    She lives in Bengaluru with her eight year old son Ishaan and husband Puneeth.
                                </Typography>
                                <Typography variant="body2" className={classes.wordWrap}>
                                    You can reach out to her directly on <a href="mailto:shweta@investography.in" className={classes.link}><b>shweta@investography.in</b></a>
                                </Typography>
                                <br />
                            </Grid>
                        </Grid>
                        <Grid item lg={4} sm={5} xs={10}>
                            <Grid item container justify="center">
                                <div className="team-member">
                                    <div className="team-photo">
                                        <img src={Nithin} alt="Nithin Sasikumar" />
                                    </div>
                                    <div className="team-hover">
                                        <div className="team-text">
                                            <h3>Nithin Sasikumar</h3>
                                            <span>Co - Founder</span>
                                            <div className="social-icons">
                                                <a target="_blank" href="https://twitter.com/nithinsasikumar?lang=en"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a target="_blank" href="https://in.linkedin.com/in/nithin-sasikumar-74335330"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Typography variant="body2" className={classes.wordWrap}>
                                    <b>Nithin Sasikumar - </b>After graduating with an MSc in Investment Management from the Cass Business School in London, Nithin spent 4 years with a boutique financial advisory company in Bengaluru. Here, he focused on investment & product research and creating portfolio strategies for clients.
                                </Typography>
                                <Typography variant="body2" className={classes.wordWrap}>
                                    As a millennial who is a firm believer that financial advice isn’t just for the uber wealthy, he’s passionate about simplifying the financial jargon and making investing knowledge available to everyone. His investment philosophy is best captured by a quote from Morgan Housel <strong><i>- “The key to building wealth isn't necessarily high returns, but mediocre returns sustained for the longest period of time.”</i></strong>
                                </Typography>
                                <Typography variant="body2" className={classes.wordWrap}>
                                    He lives in Bengaluru with his wife Shruthi and their three year old son Charlie (a golden retriever).
                                </Typography>
                                <Typography variant="body2" className={classes.wordWrap}>
                                    You can reach out to him directly on <a href="mailto:nithin@investography.in" className={classes.link}><b>nithin@investography.in</b></a>
                                </Typography>
                                <br />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={9} sm={9} xs={11} container spacing={16}>
                        <br />
                        <Typography variant="body2" className={classes.wordWrap}>
                            Educate, Empathize and Empower. We believe that by educating people about money management, being empathetic about what people most want in life and empowering them to make the right choices, we can bring a change to how we each one of us perceives money. While schools and colleges prepare us for the careers ahead, nobody teaches us how to manage money. We are here to ensure that we prepare people about basic money skills. What they should do, what they shouldn’t and what they should beware of. From savings accounts, to filing taxes to investing et al.
                        </Typography>
                        <br />
                        <br />
                    </Grid>
                    <Grid item lg={9} sm={9} xs={11}
                        container
                        justify="center">
                        <Grid item className={classes.quote}>
                            <blockquote><i><b>Our Story isn’t ‘About Us’. It’s ‘About You’.</b></i></blockquote>
                            <br />
                            <br />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

AboutInvestography.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(AboutInvestography);
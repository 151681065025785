import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Annoucement from './components/announcement/Announcement';
import { theme } from './theme';
import ScrollToTop from './components/utility/ScrollToTop';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

// Website Import
import Navbar from './components/header/Navbar';
import Footer from './components/footer/Footer';
import Home from './components/view/Home';
import Events from './components/view/Events';
import Media from './components/view/Media';
import Contact from './components/contact/Contact';
import About from './components/about/About';
import FinancialWellness from './components/financialWellness/FinancialWellness';
import RetirementPlanner from './components/userProfile/apps/retirementPlanner/RetirementPlanner';
import FinancialInputs from './components/userProfile/apps/FinancialHealth';
import RiskProfile from './components/userProfile/apps/RiskProfile';
import MoneyMap from './components/userProfile/apps/moneyMaps/MoneyMaps';
import Disclaimer from './components/disclamer/Disclaimer';
import FAQ from './components/disclamer/FAQ';
import PrivacyPolicy from './components/disclamer/PrivacyPolicy';
import withAuthentication from './components/session/withAuthentication';
import UserProfile from './components/userProfile/UserProfile';
import RiskProfileResult from './components/userProfile/apps/result/RiskProfileResult';
import FinancialHealthResult from './components/userProfile/apps/result/FinancialHealthResult';
import FinancialEducation from './components/financialEducation/financialEducation';
import WomenInvestment from './components/womenInvestment/WomenInvestment';
import DIYPlanner from './components/diyPlanner/DiyPlanner';
import WealthManagement from './components/wealthManagement/wealthManagement';
import ComingSoon from './components/coming/Coming';
import Magazine from './components/magazine';
import BlogPost from './components/blog/BlogPost';
import VideoPage from './components/magazine/videoPage'
import BlogDetails from './components/magazine/BlogDetails'
import BlogCategory from './components/magazine/Category';

// Admin Import 
import Login from './components/admin/Login'
import Dashboard from './components/admin/dashboard'
import SaveEvent from './components/admin/SaveEvent';
import UpdateEvent from './components/admin/UpdateEvent';
import FetchEvents from './components/admin/FetchEvents';
import Announcement from './components/admin/Announcement';
import ManageBlog from './components/admin/ManageBlog';
import ManageAuthor from './components/admin/ManageAuthor';
import Testimonials from './components/admin/Testimonials';
import Testimonial from './components/admin/Testimonial';
import UpdateTestimonial from './components/admin/UpdateTestimonial';
import Planner from './components/admin/Planner'
import UpdateSessions from './components/admin/UpdateSessions'
import BlogSection from './components/admin/BlogSection'
import BlogManagement from './components/admin/BlogManagement';
import BlogPostAdmin from './components/admin/BlogPost';

library.add(faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube);

class App extends Component {
  render() {
    return (
      <Fragment>
        <CssBaseline />

        <Router>
          <div>
            <ScrollToTop>
              <MuiThemeProvider theme={theme}>
                <Annoucement />
                <Navbar />
                <main>
                  <Route path="/" component={Home} exact />
                  <Route path="/media" component={Media} />
                  <Route path="/events" component={Events} />
                  <Route path="/contact-us" component={Contact} />
                  <Route path="/about" component={About} />
                  <Route path="/financial-health" component={FinancialInputs} />
                  <Route path="/financial-wellness" component={FinancialWellness} />
                  <Route path="/retirement-planner" component={RetirementPlanner} />
                  <Route path="/risk-profile" component={RiskProfile} />
                  <Route path="/moneyMap" component={MoneyMap} />
                  <Route path="/termCondition" component={Disclaimer} />
                  <Route path="/faq" component={FAQ} />
                  <Route path="/privacyPolicy" component={PrivacyPolicy} />
                  <Route path="/profile" component={UserProfile} />
                  <Route path="/riskProfileResult" component={RiskProfileResult} />
                  <Route path="/financialHealthResult" component={FinancialHealthResult} />
                  <Route path="/financialEducation" component={FinancialEducation} />
                  <Route path="/womenInvestment" component={WomenInvestment} />
                  <Route path="/diy" component={DIYPlanner} />
                  <Route path="/wealth" component={WealthManagement} />
                  <Route path="/coming-soon" component={ComingSoon} />
                  <Route path="/mothers-day" component={BlogPost} />
                  <Route path="/magazine" component={Magazine} exact />
                  <Route path="/magazine/tag/video" component={VideoPage} exact />
                  <Route path="/magazine/blog-details" component={BlogDetails} exact />
                  <Route path="/magazine/section" component={BlogCategory} exact />

                  <Route path="/admin" component={Login} exact />
                  <Route path="/dashboard" component={Dashboard} exact />
                  <Route path="/dashboard/events" component={FetchEvents} exact />
                  <Route path="/dashboard/quote" component={Announcement} exact />
                  <Route path="/dashboard/saveEvent" component={SaveEvent} exact />
                  <Route path="/dashboard/updateEvent" component={UpdateEvent} exact />
                  <Route path="/dashboard/manageBlog" component={ManageBlog} exact />
                  <Route path="/dashboard/manageAuthor" component={ManageAuthor} exact />
                  <Route path="/dashboard/testimonials" component={Testimonials} exact />
                  <Route path="/dashboard/testimonial" component={Testimonial} exact />
                  <Route path="/dashboard/updateTestimonial" component={UpdateTestimonial} exact />
                  <Route path="/dashboard/planner" component={Planner} exact />
                  <Route path="/dashboard/sessions" component={UpdateSessions} exact />
                  <Route path="/dashboard/blogSection" component={BlogSection} exact />
                  <Route path="/dashboard/blogManagement" component={BlogManagement} exact />
                  <Route path="/dashboard/blogPost" component={BlogPostAdmin} exact />
                </main>
                <Footer />
              </MuiThemeProvider>
            </ScrollToTop>
          </div>
        </Router>

      </Fragment>
    );
  }
}

export default withAuthentication(App);

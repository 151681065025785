import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import book from '../../images/book2.jpg';
import bullet from '../../images/bullet.png';
import tax from '../../images/tax.png';
import rich from '../../images/rich.png';
import dsp from '../../images/dsp.png';
import idiot from '../../images/idiot.png';
import franklin from '../../images/franklin.png';
import idfc from '../../images/idfc.png';

import Blogs from '../blog/Blog';

const styles = theme => ({
    container: {
        width: "80%",
        margin: "0 auto"
    },
    image: {
        width: '80%',
        height: 'auto',
        margin: '1.5em',
    },
    buyNow: {
        textDecoration: 'none',
        color: '#fff',
        fontWeight: 'bold',
        letterSpacing: '0.1em',
        wordSpacing: '0.2em',
        textAlign: 'center',
        padding: '0.5em',
        backgroundColor: 'orange',
        borderRadius: '5px',
        marginBottom: '1em',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1em auto',
    },
    margin: {
        margin: '1em 0',
        fontFamily: 'Playfair Display, serif'
    },
    heading: {
        fontFamily: 'Playfair Display, serif',
        margin: '2em 0 1em 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
        }
    },
    headingKnowledge: {
        fontFamily: 'Playfair Display, serif',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
        }
    },
    fullWidth: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    knowledgeBankImage: {
        width: '50%',
        height: '50px',
        marginBottom: '1em',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        }
    },
    idfcImage: {
        width: '40%',
        marginBottom: '1em',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        }
    },
    knowledgeBankMargin: {
        margin: '0 1em',
        fontFamily: 'Playfair Display, serif'
    },
    sectionMargin: {
        margin: '30px 0'
    },
    box: {
        border: '1px dotted blue'
    }
})

class Magazine extends Component {
    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                {/* <Grid
                    className={classes.box}
                    xs={12}
                    lg={12}
                    md={12}
                >
                    <List>
                        <ListItem>
                            Investing
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            Money & Life
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            Career
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            Disrupt Money
                        </ListItem>
                    </List>
                </Grid> */}


                <Grid container>

                    {/* Our Books */}
                    <Grid item
                        container
                        justify="center"
                        className={classes.sectionMargin}
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                        >
                            <Typography variant="h4" component="h3" align="center" className={classes.heading} gutterBottom>
                                OUR BOOKS
                            </Typography>
                        </Grid>
                        <Grid item
                            container
                            justify="center"
                            alignItems="center"
                            xs={12}
                            md={4}
                        >
                            <img src={book} alt="Book" className={classes.image} />
                            <a href="https://www.amazon.in/My-Conversations-Money-SHWETA-JAIN/dp/9387860442/ref=sr_1_1?ie=UTF8&qid=1548253290&sr=8-1&keywords=shweta+jain" target="_blank" className={classes.buyNow}>BUY NOW</a>
                        </Grid>
                        <Grid item
                            container
                            alignItems="center"
                            xs={12}
                            md={8}
                        >
                            <Typography variant="body2" component="blockquote" align="justify" className={classes.margin} gutterBottom>
                                “The book is meticulously written to not only cater to new investors but also to the evolved ones since it highlights the mistakes one usually overlooks. The book checks all boxes of simplicity & idea implementation and will definitely stand out as the must read from the crowd.” <b>- Radhika Gupta, CEO, Edelweiss Asset Management</b>
                            </Typography>
                            <Typography variant="body2" component="blockquote" align="justify" className={classes.margin} gutterBottom>
                                “Shweta combines lucid writing with practical tips for readers who are interested in getting more out of their hard-earned money. Shweta has been a crusader for better money management since I’ve known her. In her focus and in her writing, Shweta is relentless” <b>- Rishi Kakar, Director- Marketing, IDFC Mutual Fund</b>
                            </Typography>
                            <Typography variant="body2" component="blockquote" align="justify" className={classes.margin} gutterBottom>
                                "Conversations with Money is an honest book about money and the mistakes we make with it. Told by Shweta in an easy to understand manner, this book is a must read for those starting out on their journey in life. With practical examples, it tells you how to make your life better." <b>- Vivek Law, Founder, The MoneyMile</b>
                                <br />
                            </Typography>
                        </Grid>
                    </Grid>


                    {/* The Knowledge Bank */}
                    <Grid item
                        container
                        justify="center"
                        className={classes.sectionMargin}
                        xs={12}
                    >
                        <Typography variant="h4" component="h3" align="center" className={classes.headingKnowledge}>
                            THE KNOWLEDGE BANK
                            </Typography>
                        <Grid item
                            container
                            justify="space-between"
                            className={classes.sectionMargin}
                            xs={12}
                        >
                            <Grid item
                                container
                                justify="center"
                                className={classes.sectionMargin}
                                xs={12}
                                md={5}
                            >
                                <a href="https://learn.dspim.com/homepage" target="_blank" className={classes.link}>
                                    <img src={dsp} alt="DSP Mutual Fund" className={classes.knowledgeBankImage} />
                                </a>
                                <Typography variant="body2" component="blockquote" className={classes.knowledgeBankMargin} gutterBottom>
                                    Whether you’re a novice, an amateur or nearly an expert, DSP Mutual Fund’s learning centre has you sorted
                            </Typography>
                            </Grid>
                            <Grid item
                                container
                                justify="center"
                                className={classes.sectionMargin}
                                xs={12}
                                md={5}
                            >
                                <a href="https://www.youtube.com/watch?v=vU1l1TB7GzI" target="_blank" className={classes.link}>
                                    <img src={idiot} alt="IDFC Foundation Initiative about systematic investing" className={classes.knowledgeBankImage} />
                                </a>
                                <Typography variant="body2" component="blockquote" className={classes.knowledgeBankMargin} gutterBottom>
                                    When IDFC Foundation decided to make a movie about systematic investing, they created a video that was both informative and funny
                            </Typography>
                            </Grid>
                            <Grid item
                                container
                                justify="center"
                                className={classes.sectionMargin}
                                xs={12}
                                md={5}
                            >
                                <a href="http://us.beyondbullsandbears.com/" target="_blank" className={classes.link}>
                                    <img src={franklin} alt="around the Globe" className={classes.knowledgeBankImage} />
                                </a>
                                <Typography variant="body2" component="blockquote" className={classes.knowledgeBankMargin} gutterBottom>
                                    For a closer look at what’s happening around the globe
                            </Typography>
                            </Grid>
                            <Grid item
                                container
                                justify="center"
                                className={classes.sectionMargin}
                                xs={12}
                                md={5}
                            >
                                <a href="https://www.youtube.com/watch?v=QrnlvLA8gMs" target="_blank" className={classes.link}>
                                    <img src={idfc} alt="Realities of retirement by IDFC" className={classes.idfcImage} />
                                </a>
                                <Typography variant="body2" component="blockquote" className={classes.knowledgeBankMargin} gutterBottom>
                                    A movie on the realities of retirement told by IDFC MF and Amol Gupte
                            </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

Magazine.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Magazine)
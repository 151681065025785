import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Notifier, { openSnackbar } from '../utility/Notifier'
import axios from '../../axios/axios'
import NativeSelect from '@material-ui/core/NativeSelect'
import Input from '@material-ui/core/Input'
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  headerContainer: {
    width: '100%',
    padding: '40px 10px',
    background: '#29B5C3',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  container: {
    maxWidth: '60%',
    paddingLeft: '15px',
    paddingRight: '15px',
    margin: '0 auto',
    '& div': {
      '& h1': {
        fontSize: '42px',
        color: '#fff',
        marginBottom: '15px',
        fontFamily: 'Playfair Display, serif',
        textAlign: 'center',
        cursor: 'pointer'
      }
    }
  },
  mobileHeaderContainer: {
    width: '100%',
    padding: '40px 10px',
    background: '#29B5C3',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  navLink: {
    listStyleType: 'none',
    display: 'flex',
    fontSize: '12px',
    lineHeight: 1,
    letterSpacing: '2px',
    marginTop: '3px',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#fff',
      fontWeight: 700,
      borderBottom: '1px solid transparent',
      marginLeft: '17px',
      textTransform: 'uppercase',
      paddingBottom: '3px',
      '&:active': {
        borderBottom: '1px solid #fff',
      }
    }
  }
})

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sections: [],
      selectedOptions: ''
    }
  }

  componentDidMount() {
    this.fetchAllSection();
  }

  fetchAllSection = () => {
    axios.get('blog/fetchSection')
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({
              sections: result.data.sections
            });
          } else {
            openSnackbar({ message: result.data.msg })
          }
        } else {
          openSnackbar({ message: result.data.msg })
        }
      }).catch(exception => {
        openSnackbar({ message: exception.message });
      });
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Notifier />
        <Grid container className={classes.headerContainer}>
          <Grid item xs={12} className={classes.container}>
            <div>
              <h1 onClick={() => {
                this.props.history.push('/magazine');
              }}>Magazine</h1>
              <nav>
                <ul className={classes.navLink}>
                  {
                    this.state.sections.map(section => {
                      const URL = `/magazine/section?section=${section.id}`
                      return (<li key={section.id}><Link to={URL}>{section.name}</Link></li>)
                    })
                  }
                </ul>
              </nav>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.mobileHeaderContainer}>
          <Grid item xs={12} className={classes.container}>
            <div>
              <h1>Magazine</h1>
              <Grid item xs={12}>
                <h2 style={{ textAlign: 'center' }}> <NativeSelect
                  value={this.state.selectedOptions}
                  onChange={(e) => {
                    this.setState({
                      selectedOptions: e.target.value
                    }, () => {
                      const URL = `/magazine/section?section=${this.state.selectedOptions}`
                      this.props.history.push(URL);
                    });
                  }}
                  input={<Input name="" />}
                  className={classes.selectedOptions}
                >
                  {this.state.sections.map(section => <option key={section.id} value={section.id}>{section.name}</option>)}
                </NativeSelect>
                </h2>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(Header))
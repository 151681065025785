import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import women from '../../images/womenInvestment2.png';
import Hero from '../herohome/hero/Hero';
import ContactSmall from '../contact/contactSmall/ContactSmall';

const styles = theme => ({
    subheading: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        margin: '1.5em 1em',
    },
    fontSizeHeading: {
        fontSize: '19px',
    },
    text: {
        color: '#fff',
        fontSize: '18px',
    },
    serviceBox: {
        margin: '1em',
        padding: '1em',
        height: '300px',
        [theme.breakpoints.down('lg')]: {
            height: '440px',
        },
        [theme.breakpoints.down('md')]: {
            height: '460px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '240px',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    box: {
        border: '1px solid #fff',
    },
    backgroundBlue: {
        backgroundColor: '#00bbf0',
    },
    backgroundPink: {
        backgroundColor: '#e40475',
    },
    image: {
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            height: '500px',
        },
        [theme.breakpoints.down('sm','xs')]: {
            height: '100%',
        },
    },
});

class WomenInvestment extends Component {


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Hero 
                    image="women.png"
                />
                <Grid container
                    justify="center"
                >
                    <Grid item
                        className={classes.backgroundBlue}
                        xs={12}
                        md={11}
                        lg={9}
                        xl={8}
                    >
                        <Typography variant="h4" className={classes.subheading}>BECAUSE YOU DESERVE THE BEST</Typography>
                    </Grid>

                    <Grid item
                        container
                        className={classes.backgroundBlue}
                        xs={12}
                        md={11}
                        lg={9}
                        xl={8}
                    >
                        <Grid item
                            xs={12}
                            md={4}
                            lg={4}
                        >
                            <div className={classNames(classes.serviceBox, classes.box)}>
                                <Typography variant="h6" className={classNames(classes.subheading, classes.fontSizeHeading)}>Financial Inclusivity</Typography>
                                <Typography variant="body2" className={classes.text}>
                                    Our mission through The Equality Collective is to promote inclusive money conversations with both men and women. Real power isn’t about putting another demographic down, it’s about raising them up and breaking the rigid constructs that have created these biases.
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={4}
                            lg={4}
                        >
                            <div className={classNames(classes.serviceBox, classes.box)}>
                                <Typography variant="h6" className={classNames(classes.subheading, classes.fontSizeHeading)}>Tailored Solutions</Typography>
                                <Typography variant="body2" className={classes.text}>
                                    We understand that women have different character traits than men, have different financial, personal and social priorities. We are able to tailor our solutions to your unique needs.
                                    <br />
                                    <br />
                                    Our team really gets it.
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={4}
                            lg={4}
                        >
                            <div className={classNames(classes.serviceBox, classes.box)}>
                                <Typography variant="h6" className={classNames(classes.subheading, classes.fontSizeHeading)}>Breaking stereotypes</Typography>
                                <Typography variant="body2" className={classes.text}>
                                    The design for The Equality Collective seeks to portray gender fluidity which is why we have a blue outline for the pink half of the heart and a pink outline for the blue half.
                                    <br />
                                    <br />
                                    Let’s join together to help women break the glass ceiling in all things personal finance too.
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item
                        container
                        xs={12}
                        md={11}
                        lg={9}
                        xl={8}
                    >
                        <img src={women} alt="Women & Investment" className={classes.image} />
                    </Grid>


                    <Grid item
                        container
                        xs={12}
                        md={11}
                        lg={9}
                        xl={8}
                    >
                        <Grid item
                            className={classes.backgroundPink}
                            xs={6}
                        >
                            <Typography variant="subtitle1" className={classes.subheading}>IT'S TIME</Typography>
                        </Grid>
                        <Grid item
                            className={classes.backgroundBlue}
                            xs={6}
                        >
                            <Typography variant="subtitle1" className={classes.subheading}>TO CHANGE THE NARRATIVE</Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <ContactSmall title="We’d Love to Connect With You" page="Women & Investment" />
            </div >
        );
    }
};

WomenInvestment.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WomenInvestment);
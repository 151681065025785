import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Coffee from '../../images/coffee.jpg'
import axios from '../../axios/axios'
import Notifier, { openSnackbar } from '../utility//Notifier'
import { withRouter } from 'react-router-dom';
import { cleanUrl } from '../../functions'

const styles = theme => ({
  container: {
    width: '80%',
    margin: '0 auto',
    paddingTop: '80px',
    paddingBottom: '38px',
  },
  sectionHeading: {
    '& h2': {
      fontSize: '30px',
      fontFamily: 'Playfair Display, serif',
      fontWeight: '400',
      textAlign: 'center',
      marginBottom: '36.75px',
    },
  },
  section: {
    padding: '0 12.5px',
  },
  border: {
    borderBottom: '1px solid #CBC8C5',
  },
  articlePreviewImg: {
    paddingTop: '25px',
    paddingBottom: '20px',
    display: 'flex',
    '& figure': {
      width: '90px',
      height: '90px',
      flexShrink: 0,
      marginRight: '15px',
      overflow: 'hidden',
      '& img': {
        width: '90px',
        height: '90px',
        objectFit: 'cover',
      }
    },
    '& div': {
      flex: '0 0 70%',
      '& a': {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: '#29B5C3',
      },
      '& h3': {
        fontSize: '18px',
        lineHeight: 1.33,
        fontFamily: 'Playfair Display, serif',
        marginTop: '10px',
        margnBottom: 0,
        paddingRight: '20px',
        fontWeight: 400,
        textOoverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
      }
    }
  },
  hiddenSM: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
});

class More extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      blogs: []
    }
  }

  componentDidMount() {
    this.fetchAllBlogs()
  }

  fetchAllBlogs = () => {
    const body = {
      offset: 0,
      limit: 9
    }

    axios.post('/blog', body)
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({ blogs: result.data.blogs })
          } else {
            openSnackbar({ message: result.data.msg })
          }
        } else {
          openSnackbar({ message: result.data.msg })
        }
      })
      .catch(err => openSnackbar({ message: err.message }))

  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Notifier />
        <Grid container className={classes.container} justify="center">
          <Grid className={classes.sectionHeading} item xs={12}>
            <h2>More from Investography</h2>
          </Grid>
          {
            this.state.blogs.map(blog => {
              return (
                <Grid key={blog.id} className={classes.section} item xs={12} lg={4} md={12}>
                  <article className={classes.articlePreviewImg}>
                    <figure>
                      <img src={blog.imageURL} alt="coffee" style={{ cursor: 'pointer' }} onClick={() => {
                        const URL = `/magazine/blog-details?title=${cleanUrl(blog.title)}&blog=${blog.id}`
                        this.props.history.push(URL)
                      }} />
                    </figure>
                    <div>
                      <Link to={`/magazine/section?section=${blog.section}`}>{blog.sectionName}</Link>
                      <h3 style={{ cursor: 'pointer' }} onClick={() => {
                        const URL = `/magazine/blog-details?title=${cleanUrl(blog.title)}&blog=${blog.id}`
                        this.props.history.push(URL)
                      }}>{blog.title}</h3>
                    </div>
                  </article>
                </Grid>
              )
            })
          }
        </Grid>
      </React.Fragment>
    )
  }
}


More.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(More))
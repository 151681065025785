import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom';
import { cleanUrl } from '../../functions'
import axios from '../../axios/axios';

const styles = theme => ({
  container: {
    width: '80%',
    margin: '0 auto',
    marginTop: '3rem',
    paddingBottom: '2em',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    }
  },
  sectionBanner: {
    margin: '2em 0',
  },
  featuredArticle: {
    width: '575px',
    position: 'relative',
    color: '#fff',
    cursor: 'pointer'
  },
  featuredArticleImage: {
    width: '575px',
    height: '406px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  featuredArticleText: {
    position: 'absolute',
    padding: '0 30px',
    zIndex: 1,
    paddingBottom: '54px',
    bottom: 5,
    width: '575px',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'block',
      width: '100%',
      backgroundColor: 'transparent',
      color: '#000',
      paddingLeft: 0,
    },
    '& span': {
      letterSpacing: '2px',
      fontSize: '15px',
      textTransform: 'uppercase',
      lineHeight: '26.25px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    '& h1': {
      fontSize: '36px',
      fontFamily: 'Playfair Display, serif',
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      }
    }
  },
  author: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  sectionSidebar: {
    marginLeft: '25px',
    '&:not(:first-child)': {
      borderTop: '1px solid #CBC8C5',
      paddingTop: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  sectionSidebarPreview: {
    display: 'flex',
    flex: '0 0 32%',
    paddingBottom: '20px',

    '& figure': {
      width: '90px',
      height: '90px',
      marginRight: '15px',
      flexShrink: 0,
      '& img': {
        width: '90px',
        height: '90px',
        objectFit: 'cover',
      }
    },
    '& a': {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#29B5C3',
    },
    '& h2': {
      fontSize: '18px',
      lineHeight: 1.33,
      fontFamily: 'Playfair Display, serif',
      marginTop: '10px',
      margnBottom: 0,
      paddingRight: '20px',
      fontWeight: 400,
    }
  },
  sectionSidebarImage: {
    width: '100%',
    maxWidth: '100%',
  },
  sectionFooterPreview: {
    width: '300px',
    marginTop: '20px',
    '& a': {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#29B5C3',
    },
    '& h2': {
      fontSize: '18px',
      lineHeight: 1.33,
      fontFamily: 'Playfair Display, serif',
      marginTop: '10px',
      margnBottom: 0,
      paddingRight: '20px',
      fontWeight: 400,
    }
  }
})

class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      featuredArticleMain: {},
      featuredList: []
    }
  }

  fetchFeaturedList = () => {
    axios.post('blog/featured', {
      offset: 0,
      limit: 7
    }).then(result => {
      if (result.status === 200) {
        // console.log("Featured : ", result.data);
        if (result.data.success) {
          this.setState({
            featuredArticleMain: result.data.blogs[0] ? result.data.blogs[0] : {},
            featuredList: result.data.blogs
          }, () => {
            console.info(result.data.blogs);
          });
        } else {

        }
      } else {
        console.log(result.data.msg);
      }
    }).catch(exception => {

    });
  }

  componentDidMount() {
    this.fetchFeaturedList();
  }


  render() {
    const { classes } = this.props
    return (
      <Grid container className={classes.container} spacing={0}>
        <Grid onClick={() => {
          const URL = `/magazine/blog-details?title=${cleanUrl(this.state.featuredArticleMain.title)}&blog=${this.state.featuredArticleMain.id}`
          this.props.history.push(URL)
        }} item lg={7} xs={12} className={classes.featuredArticle}>
          <img className={classes.featuredArticleImage} src={this.state.featuredArticleMain.imageURL} alt="evie" />
          <div className={classes.featuredArticleText}>
            <span>FEATURED STORY</span>
            <h1>{this.state.featuredArticleMain.title}</h1>
            <div>
              <span className={classes.author}>BY INVESTOGRAPHY TEAM</span>
            </div>
          </div>

        </Grid>
        <Grid item container lg={4} xs={12}>
          {
            this.state.featuredList.slice(1, 4).map(blog => {
              return (
                <Grid key={blog.id} item lg={12} xs={12} className={classes.sectionSidebar}>
                  <article className={classes.sectionSidebarPreview}>
                    <figure>
                      <img className={classes.sectionSidebarImage} src={blog.imageURL} alt={blog.title} />
                    </figure>
                    <div>
                      <Link to={`/magazine/section?section=${blog.section}`}>{blog.sectionName}</Link>
                      <h2 style={{ cursor: 'pointer' }} onClick={() => {
                        const URL = `/magazine/blog-details?title=${cleanUrl(blog.title)}&blog=${blog.id}`
                        this.props.history.push(URL)
                      }}>{blog.title}</h2>
                    </div>
                  </article>
                </Grid>
              )
            })
          }
        </Grid>
        <Grid item container xs={11} className={classes.sectionFooter}>
          {
            this.state.featuredList.slice(4).map(blog => {
              return (
                <Grid key={blog.id} item lg={4} xs={12}>
                  <article className={classes.sectionFooterPreview}>
                    <Link to={`/magazine/section?section=${blog.section}`}>
                      {blog.sectionName}
                    </Link>
                    <h2 style={{ cursor: 'pointer' }} onClick={() => {
                      const URL = `/magazine/blog-details?title=${cleanUrl(blog.title)}&blog=${blog.id}`
                      this.props.history.push(URL)
                    }}>{blog.title}</h2>
                  </article>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    )
  }
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(Banner))
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
    tagline: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
    },
    taglineText: {
        fontSize: '22px',
        textAlign: 'center',
        lineHeight: 2,
        letterSpacing: '4px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '17px',
            letterSpacing: '2px',
        },
    },
});

const TaglineExcel = props => {
    const { classes } = props;
    return (
        <Grid
            container
            spacing={0}
            className={classes.tagline}
        >
            <Grid
                item
                xs={12}
            >
                <p className={classes.taglineText}>EDUCATE - EMPOWER - EMPATHIZE</p>
            </Grid>
        </Grid>
    )
};

TaglineExcel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(TaglineExcel);
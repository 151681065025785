import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from '../../axios/axios';
import TextField from '@material-ui/core/TextField';
import './admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faUser, faImage, faImages, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { storage } from '../../firebase/firebase';
import Notifier, { openSnackbar } from '../utility/Notifier';

library.add(faUser, faTwitter, faLinkedin, faImage, faStickyNote)
const styles = theme => ({
    containerInput: {
        /*  display: 'flex',
         flexWrap: 'wrap', */
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class AddAuthor extends Component {

    state = {
        token: sessionStorage.getItem('jwtToken'),
        selectedFile: null,
        imageUrl: 'https://image.flaticon.com/icons/svg/236/236831.svg',

        name: null,
        twitter: null,
        linkedin: null,
        description: null
    }

    fileSelectorHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
            imageUrl: URL.createObjectURL(event.target.files[0])
        });
    }

    nameChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            name: val
        });
    }

    twitterChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            twitter: val
        });
    }

    linkedinChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            linkedin: val
        });
    }

    descriptionChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            description: val
        });
    }

    saveData = (e) => {
        e.preventDefault();
        const currentDate = new Date();
        const image = this.state.selectedFile;
        const uploadFile = storage.ref(`author_image/${image.name + "-" + currentDate}`).put(image);
        uploadFile.on('state_changed', (snapshot) => {

        }, (err) => {
            openSnackbar({ message: "Something went wrong, while uploading image!" });
            console.log(err);
        }, () => {
            storage.ref('author_image').child(image.name + "-" + currentDate).getDownloadURL()
                .then(url => {
                    let data = {
                        name: this.state.name,
                        twitter: this.state.twitter,
                        linkedin: this.state.linkedin,
                        description: this.state.description,
                        imageUrl: url
                    }

                    axios.post('blog/addAuthor', data, {
                        headers: {
                            authorization: `Bearer ${this.state.token}`
                        }
                    }).then(result => {
                        if (result.status === 200) {
                            if (result.data.success) {
                                this.props.fetchAuthorComponent();
                            } else {
                                openSnackbar({ message: result.data.msg });
                            }
                        } else {
                            openSnackbar({ message: result.data.msg });
                        }
                    }).catch(exception => {
                        openSnackbar({ message: exception.message });
                    });
                });
        });
    }

    render() {
        const { classes } = this.props;
        // let formClasses = [classes.containerInput, "container"].join(" ");
        return (
            <div id="addAuthorForm" >
                <Notifier />
                <h3 style={{ textAlign: 'center', margin: '5px' }}>Add New Author</h3>
                <div className={classes.containerInput}>
                    <br />
                    <section className="addAuthor-section">
                        <form className="autherForm" onSubmit={this.saveData}>
                            <div className="form">
                                <div className="addAuthorForm-inputs">
                                    <FontAwesomeIcon icon={faUser} style={{ color: '#2F4F4F', fontSize: '22px', margin: '5px' }}></FontAwesomeIcon>
                                    <TextField
                                        label="Name"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        type="text"
                                        onChange={this.nameChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="addAuthorForm-inputs">
                                    <FontAwesomeIcon icon={faImages} style={{ color: '#2F4F4F', fontSize: '22px', margin: '5px' }}></FontAwesomeIcon>
                                    <input onChange={this.fileSelectorHandler} type="file"></input>
                                </div>
                                <div className="addAuthorForm-inputs">
                                    <FontAwesomeIcon icon={faTwitter} style={{ color: '#2F4F4F', fontSize: '22px', margin: '5px' }}></FontAwesomeIcon>
                                    <TextField
                                        label="Twitter"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        type="text"
                                        onChange={this.twitterChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="addAuthorForm-inputs">
                                    <FontAwesomeIcon icon={faLinkedin} style={{ color: '#2F4F4F', fontSize: '22px', margin: '5px' }}></FontAwesomeIcon>
                                    <TextField
                                        label="LinkedIn"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        type="text"
                                        onChange={this.linkedinChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="addAuthorForm-inputs">
                                    <FontAwesomeIcon icon={faStickyNote} style={{ color: '#2F4F4F', fontSize: '22px', margin: '5px' }}></FontAwesomeIcon>
                                    <TextField
                                        label="Description"
                                        multiline
                                        rows="4"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        type="text"
                                        onChange={this.descriptionChangeHandler}
                                        required
                                    />
                                </div>
                                <br></br>
                                <button className="addButton">Save</button>
                            </div>
                        </form>
                        <div className="author-image">
                            <img src={this.state.imageUrl} alt="author" style={{ width: '250px', height: 'auto' }}></img>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

AddAuthor.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddAuthor);
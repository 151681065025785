import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage'

const config = {
    apiKey: "AIzaSyDn3b34dF3b-ZYSIaGfAAPkpG22zGPpWuU ",
    authDomain: "investography-2a6de.firebaseapp.com",
    databaseURL: "https://investography-2a6de.firebaseio.com/",
    projectId: "investography-2a6de",
    storageBucket: "investography-2a6de.appspot.com",
    messagingSenderId: "898876443880"
};
/* const config = {
    apiKey: "AIzaSyCqkiLRaV21_qJ_3gp9ZJabbvFIfMJsT7Q",
    authDomain: "investography-22875.firebaseapp.com",
    databaseURL: "https://investography-22875.firebaseio.com/",
    projectId: "investography-22875",
    storageBucket: "investography-22875.appspot.com",
    messagingSenderId: "665342073004"
}; */


firebase.initializeApp(config);

const auth = firebase.auth();
const GoogleAuthProviderLogin = new firebase.auth.GoogleAuthProvider();
const FacebookAuthProviderLogin = new firebase.auth.FacebookAuthProvider();

const storage = firebase.storage();

export {
    firebase,
    auth,
    GoogleAuthProviderLogin,
    FacebookAuthProviderLogin,
    storage
};
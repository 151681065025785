import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

let openSnackbarFn;
const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

class Notifier extends React.Component {
  state = {
    open: false,
    message: '',
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ message }) => {
    this.setState({
      open: true,
      message,
    });
  };

  handleSnackbarClose = (event) => {
    this.setState({
      open: false,
      message: '',
    });
  };

  render() {
    const { classes } = this.props;
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={message}
        autoHideDuration={3000}
        onClose={this.handleSnackbarClose}
        open={this.state.open}
        snackbarcontentprops={{
          'aria-describedby': 'snackbar-message-id',
        }}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            className={classes.close}
            onClick={this.handleSnackbarClose}
            style={{ backgroundColor: 'transparent' }}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

Notifier.propTypes = {
  classes: PropTypes.object.isRequired,
};

export function openSnackbar({ message }) {
  openSnackbarFn({ message });
}

export default withStyles(styles)(Notifier);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { theme } from '../../theme';
import pdf from '../../images/Investography- Purchase, SIP and Biller Registration Process.pdf'
import pdf1 from '../../images/Investography- Redemption Process.pdf'

const styles = theme => ({
    margin: {
        margin: '2em 0',
    },
    textAlign: {
        textAlign: 'center',
    },
    list: {
        lineHeight: 2,
        marginLeft: '1em',
    },
    important: {
        color: 'red',
    },
    link: {
        textDecoration: 'none',
        color: '#29B5C3',
    },
    pdf: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
        paddingBottom: 30,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',

        },
        '& a': {
            width: 200,
            border: '1px solid #000',
            margin: '0 30px',
            padding: '10px',
            [theme.breakpoints.down('xs')]: {
                width: '95%',
                margin: '10px 0',
            },
        }
    }
});

class FAQ extends Component {


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container
                    justify="center"
                    className={classes.margin}
                >
                    <Grid item
                        className={classes.margin}
                        xl={12}
                        lg={12}
                    >
                        <Typography variant="h6" className={classes.textAlign} gutterBottom><b>Frequently Asked Questions (FAQs)</b></Typography>
                    </Grid>

                    <Grid item
                        className={classes.pdf}
                        lg={6}
                    >
                        <a href={pdf1} target="_blank" rel="noopener noreferrer" className={classes.link}>Download Investography- Redemption Process.pdf </a>
                        <a href={pdf} target="_blank" rel="noopener noreferrer" className={classes.link}>Download Investography- Purchase, SIP and Biller Registration Process </a>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <div className={classes.list}>
                            <ul>
                                <li>
                                    <Typography variant="body2" gutterBottom>What is the price of Investography’s retirement calculator?</Typography>
                                    <Typography variant="body2" gutterBottom>The application/calculator is free to use, is ad-free and there are also no pesky in-app purchase prompts at this moment.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" className={classes.important} gutterBottom>Do you save my data? If so, how secure is my data?</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>Why have you assumed life expectancy at 85 when data shows life expectancy at 70 in India?</Typography>
                                    <Typography variant="body2" gutterBottom>Better to be safe than sorry. Since averages are made up of both the upside and downside to the resultant number, we prefer to take a conservative estimate on the upside.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>Why is inflation assumed at 6%?</Typography>
                                    <Typography variant="body2" gutterBottom>The rate of general inflation in India has averaged close to 6% over the past ten years.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>Why have you assumed the rates of return on the conservative, moderate or aggressive portfolio as 8%, 10% and 12%?</Typography>
                                    <Typography variant="body2" gutterBottom>This is based on the long-term average historic returns generated by equity and debt as two distinct asset classes. Conservative portfolios can be considered to have a higher tilt towards debt (~70%) while aggressive portfolios are tilted towards equities (70%) and moderate portfolios are split equally between equity and debt.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>I don’t know what risk profile I fall under, how do I know what to choose?</Typography>
                                    <Typography variant="body2" gutterBottom>While we do not have a risk-profiler built into the app now, you can search for risk profiling questionnaires on the internet to give you a fair understanding of yourself. We do not endorse any one in particular and we cannot vouch for the accuracy of such risk-profilers.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>Is the retirement app easy to use?</Typography>
                                    <Typography variant="body2" gutterBottom>Extremely so. All we ask you for are simple things like the age at which you’d like to retire, your current investments for retirement, your monthly expenditure today what you can save towards your retirement. We’ve done all the hardwork for you by modelling it on spreadsheet programmes so that your focus is only on what it’s going to take for your to retire as per your preferences.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>Is Investography Pvt Ltd a financial advisor?</Typography>
                                    <Typography variant="body2" gutterBottom>Investography Pvt Ltd is registered with the Association of Mutual Funds of India (AMFI) with the ARN-142881 and currently functions as distributor of mutual funds.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" gutterBottom>How do I contact you in case of any queries?</Typography>
                                    <Typography variant="body2" gutterBottom>You can reach out to us at <a href="mailto:wecare@investography.in" className={classes.link}>wecare@investography.in</a> and we’ll be happy to talk.</Typography>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
};


FAQ.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FAQ);
import React from 'react';
import Images from './imagesArray';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images'
import Measure from 'react-measure';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
});

class GalleryImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
            width: -1
        };
    }

    openLightbox = (event, obj) => {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    };

    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <section>
                <Grid container
                    justify="center"
                    className={classes.root}
                >
                    <Grid item
                        xs={12}
                    >
                        <Measure bounds onResize={(contentRect) => this.setState({ width: contentRect.bounds.width })}>
                            {
                                ({ measureRef }) => {
                                    if (this.state.width < 1) {
                                        return <div ref={measureRef}></div>;
                                    }
                                    let columns = 2;
                                    if (this.state.width >= 480) {
                                        columns = 4;
                                    }
                                    if (this.state.width >= 1024) {
                                        columns = 3;
                                    }
                                    if (this.state.width >= 1824) {
                                        columns = 4;
                                    }
                                    return <div ref={measureRef}>



                                        <Gallery photos={Images} columns={columns} onClick={this.openLightbox} />

                                        <Lightbox images={Images}
                                            onClose={this.closeLightbox}
                                            onClickPrev={this.gotoPrevious}
                                            onClickNext={this.gotoNext}
                                            currentImage={this.state.currentImage}
                                            isOpen={this.state.lightboxIsOpen}
                                        />
                                    </div>
                                }
                            }
                        </Measure>
                    </Grid>
                </Grid>
            </section>
        );
    }
};

GalleryImages.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GalleryImages);
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Testimonial from '../../images/testimonial.png';
import axios from '../../axios/axios';
import Notifier, { openSnackbar } from '../utility/Notifier';

const styles = theme => ({
    slider: {
        position: 'relative'
    },
    carouselText: {
        color: '#FFFFFF !important',
        fontSize: '20px',
        padding: '12rem',
        [theme.breakpoints.down('lg')]: {
            padding: '10rem 8rem 4rem 8rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: '6rem 1rem 2rem 1rem',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10rem 2rem 3rem 2rem',
        },
    },
    carousel: {
        backgroundImage: `url(${Testimonial})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 'auto',
        // marginBottom: '2rem',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        }
    },
    heading: {
        position: 'absolute',
        top: '25px',
        backgroundColor: 'transparent',
        width: '100%',
        zIndex: 100,
    },
    headingColor: {
        color: '#FFF !important',
        fontWeight: 700,
        fontSize: '2rem',
        letterSpacing: '1px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.7rem',
        },
    },
});


class Testomial extends React.Component {
    state = {
        testimonials: []
    }

    componentDidMount() {
        axios.get('/admin/fetchTestimonials').then(result => {
            if (result.status === 200) {
                if (result.data.success) {
                    this.setState({
                        testimonials: result.data.testimonials
                    });
                }
            }
        }).catch(exception => {
            openSnackbar({ message: exception });
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Notifier />
                <div className={classes.slider}>
                    <h1 align="center" className={classes.heading}><span className={classes.headingColor}>WHAT OUR CLIENTS SAY</span></h1>
                </div>
                <Carousel className={classes.carousel} autoPlay infiniteLoop showThumbs={false}>
                    {this.state.testimonials.map(testimonial => {
                        return (
                            <div key={testimonial.id} className={classes.background}>
                                <Typography variant="body2" className={classes.carouselText}>{testimonial.testimonial}. <br />- {testimonial.name}</Typography>
                            </div>
                        )
                    })}
                </Carousel>
            </Fragment>
        )
    }
}

Testomial.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Testomial);
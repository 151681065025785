import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { auth, firebase } from '../../firebase/firebase';

const styles = theme => ({
    linksItem: {
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        textDecoration: 'none',
        color: '#29B5C3',
    },
    links: {
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    popper: {
        zIndex: 9999,
    },
    button: {
        background: 'transparent !important',
        color: '#29B5C3',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '1px',
        padding: '0 16px',
        '&:hover': {
            background: 'transparent',
        },
    },
});

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    };

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        const { classes } = this.props;
        const user = firebase.auth().currentUser;
        let name = "";
        if (user) {
            if (user.displayName) {
                name = user.displayName.split(" ");
            }
        }
        return (
            <div>
                <Button
                    className={classes.button}
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    aria-owns={open ? "menu-list" : undefined}
                    aria-haspopup="true"
                    aria-label="Dropdown"
                    onClick={this.handleClick}
                >
                    {user.displayName ? 'Hi, ' + name[0] : 'Account'}

                    <ArrowDropDown />
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    className={classes.popper}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list"
                            style={{ transformOrigin: placement === 'bottom' ? 'center-top' : 'center-bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <MenuItem className={classes.links}>
                                            <Link className={classes.linksItem} to="/profile">PROFILE</Link>
                                        </MenuItem>
                                        <MenuItem className={classes.links}>
                                            <Link onClick={() => {
                                                auth.signOut();
                                            }} className={classes.linksItem}
                                                to="/"
                                            >
                                                LOGOUT
                                        </Link>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
};

Account.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account);
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Proptypes from 'prop-types';
import axios from '../../axios/axios';

const styles = theme => ({
    announcement: {
        background: 'linear-gradient(to top right, #29B5C3, #4682B4)',
        textAlign: 'center',
        color: '#FFFFFF',
        padding: '.5em 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
});



class Announcement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
        };
    }

    componentDidMount() {
        axios.get('admin/fetchBannerTexts')
            .then(result => {
                if (result.data) {
                    if (result.data.texts.length !== 0) {
                        this.setState(() => ({
                            text: result.data.texts[0].text
                        }));
                    }
                }
            }).catch(exception => {
                //window.Materialize.toast(exception, 3000);
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={0} className={classes.announcement}>
                <Grid item xs={12}>
                    <p>{this.state.text}</p>
                </Grid>
            </Grid>
        );
    }
};

Announcement.propTypes = {
    classes: Proptypes.object.isRequired,
};

export default withStyles(styles)(Announcement);
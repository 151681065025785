import React, { Fragment } from 'react';
import axios from '../../axios/axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import './events.css';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import { Grid, Hidden } from '@material-ui/core';
import Notifier, { openSnackbar } from '../utility/Notifier';
import { TextField } from '@material-ui/core';
import EventSession from './EventSession';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    main: {
        width: '80%',
        margin: '0 auto',
        padding: '0 15px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0',
        }
    },
    root: {
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        [theme.breakpoints.down('xs', 'sm')]: {
            margin: '0 0.5em',
        }
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHeading: {
        backgroundColor: "#29b5c3",
        color: theme.palette.common.white,
        padding: '10px',
        textAlign: 'center'
    },
    tableSubHeading: {
        padding: '10px 5px',
        textAlign: 'center',
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        '&:hover': {
            backgroundColor: 'rgba(41,181,195,0.4)'
        }
    },
    titleRow: {
        textAlign: 'left',
    },
    subscribeButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        color: '#FFFFFF',
        letterSpacing: '2px',
        fontSize: '13px',
        fontWeight: 700,
    },
    eventInput: {
        margin: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    cssLabel: {
        fontSize: '0.8rem',
        '&$cssFocused': {
        },
    },
    typographyText: {
        fontSize: '14px',
        letterSpacing: '1px',
    },
    eventText: {
        fontSize: '14px',
        letterSpacing: '1px',
    },
    dialogTitle: {
        fontSize: '16px',
        letterSpacing: '1px',
        fontWeight: '700'
    }
});


class EventsList extends React.Component {

    state = {
        events: [],
        name: '',
        phone: '',
        email: '',
        eventId: '',
        open: false,
        event: null
    }


    nameChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            name: val
        });
    }

    phoneChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            phone: val
        });
    }

    emailChangeHandler = (e) => {
        let val = e.target.value;
        this.setState({
            email: val
        });
    }

    componentDidMount() {
        axios.get("admin/getEvents")
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        let sample = [];
                        sample.push(result.data.events[0])
                        this.setState(() => ({
                            events: result.data.events
                        }))
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: exception });
            });
    }


    registerEvent = (eventId) => {
        axios.post("contactUs/eventRegister",
            {
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                eventId: eventId
            }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        openSnackbar({ message: 'Registration Successful' });
                        this.setState({
                            open: false
                        });
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: exception });
            });
    }

    handleClickOpen = (event) => {
        this.setState({
            open: true,
            event: event
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'Event', to: '' }];
        return (
            <div className={classes.main}>
                <Fragment>
                    <Notifier />
                    <div>
                        <Breadcrumbs list={breadcrumbsList} />
                        <div className={classes.root}>
                            <EventSession />
                        </div>
                        <br />
                        <Paper className={classes.root}>
                            <Grid container className={classes.tableHeading}>
                                <Grid item lg={2} sm={3} xs={3}>
                                    <Typography className={classes.typographyText} variant="inherit">City</Typography>
                                </Grid>
                                <Grid item lg={6} sm={6} xs={6}>
                                    <Typography className={classes.typographyText} variant="inherit">Title</Typography>
                                </Grid>
                                <Grid item lg={2} sm={3} xs={3}>
                                    <Typography className={classes.typographyText} variant="inherit">Date</Typography>
                                </Grid>
                                <Hidden only={['xs', 'sm']}>
                                    <Grid item lg={2}>
                                        <Typography className={classes.typographyText} variant="inherit">Duration</Typography>
                                    </Grid>
                                </Hidden>
                            </Grid>
                            {this.state.events.map((event) => {
                                return <Grid container className={classes.tableSubHeading} key={event.id} onClick={() => this.handleClickOpen(event)}>
                                    <Grid item lg={2} sm={3} xs={3}>
                                        <Typography className={classes.typographyText}>{event.eventCity}</Typography>
                                    </Grid>
                                    <Grid item lg={6} sm={5} xs={5}>
                                        <Typography className={classes.typographyText}>{event.eventTitle}</Typography>
                                    </Grid>
                                    <Grid item lg={2} sm={3} xs={4}>
                                        <Typography className={classes.typographyText}>{event.eventDate}</Typography>
                                    </Grid>
                                    <Hidden only={['xs', 'sm']}>
                                        <Grid item lg={2}>
                                            <Typography className={classes.typographyText}>{event.duration}</Typography>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            })}
                            {this.state.event !== null ?
                                <Dialog
                                    open={this.state.open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">Event Details</DialogTitle>
                                    <DialogContent>

                                        <div>
                                            <Typography className={classes.typographyText}><b>Title: </b>{this.state.event.eventTitle}</Typography>
                                            <br />
                                            <Typography className={classes.typographyText}><b>Description: </b>{this.state.event.eventDescription}</Typography>
                                            <br />
                                            <Typography className={classes.typographyText}><b>Key Note Speaker: </b>{this.state.event.keyNoteSpeaker}</Typography>
                                            <br />
                                            <Typography className={classes.typographyText}><b>Venue: </b>{this.state.event.venue}</Typography>
                                            <br />
                                            <Typography className={classes.typographyText}><b>Start Time: </b>{this.state.event.startTime}</Typography>

                                            <br />
                                            <Grid container spacing={16}>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <TextField
                                                        type="text"
                                                        label="Name"
                                                        required
                                                        onChange={this.nameChangeHandler}
                                                        className={classes.eventInput}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.cssLabel,
                                                            },
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.cssOutlinedInput,
                                                                // notchedOutline: classes.notchedOutline,
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <TextField
                                                        type="email"
                                                        label="Email"
                                                        required
                                                        onChange={this.emailChangeHandler}
                                                        className={classes.eventInput}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.cssLabel,
                                                            },
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.cssOutlinedInput,
                                                                // notchedOutline: classes.notchedOutline,
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} sm={6} xs={12}>
                                                    <TextField
                                                        type="text"
                                                        label="Phone"
                                                        required
                                                        onChange={this.phoneChangeHandler}
                                                        className={classes.eventInput}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.cssLabel,
                                                            },
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.cssOutlinedInput,
                                                                // notchedOutline: classes.notchedOutline,
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} sm={4} xs={12}>
                                                    <Button
                                                        className={classes.subscribeButton}
                                                        variant="contained"
                                                        onClick={() => {
                                                            this.registerEvent(this.state.event.id);
                                                        }}
                                                    >Register</Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                : null}
                        </Paper>

                    </div>
                </Fragment >
            </div>
        );
    }
};

EventsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventsList);
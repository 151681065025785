import React from 'react';
import AuthUserContext from '../session/AuthUserContext';
import NavbarAuth from './NavbarAuth';
import NavbarNonAuth from './NavbarNonAuth';


const Navbar = () =>
    <AuthUserContext.Consumer>
        {authUser => authUser
            ? <NavbarAuth />
            : <NavbarNonAuth />
        }
    </AuthUserContext.Consumer>

export default Navbar;


import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Notifier, { openSnackbar } from '../utility/Notifier'
import NativeSelect from '@material-ui/core/NativeSelect'
import Input from '@material-ui/core/Input'
import axios from '../../axios/axios'
import { withRouter } from 'react-router-dom';
import { cleanUrl } from '../../functions'

const styles = theme => ({
  sectionOptions: {
    marginTop: '2em',
    padding: '50px 0 0 0',
    '& h2': {
      fontSize: '30px',
      fontFamily: 'Playfair Display, serif',
      marginBottom: '5px',
      fontWeight: '400'
    },
  },
  container: {
    width: '80%',
    display: 'flex',
  },
  sectionArticlePreview: {
    width: '80%',
    paddingTop: '25px',
    paddingBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: '0 0 32%',
    },
    '& figure': {
      width: '200px',
      maxHeight: '200px',
      marginBottom: '15px',
      overflow: 'hidden',
      flexShrink: 0,
      '& img': {
        width: '200px',
        height: '200px',
        margin: '0 auto',
        display: 'block',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
          width: '90px',
          height: '90px',
        }
      }
    },
    '& div': {
      marginTop: '10px',
      '& a': {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: '#29B5C3',
        textAlign: 'left'
      },
      '& h3': {
        fontSize: '18px',
        fontFamily: 'Playfair Display, serif',
        fontWeight: 400,
        textAlign: 'left'
      }
    }
  },
  selectedOptions: {
    fontSize: '30px',
    fontFamily: 'Playfair Display, serif',
    marginBottom: '5px',
    fontWeight: '400'
  },

  blogs: {
    margin: '25px 15px',
    '&:not(:first-child)': {
      borderTop: '1px solid #CBC8C5',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      maxWidth: '905px',
      margin: '25px auto',
      borderTop: '0'
    },
    '& .blog': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '20px 0',
      borderBottom: '1px solid #CBC8C5',
      '& figure': {
        width: '90px',
        height: '90px',
        marginRight: '15px',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }
      },
      '&>div': {
        width: 'calc(100% - 90px)',
        '& a': {
          textDecoration: 'none',
          textTransform: 'uppercase',
          color: '#29B5C3',
          letterSpacing: '0.5px',
          textAlign: 'left'
        },
        '& h3': {
          fontSize: '18px',
          fontFamily: 'Playfair Display, serif',
          fontWeight: 400,
          textAlign: 'left',
          cursor: 'pointer'
        }
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
        width: '200px',
        borderBottom: '0',
        '& figure': {
          width: '200px',
          height: '200px',
          marginRight: '0'
        },
        '&>div': {
          width: '100%',
          marginTop: '10px',
          '& h3': {
            marginTop: '5px'
          }
        }
      }
    }
  }
})

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectOptions: "",
      tagBlogs: [],
    }
  }

  handleChange = (e) => {
    this.setState({
      selectOptions: e.target.value
    }, () => this.fetchBlogsByTag())

  }

  fetchBlogsByTag = () => {
    const body = {
      tag: this.state.selectOptions,
      offset: 0,
      limit: 4
    }

    axios.post('/blog/byTag', body)
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({ tagBlogs: result.data.blogs })
          } else {
            openSnackbar({ message: result.data.msg })
          }
        } else {
          openSnackbar({ message: result.data.msg })
        }
      })
      .catch(err => openSnackbar({ message: err.message }))

  }

  fetchRetirementBlog = () => {
    const body = {
      tag: "retire like a boss",
      offset: 0,
      limit: 4
    }

    axios.post('/blog/byTag', body)
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({ tagBlogs: result.data.blogs })
          } else {
            openSnackbar({ message: result.data.msg })
          }
        } else {
          openSnackbar({ message: result.data.msg })
        }
      })
      .catch(err => openSnackbar({ message: err.message }))

  }

  componentDidMount() {
    this.fetchRetirementBlog()
  }


  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Notifier />
        <Grid container className={classes.sectionOptions} justify="center">
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>I want to <NativeSelect
              value={this.state.selectOptions}
              onChange={this.handleChange}
              input={<Input name="" />}
              className={classes.selectedOptions}
            >
              <option value="retire like a boss">retire like a boss</option>
              <option value="start investing now">start investing now</option>
              <option value="invest for impact">invest for impact</option>
              <option value="start a business">start a business</option>
              <option value="live debt free">live debt free</option>
            </NativeSelect>
            </h2>
          </Grid>
          <Grid item xs={12} className={classes.blogs}>
            {
              this.state.tagBlogs.map(blog => {
                return (
                  <article
                    key={blog.id}
                    className="blog">
                    <figure style={{ cursor: 'pointer' }} onClick={() => {
                      const URL = `/magazine/blog-details?title=${cleanUrl(blog.title)}&blog=${blog.id}`
                      this.props.history.push(URL)
                    }}>
                      <img src={blog.imageURL} alt="coffee" />
                    </figure>
                    <div>
                      <Link to={`/magazine/section?section=${blog.section}`}>{blog.sectionName}</Link>
                      <h3 onClick={() => {
                        const URL = `/magazine/blog-details?title=${cleanUrl(blog.title)}&blog=${blog.id}`
                        this.props.history.push(URL)
                      }}>{blog.title}</h3>
                    </div>
                  </article>
                )
              })
            }
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

Options.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(Options))
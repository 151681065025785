import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const style = theme => ({
    goal: {
        backgroundColor: '#29B5C3',
        padding: '1.5rem 0',
    },
    container: {
        width: '80%',
        padding: '0 15px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    quote: {
        textAlign: 'right',
    },
    content: {
        color: '#FFFFFF',
        borderRight: '2px solid #FFFFFF',
        paddingRight: '30px',
        fontSize: '2.0rem',
        letterSpacing: '2px',
        textTransform: 'none'
    },
    author: {
        fontSize: '1rem',
        fontStyle: 'italic',
    },
});

const Goal = props => {
    const { classes } = props;
    return (
        <div className={classes.goal}>
            <Grid
                item
                container
                className={classes.container}
                justify="flex-end"
            >
                <div className={classes.quote}>
                    <Typography
                        className={classes.content}
                        variant="h4"
                    >
                        A goal without a plan is just a wish.<br />
                        <span className={classes.author}>Antoine de Saint-Exupéry -</span>
                    </Typography>
                </div>
            </Grid>
        </div>
    );
};

Goal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Goal);
import React from 'react'
import More from './More'
import Banner from './Banner'
import Video from './Video'
import Options from './Options'
import Header from './Header'
import Magazine from './Magazine'

export default () => (
  <div>
    <Header />
    <Banner />
    <Video />
    <Options />
    <Magazine />
    <More />
  </div>
)
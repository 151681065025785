import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { theme } from '../../theme';
import { Link } from 'react-router-dom'


const styles = theme => ({
    margin: {
        margin: '2em 0',
    },
    textAlign: {
        textAlign: 'center',
    },
});

class Disclaimer extends Component {


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container
                    justify="center"
                    className={classes.margin}
                >
                    <Grid item
                        className={classes.margin}
                        xs={12}
                    >
                        <Typography variant="h6" className={classes.textAlign} gutterBottom><b>Terms and Conditions</b></Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our <Link to="/privacyPolicy">'Privacy Policy'</Link>, govern Investography’s relationship with you.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        The term “Investography.” (or “us” / “we”) refers to the owner of the website. The term “you” refers to the user or viewer of our website.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="h6" className={classes.textAlign} gutterBottom><b>THE USE OF THIS WEBSITE IS SUBJECT TO THE FOLLOWING TERMS:</b></Typography>
                        <Typography variant="body2">
                        You understand and accept that Investography maintains the website – <a target="_blank" href="http://www.investography.in">http://www.investography.in</a> , or related websites (referred to as “site” or “website” on this page) to provide visitors with information about Investography, its services and products, and to enable visitors to avail its services. You also accept that visitors to the site are required to read the below terms, and the use of the site constitutes your acceptance and agreement to be bound by such terms. You will also be bound by changes therein to the terms from time-to-time as communicated and made available on the website.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You are aware and accept that all information, content, materials, products (including, but not limited to text, content, photographs, graphics, video and audio content) on the website is protected by copyright in favour of Investography under the applicable copyright laws and the general intellectual property law.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You understand and accept that all information submitted by you on the site shall be deemed the property of Investography, and Investography shall be free to use any ideas, concepts, know-how or techniques provided by you in any manner whatsoever. On initiating a contact through the site, you agree to being contacted by Investography, or any other entities with whom Investography has entered into an arrangement.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You agree that you shall not copy, reproduce, sell, redistribute, publish, enter into a database, display, perform, modify, transmit, license, create derivatives from, transfer, or in any way exploit, any part of any information, content, materials, services available on or through the site, except that which you may download for your own personal, non-commercial use.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You agree that you will not use Investography’s website for any purpose that is unlawful, or prohibited by these terms. You also agree you will not use the site in any manner that could damage, disable or impair the website, or interfere with any other party’s use, or enjoyment of the website.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You acknowledge that the software and hardware underlying the site, as well as other Internet-related software required for accessing the website, are the legal property of the respective vendors. The permission given by Investography to access the website will not convey any proprietary or ownership rights in the above software / hardware. You agree that you shall not attempt to modify, translate, disassemble, decompile, or reverse engineer the software / hardware underlying the website, or create any derivative product based on the software / hardware.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You understand and accept that not all products and services offered on this website are available in all geographic areas, and you may not be eligible for all the products or services offered by Investography on the site. Investography reserves the right to determine the availability and eligibility for any product or service.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You understand and accept that Investography is not responsible for the availability of content, or other services on third-party sites linked from the website. You are aware that the access of hyperlinks to other internet sites are at your own risk, and the content, accuracy, opinions expressed, and other links provided by these sites are not verified, monitored or endorsed by Investography in any way. Investography does not make any warranties, and expressly disclaims all warranties, express or implied, including without limitation, those of merchantability and fitness for a particular purpose, title or non-infringement with respect to any information or services or products that are available or advertised or sold through these third-party websites.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        Investography shall not be liable if any transaction does not fructify, or may not be completed, or for any failure on the part of Investography to perform any of its obligations under these terms and conditions, or those applicable specifically to its services/facilities if performance is prevented, hindered or delayed by a Force Majeure event (defined below). In such a case, its obligations shall be suspended for so long as the Force Majeure event continues.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        “Force Majeure Event” means any event due to any cause beyond the reasonable control of Investography, including and without limitations the unavailability of any communication systems, breach, or virus in the processes or payment or delivery mechanism, sabotage, fire, flood, explosion, acts of God, civil commotions, strikes or industrial action of any kind, riots, insurrection, war, acts of the government, computer hacking, unauthorised access to computer data and storage devices, computer crashes, malfunctioning in the computer terminal, or the systems getting affected by any malicious, destructive or corrupting code or programme, mechanical or technical errors/failures or power shutdown, faults or failures in telecommunication, etc.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You understand and accept that Investography has the absolute discretion to amend or supplement any of the terms at any time, and will give a prior notice of 30 days for such changes. The changed terms and conditions shall be communicated to you on the website, and by other acceptable modes of communication. By using Investography’s services, you shall be deemed to have accepted the changed terms and conditions.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You accept that the Courts in Bengaluru alone shall have exclusive jurisdiction as regards to any claims or matters arising out of dealings with Investography. All disputes will be governed by the laws of India.
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                        You understand and agree that these terms are in addition to, and not in derogation of, the applicable terms and conditions relating to your usage of any Investography services that you may be currently availing, or may avail in the future.
                            <br />
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

Disclaimer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Disclaimer);
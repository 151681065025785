import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import financialEducation from '../../images/financialEducation.PNG';
import financialEducation1 from '../../images/financialEducation1.png';
import Hero from '../herohome/hero/Hero';
import ContactSmall from '../contact/contactSmall/ContactSmall';


const styles = theme => ({
    container: {
        width: '80%',
        padding: '1em 0',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    heading: {
        textAlign: 'center'

    },
    sectionPadding: {
        padding: '1em',
        [theme.breakpoints.down('sm')]: {
            padding: '0em 1em',
        }
    },
    margin: {
        margin: '1em 0',
    },
    fontSize: {
        fontSize: '18px',
    },
    imageMargin: {
        margin: '0.5em 0 2.2em 0',
        [theme.breakpoints.down('xs')]: {
            margin: '0.5em 0 2.7em 0',
        }
    },
    image: {
        width: '80%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '130%',
        },
    },
    quote: {
        fontSize: '20px',
        textAlign: 'center',
        padding: '1em',
        borderTop: '1px solid #29B5C3',
        borderBottom: '1px solid #29B5C3',
    },
    quoteLink: {
        textDecoration: 'none',
        color: '#29B5C3',
    },
    quoteMargin: {
        margin: '3em auto',
    },


    backgroundGray: {
        backgroundColor: 'rgb(160,160,160)',
    },
    bannerText: {
        color: '#fff',
        letterSpacing: '0.1em',
        wordSpacing: '0.2em',
        textAlign: 'center',
        margin: '0.5em 1.5em',
        [theme.breakpoints.down('sm')]: {
            letterSpacing: '0.1em',
            wordSpacing: '0.2em',
            lineHeight: '1.4em',
        },
    },
    bannerTextStrong: {
        color: 'rgb(0,100,255)',
    },
});

class FinancialEducation extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Hero image="education.png" />
                <Grid container
                    justify="center"
                    className={classes.container}
                >
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        {/* <Grid item
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                        >
                            <Typography variant="h4" className={classes.heading} gutterBottom>Financial Education</Typography>
                        </Grid> */}

                        <Grid item
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                        >
                            <Typography variant="body2" className={classes.fontSize} gutterBottom>
                                We believe that financial literacy is as fundamental as reading and writing. By starting early, the foundation is laid for the understanding of basic financial concepts and with money skills that empower the students for a lifetime of money success.
                            </Typography>
                            <Typography variant="body2" className={classes.fontSize} gutterBottom>
                                While understanding facts about money is great, we can only lay claim to financial literacy when doing the right things with money that lead to the right financial results is a consistent behaviour trait.
                            </Typography>
                            <Typography variant="body2" className={classes.fontSize} gutterBottom>
                                What better place to communicate these life lessons around money than in the classroom?
                            </Typography>
                        </Grid>

                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.sectionPadding, classes.imageMargin)}
                            xs={12}
                        >
                            <img src={financialEducation1} alt="Financial Education" className={classes.image} />
                        </Grid>

                        <Grid item
                            xs={12}
                            className={classNames(classes.sectionPadding, classes.margin)}
                        >
                            <Typography variant="body2" className={classes.fontSize} gutterBottom>
                                Credit card bills, loans, savings or investments may not be top of mind for students, but the decisions they make today will have a long-term impact on their life. Understanding the concepts behind prudent money management is crucial to successful adulting and together we can equip them with the essential core skills and habits to take charge of their financial future.
                        </Typography>
                        </Grid>

                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                        >
                            <img src={financialEducation} alt="Financial Education" className={classes.image} />
                        </Grid>

                        <Grid item
                            container
                            justify="center"
                            className={classes.quoteMargin}
                            xs={12}
                        >
                            <Grid item
                                xs={11}
                                sm={8}
                            >
                                <Typography variant="body2" align="center" className={classNames(classes.quote, classes.fontSize)}>
                                    <b><i>"Academic qualifications are important and so is financial education. They're both important and schools are forgetting one of them and that is financial education. That's why there are so many intelligent broke people in this world." - Robert T Kiyosaki</i></b>
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                
                <ContactSmall title="Connect with us today and make a difference to financial education" page="Financial Education" />
            </div >
        );
    }
};


FinancialEducation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FinancialEducation);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import logo from '../../../../images/logo.png';
import axios from '../../../../axios/axios';
import Notifier, { openSnackbar } from '../../../utility/Notifier';
import { firebase } from '../../../../firebase/firebase';

import Result1D from '../../../../images/report_1-10_desktop.png';
import Result2D from '../../../../images/report_11-15_desktop.png';
import Result3D from '../../../../images/report_16-20_desktop.png';

import Result1M from '../../../../images/report_1-10_mobile.png';
import Result2M from '../../../../images/report_11-15_mobile.png';
import Result3M from '../../../../images/report_16-20_mobile.png';


const styles = theme => ({
    logo: {
        width: '30%',
        height: '30%',
        margin: '2em 0',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            height: '35%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '50%',
            height: '20%',
        }
    },
    heading: {
        color: '#444',
        textAlign: 'center',
        lineHeight: 1.2,
        textTransform: 'uppercase',
        textDecoration: 'underline',
        letterSpacing: '0.07em',
        wordSpacing: '0.3em',
        margin: '1em 0',
        [theme.breakpoints.down('xs')]: {
            textDecoration: 'none',
        },
    },
    name: {
        fontSize: '28px',
        color: '#666',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        margin: '1em 0 0.5em 0',
    },
    date: {
        fontSize: '20px',
        color: '#666',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        margin: '0.5em 0 1em 0',
    },
    image: {
        width: '80%',
        height: '90%',
        margin: '1em',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: '90%',
        }
    },
    borderTop: {
        borderTop: '10px solid #29B5C3',
    },
    borderRight: {
        borderRight: '10px solid #29B5C3',
    },
    borderBottom: {
        borderBottom: '10px solid #29B5C3',
    },
    borderLeft: {
        borderLeft: '10px solid #29B5C3',
    },
});

class FinancialHealthResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            finalScore: 0,
            createdAt: null,
            name: 'Zeeshan Nagori',
            resultImage: '',
        }
    }

    componentDidMount() {
        const user = firebase.auth().currentUser;
        if (user) {
            axios.post("user/getFinancialHealthCheckup", { uid: user.uid }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        console.log(result.data.result.finalScore);
                        this.setState({
                            finalScore: result.data.result.finalScore,
                            name: user.displayName,
                            createdAt: result.data.result.createdAt,
                        }, () => {
                            this.showResult(this.state.finalScore);
                            console.log(this.state);
                        });
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong" });
                }
            }).catch(exception => {
                openSnackbar({ message: exception });
            });
        }
    }

    showResult = (result) => {
        if (result >= 0 && result <= 10) {
            //this.saveData(this.totalScore());
            if (window.innerWidth <= 600) {
                this.setState({
                    resultImage: Result1M
                });
            } else {
                this.setState({
                    resultImage: Result1D
                });
            }
        } else if (result >= 11 && result <= 15) {
            //this.saveData(this.totalScore());
            if (window.innerWidth <= 600) {
                this.setState({
                    resultImage: Result2M
                });
            } else {
                this.setState({
                    resultImage: Result2D
                });
            }
        } else {
            //this.saveData(this.totalScore());
            if (window.innerWidth <= 600) {
                this.setState({
                    resultImage: Result3M
                });
            } else {
                this.setState({
                    resultImage: Result3D
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        let imageContent = this.state.resultImage;
        return (
            <div>
                <Notifier />
                <Grid container
                    justify="center"
                >
                    {/*  Logo */}
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.borderTop, classes.borderRight, classes.borderLeft)}
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <img src={logo} alt="Investography Logo" className={classes.logo} />
                        </Grid>
                    </Grid>
                    {/*  Heading */}
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.borderRight, classes.borderLeft)}
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <Typography component="h2" variant="h3" className={classes.heading}>FINANCIAL HEALTH</Typography>
                        </Grid>
                    </Grid>
                    {/* Name */}
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.borderRight, classes.borderLeft)}
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <Typography variant="body2" className={classes.name}>{`Name: ${this.state.name}`}</Typography>
                        </Grid>
                    </Grid>
                    {/* Date */}
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.borderRight, classes.borderLeft)}
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <Typography variant="body2" className={classes.date}>{`Date: ${this.state.createdAt}`}</Typography>
                        </Grid>
                    </Grid>
                    {/* Result Image */}
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.borderRight, classes.borderBottom, classes.borderLeft)}
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <img src={imageContent} alt="Result" className={classes.image} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

FinancialHealthResult.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FinancialHealthResult);
import React, { Fragment, Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from '../../axios/axios';
import Snackbar, { openSnackbar } from '../utility/Notifier';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    root: {

    },
    container: {
        width: '90%',
        margin: '10px auto',
        textAlign: 'center'
    },
    line1: {
        margin: '10px 0',
        fontSize: '60px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '35px'
        }
    },
    button: {
        padding: '0 15px',
        margin: '0 20px',
        height: '30px',
        color: '#FFFFFF',
        border: '1px #29B5C3',
        borderRadius: '2px',
        fontSize: '14px',
        backgroundColor: '#29B5C3',
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#ff9933'
        }
    },
    header: {
        backgroundColor: '#29B5C3',
        borderRadius: '5px',
        color: '#FFFFFF'
    },
    subHeader: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    heading: {
        margin: '5px 0',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px'
        }
    },
    subHeading: {
        margin: '5px 0',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px'
        }
    },
    dialogContent: {
        margin: '5px',
        textAlign: 'center'
    },
    deleteBT: {
        margin: '10px 0',
        color: '#FFFFFF',
        backgroundColor: 'red'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    sectionList: {
        margin: '0 20px',
        height: '30px',
        borderRadius: '2px',
    }
});

class ManageBlog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            blog: null,
            blogs: [],
            hasMore: true,
            limit: 9,
            token: sessionStorage.getItem('jwtToken'),

            sections: [],
            sectionId: "All"
        }
    }

    componentDidMount() {
        this.fetchBlogs();
        this.fetchSections();
    }

    onSectionChange = (e) => {
        this.setState({
            sectionId: e.target.value,
            blogs: []
        }, () => {
            this.fetchBlogs();
        });
    }

    fetchSections = () => {
        axios.get('blog/fetchSection', { headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState({
                            sections: result.data.sections
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
    }

    fetchBlogs = () => {
        if (this.state.sectionId === "All") {
            axios.post('blog', {
                offset: this.state.blogs.length,
                limit: this.state.limit,
            }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState(prevState => ({
                            blogs: [...prevState.blogs, ...result.data.blogs]
                        }), () => {
                            this.setState({
                                loading: false
                            });
                            if (result.data.blogs.length === 0 || this.state.blogs.length < this.state.limit) {
                                this.setState({
                                    hasMore: false
                                });
                            }
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
        } else if (this.state.sectionId === "featured") {
            axios.post('blog/featured', {
                offset: this.state.blogs.length,
                limit: this.state.limit,
            }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState(prevState => ({
                            blogs: [...prevState.blogs, ...result.data.blogs]
                        }), () => {
                            this.setState({
                                loading: false
                            });
                            if (result.data.blogs.length === 0 || this.state.blogs.length < this.state.limit) {
                                this.setState({
                                    hasMore: false
                                });
                            }
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
        } else {
            axios.post('blog/bySection', {
                section: this.state.sectionId,
                offset: this.state.blogs.length,
                limit: this.state.limit,
            }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState(prevState => ({
                            blogs: [...prevState.blogs, ...result.data.blogs]
                        }), () => {
                            this.setState({
                                loading: false
                            });
                            if (result.data.blogs.length === 0 || this.state.blogs.length < this.state.limit) {
                                this.setState({
                                    hasMore: false
                                });
                            }
                        });
                    } else {
                        openSnackbar({ message: result.data.msg, variant: "error" });
                    }
                } else {
                    openSnackbar({ message: "Something went wrong", variant: "error" });
                }
            }).catch(error => {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            });
        }
    }

    deleteBlog = (id) => {
        axios.delete(`blog/?id=${id}`, {
            headers: {
                authorization: `Bearer ${this.props.token}`
            }
        }).then(result => {
            if (result.status === 200) {
                if (result.data.success) {
                    openSnackbar({ message: result.data.msg, variant: "success" });
                    const newList = this.state.blogs.filter(blog => {
                        return blog.id !== id;
                    });

                    this.setState({
                        blogs: newList,
                        open: false
                    });
                } else {
                    openSnackbar({ message: result.data.msg, variant: "error" });
                }
            } else {
                openSnackbar({ message: "Something went wrong", variant: "error" });
            }
        }).catch(error => {
            openSnackbar({ message: "Something went wrong", variant: "error" });
        });
    }

    makeFeatured = (e, id) => {
        axios.post('blog/makeFeatured', {
            blog: id,
            featured: e.target.checked
        }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
            if (result.status === 200) {
                if (result.data.success) {
                    this.setState({
                        blogs: []
                    }, () => {
                        this.fetchBlogs();
                    });
                    openSnackbar({ message: result.data.msg, variant: "success" });
                } else {
                    openSnackbar({ message: result.data.msg, variant: "warning" });
                }
            } else {
                openSnackbar({ message: result.data.msg, variant: "warning" });
            }
        }).catch(error => {
            openSnackbar({ message: error.message, variant: "error" });
        });
    }

    handleClickOpen = (blog) => {
        this.setState({
            open: true,
            blog: blog
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Snackbar />
                <div className={classes.container}>
                    <Typography variant="h1" className={classes.line1}>Manage Blog</Typography>
                    <br />
                    <button variant="outlined" color="secondary" className={classes.button}
                        onClick={() => {
                            this.props.history.push("/dashboard/blogPost");
                        }}>New Blog</button>
                    <select className={classes.sectionList} onChange={this.onSectionChange}>
                        <option value="All">All</option>
                        <option value="featured">Featured Blogs</option>
                        {
                            this.state.sections.map(section => {
                                return (
                                    <option key={section.id} value={section.id}>{section.name}</option>
                                )
                            })
                        }
                    </select>
                    <br /><br />
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell>Title</CustomTableCell>
                                    <CustomTableCell>Section</CustomTableCell>
                                    <CustomTableCell>Author</CustomTableCell>
                                    <CustomTableCell>Actions</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.blogs.map((blog) => {
                                    return <TableRow className={classes.row} key={blog.id}>
                                        <CustomTableCell style={{ cursor: 'pointer' }} onClick={() => {
                                            const url = `/dashboard/blogPost?title=${blog.title}&blog=${blog.id}`
                                            this.props.history.push(url);
                                        }}>{blog.title}</CustomTableCell>
                                        <CustomTableCell>{blog.sectionName}</CustomTableCell>
                                        <CustomTableCell style={{ cursor: 'pointer' }}>{blog.author.name}</CustomTableCell>
                                        <CustomTableCell>
                                            <div>
                                                {/* <button style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    textDecoration: 'underline',
                                                    textTransform: 'uppercase',
                                                    color: '#29B5C3',
                                                    cursor: 'pointer',
                                                    marginRight: '5px'
                                                }}
                                                    onClick={() => {
                                                        this.makeFeatured(blog.id, blog.featured ? false : true);
                                                    }}>{blog.featured ? 'Remove Featured' : 'Make Featured'}</button> */}
                                                <Switch
                                                    checked={blog.featured}
                                                    onChange={(e) => {
                                                        this.makeFeatured(e, blog.id);
                                                    }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                <button style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    textDecoration: 'underline',
                                                    textTransform: 'uppercase',
                                                    color: 'red',
                                                    cursor: 'pointer',
                                                    marginLeft: '5px'
                                                }}
                                                    onClick={() => {
                                                        this.setState({ blog: blog, open: true });
                                                    }}>Delete</button>
                                            </div>
                                        </CustomTableCell>
                                    </TableRow>
                                })}
                                {this.state.blog !== null ?
                                    <Dialog
                                        open={this.state.open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={this.handleClose}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle id="scroll-dialog-title">Blog Details</DialogTitle>
                                        <DialogContent>
                                            <Typography variant="subheading">{this.state.blog.title}</Typography>
                                            <div className="modal-content">
                                                <strong><p>Are you sure?</p></strong>

                                                <button className="modalFetchEventBT addButton" onClick={() => {
                                                    axios.delete('blog', {
                                                        params: {
                                                            id: this.state.blog.id
                                                        }, headers: { authorization: `Bearer ${this.state.token}` }
                                                    })
                                                        .then(result => {
                                                            if (result.status === 200) {
                                                                if (result.data.success) {
                                                                    this.setState({
                                                                        open: false,
                                                                        blogs: []
                                                                    }, () => {
                                                                        this.fetchBlogs();
                                                                    });
                                                                    openSnackbar({ message: result.data.msg });
                                                                } else {
                                                                    openSnackbar({ message: result.data.msg });
                                                                }
                                                            }
                                                        }).catch(exception => {
                                                            openSnackbar({ message: exception });
                                                        });
                                                }}>Delete</button>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} color="primary">
                                                Cancel</Button>
                                        </DialogActions>
                                    </Dialog>
                                    : null}
                            </TableBody>
                        </Table>
                    </Paper>
                    {this.state.loading ? (<CircularProgress className={classes.progress} color="secondary" />) : null}
                    <br />
                    {this.state.hasMore ? (<Button variant="outlined" color="secondary" className={classes.loadMore}
                        onClick={() => {
                            this.fetchBlogs();
                        }}>Load More</Button>) : null}
                </div>
            </Fragment>
        );
    }
}

ManageBlog.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ManageBlog);
import React, { Fragment } from 'react';
import EventsList from '../events/Events';
import Tagline from '../taglineexcel/TaglineExcel';
import Subscribe from '../subscribe/Subscribe';
import Gallery from '../gallery/Gallery';
import Hero from '../herohome/hero/Hero';
import Grid from '@material-ui/core/Grid'


const Events = () => (
    <Fragment>
        <Hero
            image="event_hero.png"
        />
        <Grid container
            justify="center"
        >
            <Grid item
                container
                justify="center"
                xs={12}
            >
                <Grid item
                    xs={12}
                >
                    <EventsList />
                </Grid>
            </Grid>
            <Grid item
                container
                justify="center"
                xs={12}
            >
                <Grid item
                    xs={12}
                    lg={10}
                >
                    <Gallery />
                </Grid>
            </Grid>
        </Grid>
        <Tagline />
        <Subscribe />
    </Fragment>
);

export default Events;
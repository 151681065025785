import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

const style = theme => ({
    cover: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    image: {
        marginRight: theme.spacing.unit,
        padding: theme.spacing.unit,
    },
    content: {
        padding: theme.spacing.unit,
    },
    heading: {
        marginBottom: '10px',
        fontWeight: 400,
        letterSpacing: '2px',
        fontSize: '16px',
    },
    headText: {
        fontSize: '14px'
    }
});

const Cards = props => {
    const { classes } = props;
    return (
        <Card
            raised
            className={classes.cover}
            elevation={5}
        >
            <div className={classes.image}>
                <img src={props.image} alt=" " width="51px" height="51px" />
            </div>
            <div className={classes.content}>
                <Typography className={classes.heading}>{props.heading}</Typography>
                <Typography className={classes.headText} variant="body2">{props.text}</Typography>
            </div>
        </Card>
    );
};

Cards.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Cards);
import React from 'react';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';

const styles = ({
    breadcrumbs: {
        fontWeight: 'bold',
        display: 'block',
    },
    link: {
        textDecoration: 'none',
        color: '#29B5C3',
        fontWeight: '400 !important',
        padding: 0,
        '&:first-child': {
            '&:after': {
                display: 'inline-block',
                content: '" > "',
                color: '#29B5C3',
                fontWeight: 'bold',
                padding: '0 20px',
            },
        },
    },
    p: {
        color: '#29B5C3',
    }
})

const BreadCrumbs = props => {
    const { classes } = props;
    let itemList = [];
    if (props.list) {
        itemList = props.list;
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <List className={classes.breadcrumbs}>
                    <ListItem className={classes.breadcrumbsLink}>
                        {itemList.map((item, index) => {
                            if (itemList.length !== index + 1) {
                                return <Link key={index} className={classes.link} to={item.to}>{item.name}</Link>
                            } else {
                                return <p className={classes.p} key={index}>{item.name}</p>
                            }
                        })}
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

BreadCrumbs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BreadCrumbs); 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import map from '../../images/map2.PNG';
import diy from '../../images/diy.JPG';
import Hero from '../herohome/hero/Hero';
import ContactSmall from '../contact/contactSmall/ContactSmall';

const styles = theme => ({
    container: {
        width: '80%',
        padding: '1em 0',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    heading: {
        textAlign: 'center',
    },
    sectionPadding: {
        padding: '1em 0',
    },
    sectionMargin: {
        margin: '1em',
    },
    margin: {
        margin: '1em 0',
    },
    fontSize: {
        fontSize: '18px',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    bullet: {
        display: 'inline-block',
        content: '',
        webkitBorderRadius: '0.375rem',
        borderRadius: '0.375rem',
        height: '0.5rem',
        width: '0.5rem',
        marginRight: '0.5rem',
        backgroundColor: '#29B5C3',
    },
    list: {
        paddingLeft: '1.5em',
        textIndent: '-1.25em',
    },
    backgroundOrange: {
        padding: '1em',
        margin: '1em',
        backgroundColor: 'orange',
        borderRadius: '15px',
    },
    backgroundGreen: {
        padding: '1em',
        margin: '1em',
        backgroundColor: 'rgb(61,212,61)',
        borderRadius: '15px',
    },
    backgroundBlue: {
        padding: '1em',
        margin: '1em',
        backgroundColor: '#29B5C3',
        borderRadius: '15px',
    },
    transform: {
        '&:hover': {
            transform: 'translateY(-15px)',
            transition: 'transform 0.5s linear',
        },
    },
    specialText: {
        color: '#fff',
        letterSpacing: '0.1em',
        wordSpacing: '0.2em',
        textAlign: 'center',
    },
    map: {
        width: '80%',
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            width: '90%',
            height: '90%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            height: '80%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            height: '90%',
        },
    },
    mapText: {
        width: '80%',
        [theme.breakpoints.down('lg')]: {
            width: '90%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    diy: {
        width: '70%',
        [theme.breakpoints.down('lg')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            width: '55%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '40%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '55%',
        },
    },
    backgroundGray: {
        backgroundColor: 'rgb(160,160,160)',
        margin: '1em 0',
    },
    bannerText: {
        color: '#fff',
        width: '80%',
        letterSpacing: '0.1em',
        wordSpacing: '0.2em',
        textAlign: 'center',
        margin: '0.5em auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            letterSpacing: '0.1em',
            wordSpacing: '0.2em',
            lineHeight: '1.4em',
        },
    },
    bannerTextStrong: {
        textDecoration: 'none',
        // color: 'rgb(0,100,255)',
        color: '#222',
    },
});

class DiyPlanner extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Hero image="diy.png" />
                <Grid container
                    justify="center"
                    className={classes.container}
                >
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        {/* <Grid item
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                        >
                            <Typography variant="h4" className={classes.heading} gutterBottom>DIY Planner</Typography>
                        </Grid> */}

                        <Grid item
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                            md={7}
                            lg={7}
                        >
                            <Typography variant="body2" className={classes.fontSize} gutterBottom>Always prided yourself on being a DIY kind of person?</Typography>
                            <Typography variant="body2" className={classes.fontSize} gutterBottom>
                                If that’s what you’re looking for from your investments, just let our DIY planners be your north star and chart the path to your dreams yourself. Our <Link to="/risk-profile" className={classes.link}><b><i>Risk Profiler </i></b></Link>and <Link to="/moneyMap" className={classes.link}><b><i>Money Maps </i></b></Link>can get you started.
                                <br />
                                <br />
                            </Typography>
                            <Grid item
                                container
                                justify="center"
                                xs={12}
                            >
                                <Grid item
                                    container
                                    justify="center"
                                    xs={12}
                                    sm={8}
                                    md={9}
                                >
                                    <Grid item
                                        className={classes.transform}
                                        xs={6}
                                        md={5}
                                        lg={5}
                                        xl={4}
                                    >
                                        <div className={classes.backgroundOrange}>
                                            <Typography variant="subtitle1" className={classes.specialText} gutterBottom>SAVE TAX</Typography>
                                        </div>
                                        <Typography variant="body2" align="center" className={classNames(classes.sectionMargin, classes.fontSize)} gutterBottom><b><i>And create long term wealth while doing so</i></b></Typography>
                                    </Grid>
                                    <Grid item
                                        className={classes.transform}
                                        xs={6}
                                        md={5}
                                        lg={5}
                                        xl={4}
                                    >
                                        <div className={classes.backgroundGreen}>
                                            <Typography variant="subtitle1" className={classes.specialText} gutterBottom>GET RICH</Typography>
                                        </div>
                                        <Typography variant="body2" align="center" className={classNames(classes.sectionMargin, classes.fontSize)} gutterBottom><b><i>Everyone’s dream goal Make it yours too</i></b></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item
                                    container
                                    justify="center"
                                    xs={12}
                                >
                                    <Grid item
                                        className={classes.transform}
                                        xs={8}
                                        sm={6}
                                        md={5}
                                        lg={5}
                                        xl={4}
                                    >
                                        <div className={classes.backgroundBlue}>
                                            <Typography variant="subtitle1" className={classes.specialText} gutterBottom>BULLETPROOF</Typography>
                                        </div>
                                        <Typography variant="body2" align="center" className={classNames(classes.sectionMargin, classes.fontSize)} gutterBottom><b><i>Something to dip into during an emergency</i></b><br /><br /></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className={classes.section}>
                                <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                    <span className={classes.bullet}></span>Browse through the entire library of mutual funds available or choose from a curated list of funds
                                        </Typography>
                                <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                    <span className={classes.bullet}></span>Invest/Withdraw at any time of the day or night
                                        </Typography>
                                <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                    <span className={classes.bullet}></span>Access your portfolio holdings online
                                        </Typography>
                                <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                    <span className={classes.bullet}></span>No separate fee - We earn from the product maker through the regular plan
                            </Typography>
                            </div>
                        </Grid>

                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                            md={5}
                            lg={5}
                        >
                            <Grid item
                                container
                                justify="center"
                                alignItems="center"
                                xs={12}
                                sm={10}
                                md={9}
                                lg={11}
                            >
                                <img src={map} alt="Money Maps" className={classes.map} />
                            </Grid>
                            <Grid item
                                container
                                justify="center"
                                alignItems="center"
                                xs={12}
                                sm={10}
                                md={9}
                                lg={11}
                            >
                                <Typography variant="body2" className={classNames(classes.mapText, classes.fontSize)} gutterBottom>
                                    <br />
                                    <br />
                                    Feeling a little unsure? If you’re a new investor and would like to learn more about investing, please visit our <Link to="/magazine" className={classes.link}><b><i>Magazine </i></b></Link>and <Link to="/media" className={classes.link}><b><i>Media </i></b></Link>sections where you’ll find articles and videos that can help take away some of that nervousness.
                            </Typography>
                            </Grid>
                        </Grid>

                        <Grid item
                            container
                            justify="center"
                            className={classNames(classes.sectionPadding, classes.margin)}
                            xs={12}
                            md={10}
                            lg={6}

                        >
                            <Grid item
                                container
                                justify="center"
                                alignItems="center"
                                xs={12}
                                sm={10}
                                md={9}
                                lg={8}
                            >
                                <a href="http://fundnow.investography.in/" target="_blank"><img src={diy} alt="DIY" className={classes.diy} /></a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item
                    container
                    xs={12}
                    className={classes.backgroundGray}
                >
                    <Typography variant="subtitle1" className={classes.bannerText} gutterBottom>
                        If you’d like a dedicated relationship manager, please visit our <Link to="/wealth" className={classes.bannerTextStrong}><b>Wealth Management </b></Link>section to learn more
                    </Typography>
                </Grid>
                <ContactSmall title="We’d love to know what drives your investment choices too" page="DIY Planner" />
            </div >
        );
    }
};


DiyPlanner.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DiyPlanner);
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Individual from '../../images/individuals.png';
import Companies from '../../images/companies.png';
import Educational from '../../images/educational.png';
import { Link } from 'react-router-dom';

const style = theme => ({
    container: {
        width: '80%',
        padding: '0 15px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    services: {
        backgroundColor: '#FFFFFF',
        padding: '4rem 0',
    },
    heading: {
        paddingBottom: '3rem',
        fontWeight: 400,
        fontSize: '2.5rem',
        letterSpacing: '1px',
    },
    box: {
        textAlign: 'center',
        transition: 'all .3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
        },
    },
    icon: {
        marginBottom: '.5rem',
    },
    content: {
        padding: '1rem',
        [theme.breakpoints.up('xl')]: {
            padding: '1.5rem',
        },
    },
    boxHeading: {
        fontWeight: 400,
        letterSpacing: '1px',
        fontSize: '16px',
        marginBottom: '10px',
    },
    button: {
        margin: '20px 0',
        fontSize: '14px',
        letterSpacing: '1px',
        textDecoration: 'none',
        color: '#29B5C3',
        textTransform: 'uppercase',
        lineHeight: '4rem',
    },
    boxText: {
        textAlign: 'left',
        fontSize: '14px',
    },
})

const Services = props => {
    const { classes } = props;
    return (
        <div className={classes.services}>
            <Grid item container justify="center" xs={12}>
                <Typography className={classes.heading} variant="h4">WHAT WE DO</Typography>
            </Grid>
            <Grid container justify="center" spacing={32} className={classes.container}>
                <Grid item xs={12} lg={4} className={classes.box}>
                    <Paper className={classes.content} elevation={15}>
                        <img className={classes.icon} src={Individual} alt=" " />
                        <Typography className={classes.boxHeading} >D.I.Y</Typography>
                        <Typography className={classes.boxText} variant="body1" >Fancy doing it yourself? Our planners can help decide your risk level and investment amount. Then choose your funds and start.</Typography>
                        <Link className={classes.button} to="/diy">Read More</Link>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={4} className={classes.box}>
                    <Paper className={classes.content} elevation={15}>
                        <img className={classes.icon} src={Companies} alt=" " />
                        <Typography className={classes.boxHeading} >FINANCIAL WELLNESS</Typography>
                        <Typography className={classes.boxText} variant="body1">Our solutions can help employees manage money better and deal with financial worries. And you can be an employer of choice.</Typography>
                        <Link className={classes.button} to="/financial-wellness"> Read More</Link>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={4} className={classes.box}>
                    <Paper className={classes.content} elevation={15}>
                        <img className={classes.icon} src={Educational} alt=" " />
                        <Typography className={classes.boxHeading} >FINANCIAL EDUCATION</Typography>
                        <Typography className={classes.boxText} variant="body1">How often have you told your students about getting the basics right? It's time we applied the basics to 'all things money' too.</Typography>
                        <Link className={classes.button} to="/financialEducation">Read More</Link>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

Services.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Services);
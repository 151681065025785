import React, { Component } from 'react';
import axios from '../../axios/axios';
import './admin.css';
import AdminNav from './AdminNav';
import { Redirect } from 'react-router-dom';
import Notifier, { openSnackbar } from '../utility/Notifier';

function toTimeStampDate(stringDate) {
    var myDate = stringDate;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[2] + "/" + myDate[0];
    return newDate;
}

function toDateFrontend(date) {
    let newDate = date.split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
}

class UpdateEvent extends Component {
    state = {
        id: '',
        eventDate: '',
        eventTitle: '',
        eventDescription: '',
        venue: '',
        eventCity: '',
        startTime: '',
        duration: '',
        keyNoteSpeaker: '',
        token: sessionStorage.getItem('jwtToken')
    }

    componentWillMount() {
        if (sessionStorage && sessionStorage.getItem('event')) {
            const data = JSON.parse(sessionStorage.getItem('event'));
            data.eventDate = toTimeStampDate(toDateFrontend(data.eventDate));
            this.setState(data);
            console.log(data);
        }
    }

    /* goBack() {
        localStorage.removeItem('event');
        this.props.history.push("/dashboard/events");
        return <Redirect to={{
            pathname: "/dashboard/events"
        }} />
    } */

    handleChangeTitle = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            eventTitle: val
        }));
    }

    handleChangeCity = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            eventCity: val
        }));
    }

    handleChangeVenue = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            venue: val
        }));
    }

    handleChangeDate = (e) => {
        let val = e.target.value;
        this.setState({
            eventDate: toTimeStampDate(val)
        });
    }

    handleChangeDuration = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            duration: val
        }));
    }

    handleChangeStartTime = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            startTime: val
        }));
    }

    handleChangeSpeaker = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            keyNoteSpeaker: val
        }));
    }

    handleChangeDescription = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            eventDescription: val
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        axios.post('admin/updateEvent', this.state, { headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        openSnackbar({ message: result.data.msg });
                        this.props.history.goBack();
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: 'Something went wrong' });
            });
    }

    render() {
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }} />
                </div>
                <div id="saveForm">
                    <div className="container">
                        <h4 style={{ textAlign: 'center' }}>Update Event</h4>
                        <br />
                        <Notifier />
                        <form onSubmit={this.handleSubmit}>
                            <div className="form">
                                <div className="gridFull labelInput">
                                    <label>Event Title</label>
                                    <input type="text" name="title" maxLength="100" value={this.state.eventTitle} onChange={this.handleChangeTitle} />
                                </div>
                                <div className="labelInput">
                                    <label>Event City</label>
                                    <input type="text" name="eventCity" maxLength="30" value={this.state.eventCity} onChange={this.handleChangeCity} />
                                </div>
                                <div className="labelInput">
                                    <label>Event Venue</label>
                                    <input type="text" name="venue" maxLength="50" value={this.state.venue} onChange={this.handleChangeVenue} />
                                </div>
                                <div className="labelInput">
                                    <label>Event Date</label>
                                    <input type="date" name="eventDate" onChange={this.handleChangeDate} />
                                </div>
                                <div className="labelInput">
                                    <label>Event Duration</label>
                                    <input type="text" name="duration" value={this.state.duration} onChange={this.handleChangeDuration} />
                                </div>
                                <div className="labelInput">
                                    <label>Start Time</label>
                                    <input type="time" name="startTime" maxLength="10" value={this.state.startTime} onChange={this.handleChangeStartTime} />
                                </div>
                                <div className="labelInput">
                                    <label>KeyNoteSpeaker</label>
                                    <input type="text" name="keyNoteSpeaker" maxLength="30" value={this.state.keyNoteSpeaker} onChange={this.handleChangeSpeaker} />
                                </div>
                                <div className="gridFull labelInput">
                                    <label>Description</label>
                                    <textarea name="description" rows="5" maxLength="250" value={this.state.eventDescription} onChange={this.handleChangeDescription}></textarea>
                                </div>
                                <br></br>
                                <button>Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateEvent;
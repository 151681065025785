import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, FormLabel, Grid, TextField, Radio, RadioGroup, FormControlLabel, Tooltip, Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import Slider from '@material-ui/lab/Slider';
import Info from '@material-ui/icons/Info';
import Create from '@material-ui/icons/Create';
import Notifier, { openSnackbar } from '../../../utility/Notifier';
import './RetirementPlanner.css';
import conservativeImage from '../../../../images/conservative.png';
import moderateImage from '../../../../images/moderate.png';
import aggressiveImage from '../../../../images/aggressive.png';
import pointer from '../../../../images/pointer.png';
import Breadcrumbs from '../../../breadcrumbs/Breadcrumbs';
import axios from '../../../../axios/axios';
import { firebase } from '../../../../firebase/firebase';


const styles = theme => ({
    heading: {
        color: '#444',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '0.07em',
        wordSpacing: '0.3em',
    },
    subheading: {
        fontSize: '16px',
        color: '#666',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
    },
    nameTextField: {
        width: '270px',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    textField: {
        width: '60%',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    root: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 400,
        }, [theme.breakpoints.down('xs')]: {
            width: 250,
        },
    },
    slider: {
        flex: '15',
        padding: '20px 0px',
        marginLeft: '0.8em',
        marginRight: '0.8em',
        touchAction: 'none',
    },
    formLabel: {
        margin: '3em 1em 0em 0.8em',
    },
    submitButton: {
        color: '#fff',
        background: '#29B5C3',
        textAlign: 'center',
        letterSpacing: '0.2em',
        margin: '2em 1em 3em 0em',
        [theme.breakpoints.down('sm')]: {
            margin: '3em 1em 3em 1em',
        },
        '&:hover': {
            background: '#145a61',
        }
    },
    button: {
        color: '#fff',
        background: '#29B5C3',
        textAlign: 'center',
        letterSpacing: '0.2em',
        margin: '4em 1em 3em 1em',
        '&:hover': {
            background: '#145a61',
        }
    },
    infoIcon: {
        color: '#aaa',
        width: '4%',
        margin: '1.2em 0em 0.8em 0.8em',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '6%',
            padding: '0.5em',
            margin: '0.7em 0.3em 0.8em 0.3em',
        },
    },
    customTooltip: {
        fontSize: '12px',
    },
    radioMargin: {
        margin: '0 1.5em 0 0',
        [theme.breakpoints.down('sm')]: {
            margin: '1.5em 0 0 1.5em',
        }
    },
    afterExpansionColor: {
        backgroundColor: '#29B5C3',
        borderBottom: '1px solid #fff',
    },
    beforeExpansionColor: {
        backgroundColor: '#ddd',
        borderBottom: '1px solid #fff',
    },
    afterSectionHeading: {
        color: '#fff',
        textAlign: 'center',
        fontWeight: 700,
        letterSpacing: '1px',
        fontFamily: 'Lato',
    },
    beforeSectionHeading: {
        color: '#798791',
        textAlign: 'center',
        fontWeight: 700,
        letterSpacing: '1px',
        fontFamily: 'Lato',
    },
    container: {
        margin: '0 auto',
    },
    okLink: {
        textDecoration: 'none',
        color: '#fff',
        background: '#29B5C3',
        textAlign: 'center',
        letterSpacing: '0.2em',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
        padding: '8px 16px',
        fontSize: '1.1666666666666667rem',
        minWidth: '64px',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: 500,
        lineHeight: 1.5,
        borderRadius: '4px',
        textTransform: 'uppercase',
        margin: '4em 1em 3em 1em',
        '&:hover': {
            background: '#145a61',
        }
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
    },
    whiteText: {
        color: '#fff',
    },
    banner: {
        backgroundColor: '#29B5C3',
        marginTop: '2em',
    },
});

class RetirementPlanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            age: 18,    /*  Database  */
            retirementAge: 60,    /*  Database  */
            gender: "male",    /*  Database  */
            lifeExpectancy: 85,
            existingInvestment: 0,    /*  Database  */
            retiralBenefits: 0,    /*  Database  */
            presentMonthlyExpenses: 0,    /*  Database  */
            rentalIncome: 0,    /*  Database  */
            pensionIncome: 0,    /*  Database  */
            inflation: 0.06,
            conservativeRate: 0.08,
            moderateRate: 0.10,
            aggressiveRate: 0.12,
            expectedIncomeGrowth: 0.06,
            expectedIncomeDiscount: 0.04,
            rentalIncomeGrowth: 0.05,
            rentalIncomeDiscount: 0.04,
            pensionIncomeDiscount: 0.04,
            newMonthlyInvestment: 0,    /*  Database  */
            tryNew: false,
            panel1: true,
            panel2: false,
            panel3: false,
            editPanel1: false,
            editPanel2: false,

            shortageAmount: 0,    /* if negative then result will not be shown */
        };
    }

    /* numberFormat function for displaying outputs in Indian currency format  */
    numberFormat = (value) => {
        let x = value;
        x = x.toString();
        let afterPoint = '';
        if (x.indexOf('.') > 0)
            afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        let lastThree = x.substring(x.length - 3);
        let otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers !== '')
            lastThree = ',' + lastThree;
        let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
        return res;
    }

    onNameChange = (e) => {
        e.preventDefault();
        const userName = e.target.value;
        this.setState(() => ({ userName }));
    }

    onGender = (e) => {
        e.preventDefault();
        const gender = e.target.value;
        this.setState(() => ({ gender }));
        switch (gender) {
            case "male":
                this.setState(() => ({ lifeExpectancy: 85 }));
                break;
            case "female":
                this.setState(() => ({ lifeExpectancy: 90 }));
                break;
            default:
                this.setState(() => ({ lifeExpectancy: 85 }));
                break;
        }
    };

    onAgeChange = (event, value) => {
        event.preventDefault();
        const age = parseInt(value);
        this.setState({ age });
    };

    onRetirementAge = (event, value) => {
        event.preventDefault();
        const retirementAge = parseInt(value);
        this.setState(() => ({ retirementAge }));
    };

    existingInvestment = (e) => {
        e.preventDefault();
        const existingInvestment = parseInt(e.target.value);
        this.setState(() => ({ existingInvestment }));
    };

    rentalIncome = (e) => {
        e.preventDefault();
        const rentalIncome = parseInt(e.target.value);
        this.setState(() => ({ rentalIncome }));
    };

    retiralBenefits = (e) => {
        e.preventDefault();
        const retiralBenefits = parseInt(e.target.value);
        this.setState(() => ({ retiralBenefits }));
    };

    pensionIncome = (e) => {
        e.preventDefault();
        const pensionIncome = parseInt(e.target.value);
        this.setState(() => ({ pensionIncome }));
    };

    presentMonthlyExpenses = (e) => {
        e.preventDefault();
        const presentMonthlyExpenses = parseInt(e.target.value);
        this.setState(() => ({ presentMonthlyExpenses }));
    };

    workingAge = () => {
        const workingAge = this.state.retirementAge - this.state.age;
        return workingAge;
    };

    monthlyRequirementFuture = () => {
        const monthlyRequirementFuture = this.state.presentMonthlyExpenses * Math.pow(
            1 + this.state.inflation,
            this.workingAge()
        );
        return monthlyRequirementFuture.toFixed(0);
    };

    futurePortfolio = () => {
        const futurePortfolio = this.state.existingInvestment * Math.pow(
            1 + this.state.conservativeRate,
            this.workingAge()
        );
        return futurePortfolio;
    };

    expectedCorpusRequired = () => {
        const expectedCorpusRequired = (this.monthlyRequirementFuture() * 12) /
            (this.state.expectedIncomeDiscount - this.state.expectedIncomeGrowth) * (1 - Math.pow(
                (1 + this.state.expectedIncomeGrowth) /
                (1 + this.state.expectedIncomeDiscount),
                this.state.lifeExpectancy - this.state.retirementAge + 1)
            );
        return expectedCorpusRequired.toFixed(0);
    };

    rentalIncomeCorpus = () => {
        const rentalIncomeCorpus = (this.state.rentalIncome * 12) / (this.state.rentalIncomeDiscount - this.state.rentalIncomeGrowth) * (1 - Math.pow(
            (1 + this.state.rentalIncomeGrowth) / (1 + this.state.rentalIncomeDiscount),
            this.state.lifeExpectancy - this.state.retirementAge + 1)
        );
        return rentalIncomeCorpus;
    };

    pensionIncomeCorpus = () => {
        const pensionIncomeCorpus = (this.state.pensionIncome * 12) * (1 - Math.pow(
            1 + this.state.pensionIncomeDiscount,
            this.state.retirementAge - this.state.lifeExpectancy + 1)) / this.state.pensionIncomeDiscount + (this.state.pensionIncome * 12);
        return pensionIncomeCorpus;
    };

    requiredCorpus = () => {
        const requiredCorpus = this.expectedCorpusRequired() - this.rentalIncomeCorpus() - this.pensionIncomeCorpus();
        return requiredCorpus;
    };

    shortageAmount = () => {
        const shortageAmount = this.requiredCorpus() - this.state.retiralBenefits - this.futurePortfolio();
        this.setState({ shortageAmount });
        return shortageAmount.toFixed(0);
    };

    conservativeInvestment = () => {
        let conservativeInvestment = (this.shortageAmount() * this.state.conservativeRate / 12) / (Math.pow(
            (1 + this.state.conservativeRate / 12),
            this.workingAge() * 12) - 1);
        conservativeInvestment = 500 * Math.ceil(
            (conservativeInvestment.toFixed(0) / 500)
        );
        return conservativeInvestment;
    };

    moderateInvestment = () => {
        let moderateInvestment = (this.shortageAmount() * this.state.moderateRate / 12) / (Math.pow(
            (1 + this.state.moderateRate / 12),
            this.workingAge() * 12) - 1);
        moderateInvestment = 500 * Math.ceil(
            (moderateInvestment.toFixed(0) / 500)
        );
        return moderateInvestment;
    };

    aggressiveInvestment = () => {
        let aggressiveInvestment = (this.shortageAmount() * this.state.aggressiveRate / 12) / (Math.pow(
            (1 + this.state.aggressiveRate / 12),
            this.workingAge() * 12) - 1);
        aggressiveInvestment = 500 * Math.ceil(
            (aggressiveInvestment.toFixed(0) / 500)
        );
        return aggressiveInvestment;
    };

    tryNew = () => {
        const tryNewOkButton = document.getElementById('tryNewOkButton');
        tryNewOkButton.classList.add('hidden');

        const tryNewParagraph = document.getElementById('tryNewParagraph');
        tryNewParagraph.classList.add('hidden');

        const monthlyInvestmentParagraph = document.getElementById('monthlyInvestmentParagraph');
        monthlyInvestmentParagraph.classList.remove('hidden');

        const newMonthlyInvestmentForm = document.getElementById('newMonthlyInvestmentForm');
        newMonthlyInvestmentForm.classList.remove('hidden');

        const riskProfileNote2 = document.getElementById('riskProfileNote2');
        riskProfileNote2.classList.add('hidden');

        this.setState(() => ({ tryNew: true }));
    };

    newMonthlyInvestment = (e) => {
        e.preventDefault();
        const newMonthlyInvestment = parseInt(e.target.value);
        this.setState(() => ({ newMonthlyInvestment }));
    };

    newConservativeCorpus = () => {
        const newConservativeCorpus = (this.state.newMonthlyInvestment * (Math.pow(
            (1 + this.state.conservativeRate / 12),
            (this.workingAge() * 12)) - 1) / (this.state.conservativeRate / 12)) + this.futurePortfolio() + this.state.retiralBenefits + this.rentalIncomeCorpus() + this.pensionIncomeCorpus();
        return newConservativeCorpus;
    };

    newModerateCorpus = () => {
        const newModerateCorpus = (this.state.newMonthlyInvestment * (Math.pow(
            (1 + this.state.moderateRate / 12),
            (this.workingAge() * 12)) - 1) / (this.state.moderateRate / 12)) + this.futurePortfolio() + this.state.retiralBenefits + this.rentalIncomeCorpus() + this.pensionIncomeCorpus();
        return newModerateCorpus;
    };

    newAggressiveCorpus = () => {
        const newAggressiveCorpus = (this.state.newMonthlyInvestment * (Math.pow(
            (1 + this.state.aggressiveRate / 12),
            (this.workingAge() * 12)) - 1) / (this.state.aggressiveRate / 12)) + this.futurePortfolio() + this.state.retiralBenefits + this.rentalIncomeCorpus() + this.pensionIncomeCorpus();
        return newAggressiveCorpus;
    };

    newConservativeIncome = () => {
        const newConservativeIncome = (this.newConservativeCorpus() * (this.state.expectedIncomeDiscount - this.state.expectedIncomeGrowth) / (1 - Math.pow(
            (1 + this.state.expectedIncomeGrowth) / (1 + this.state.expectedIncomeDiscount),
            (this.state.lifeExpectancy - this.state.retirementAge + 1))
        )) / 12;
        return newConservativeIncome;
    };

    newModerateIncome = () => {
        const newModerateIncome = (this.newModerateCorpus() * (this.state.expectedIncomeDiscount - this.state.expectedIncomeGrowth) / (1 - Math.pow(
            (1 + this.state.expectedIncomeGrowth) / (1 + this.state.expectedIncomeDiscount),
            (this.state.lifeExpectancy - this.state.retirementAge + 1))
        )) / 12;
        return newModerateIncome;
    };

    newAggressiveIncome = () => {
        const newAggressiveIncome = (this.newAggressiveCorpus() * (this.state.expectedIncomeDiscount - this.state.expectedIncomeGrowth) / (1 - Math.pow(
            (1 + this.state.expectedIncomeGrowth) / (1 + this.state.expectedIncomeDiscount),
            (this.state.lifeExpectancy - this.state.retirementAge + 1))
        )) / 12;
        return newAggressiveIncome;
    };

    presentValueConservativeIncome = () => {
        let presentValueConservativeIncome = this.newConservativeIncome() / Math.pow(
            (1 + this.state.inflation),
            this.workingAge());
        presentValueConservativeIncome = 500 * Math.ceil(
            (presentValueConservativeIncome.toFixed(0) / 500)
        );
        return presentValueConservativeIncome;
    };

    presentValueModerateIncome = () => {
        let presentValueModerateIncome = this.newModerateIncome() / Math.pow(
            (1 + this.state.inflation),
            this.workingAge());
        presentValueModerateIncome = 500 * Math.ceil(
            (presentValueModerateIncome.toFixed(0) / 500)
        );
        return presentValueModerateIncome;
    };

    presentValueAggressiveIncome = () => {
        let presentValueAggressiveIncome = this.newAggressiveIncome() / Math.pow(
            (1 + this.state.inflation),
            this.workingAge());
        presentValueAggressiveIncome = 500 * Math.ceil(
            (presentValueAggressiveIncome.toFixed(0) / 500)
        );
        return presentValueAggressiveIncome;
    };

    conservativeInvestmentOutput = () => {
        let output = document.getElementById('output');
        let tryNew = this.state.tryNew;
        switch (tryNew) {
            case false:
                output.innerHTML = "₹ " + this.numberFormat(this.conservativeInvestment()) + "*";
                break;
            case true:
                output.innerHTML = "₹ " + this.numberFormat(this.presentValueConservativeIncome()) + "*";
                break;
            default:
                output.innerHTML = "₹ " + this.numberFormat(this.conservativeInvestment()) + "*";
                break;

        }
        let pointer = document.getElementById('pointer');
        pointer.style.transform = "translateX(-30px) translateY(35px) rotate(-70deg)";
    };

    moderateInvestmentOutput = () => {
        let output = document.getElementById('output');
        let tryNew = this.state.tryNew;
        switch (tryNew) {
            case false:
                output.innerHTML = "₹ " + this.numberFormat(this.moderateInvestment()) + "*";
                break;
            case true:
                output.innerHTML = "₹ " + this.numberFormat(this.presentValueModerateIncome()) + "*";
                break;
            default:
                output.innerHTML = "₹ " + this.numberFormat(this.moderateInvestment()) + "*";
                break;
        }
        let pointer = document.getElementById('pointer');
        pointer.style.transform = "translateX(0px) translateY(15px) rotate(0deg)";
    };

    aggressiveInvestmentOutput = () => {
        let output = document.getElementById('output');
        let tryNew = this.state.tryNew;
        switch (tryNew) {
            case false:
                output.innerHTML = "₹ " + this.numberFormat(this.aggressiveInvestment()) + "*";
                break;
            case true:
                output.innerHTML = "₹ " + this.numberFormat(this.presentValueAggressiveIncome()) + "*";
                break;
            default:
                output.innerHTML = "₹ " + this.numberFormat(this.aggressiveInvestment()) + "*";
                break;
        }
        let pointer = document.getElementById('pointer');
        pointer.style.transform = "translateX(30px) translateY(35px) rotate(70deg)";
    };

    recalculate = () => {
        if (this.state.newMonthlyInvestment < 0) {
            openSnackbar({ message: "New Monthly Investment should be 0 or higher" })
        } else {
            const riskProfileNote2 = document.getElementById('riskProfileNote2');
            riskProfileNote2.classList.remove('hidden');

            const riskProfileNote1 = document.getElementById('riskProfileNote1');
            riskProfileNote1.classList.add('hidden');

            const tryNewParagraph = document.getElementById('tryNewParagraph');
            tryNewParagraph.classList.remove('hidden');

            const monthlyInvestmentParagraph = document.getElementById('monthlyInvestmentParagraph');
            monthlyInvestmentParagraph.classList.add('hidden');

            const newMonthlyInvestmentForm = document.getElementById('newMonthlyInvestmentForm');
            newMonthlyInvestmentForm.classList.add('hidden');

            const tryNewOkButton = document.getElementById('tryNewOkButton');
            tryNewOkButton.classList.remove('hidden');

            this.conservativeInvestmentOutput();    /* for initially showing presentValueConservativeIncome output */

        }
    };

    /*  tryNewState function if changes are made in initial form above submit button  */
    tryNewState = () => {
        this.setState(() => ({ tryNew: false }));

        const riskProfileNote2 = document.getElementById('riskProfileNote2');
        riskProfileNote2.classList.add('hidden');

        const riskProfileNote1 = document.getElementById('riskProfileNote1');
        riskProfileNote1.classList.remove('hidden');
    };

    summary = () => {
        /* const summary = document.getElementById('summary');
        summary.classList.remove('hidden'); */

        if (document.getElementById('presentMonthlyExpenses') !== null) {
            const presentMonthlyExpenses = document.getElementById('presentMonthlyExpenses');
            presentMonthlyExpenses.innerHTML = "₹" + this.numberFormat(this.state.presentMonthlyExpenses);
        }

        if (document.getElementById('monthlyRequirementFuture') !== null) {
            const monthlyRequirementFuture = document.getElementById('monthlyRequirementFuture');
            monthlyRequirementFuture.innerHTML = "₹" + this.numberFormat(this.monthlyRequirementFuture()) + "*";
        }

        if (document.getElementById('expectedCorpusRequired') !== null) {
            const expectedCorpusRequired = document.getElementById('expectedCorpusRequired');
            expectedCorpusRequired.innerHTML = "₹" + this.numberFormat(this.expectedCorpusRequired()) + "*";
        }

        if (document.getElementById('shortageAmount') !== null) {
            const shortageAmount = document.getElementById('shortageAmount');
            shortageAmount.innerHTML = "₹" + this.numberFormat(this.shortageAmount()) + "*";
        }
    };

    validationPanel1 = () => {
        if (this.state.age >= this.state.retirementAge) {
            openSnackbar({ message: "Age should be less than Retirement age" })
        } else if (this.state.existingInvestment < 0) {
            openSnackbar({ message: "Existing Investment should be 0 or higher" })
        } else {
            this.setState({ panel1: false, panel2: true, editPanel1: true });
        }
    };

    validationPanel2 = () => {
        if (this.state.rentalIncome < 0) {
            openSnackbar({ message: "Rental Income should be 0 or higher" })
        } else if (this.state.retiralBenefits < 0) {
            openSnackbar({ message: "Retiral Benefits should be 0 or higher" })
        } else if (this.state.pensionIncome < 0) {
            openSnackbar({ message: "Pension Income should be 0 or higher" })
        } else if (this.state.presentMonthlyExpenses < 5000) {
            openSnackbar({ message: "Present Monthly Expenses should be 5000 or higher" })
        } else {

            this.setState({ panel2: false, panel3: true, editPanel2: true }, () => this.shortageAmount());
            if (this.state.shortageAmount >= 0) {
                this.summary();
                this.tryNewState();  /*  if changes are made in the above original form  */

                /* for initially displaying the default conservativeInvestment output */
                let output = document.getElementById('output');
                output.innerHTML = "₹ " + this.numberFormat(this.conservativeInvestment()) + "*";
                let pointer = document.getElementById('pointer');
                pointer.style.transform = "translateX(-30px) translateY(35px) rotate(-70deg)";
            }

            this.sendDataToServer();
        }
    };

    sendDataToServer = () => {
        let user = firebase.auth().currentUser;
        if (user) {
            axios.post('user/saveRetirement', {
                uid: user.uid,
                inputs: {
                    age: this.state.age,    /*  Database  */
                    retirementAge: this.state.retirementAge,    /*  Database  */
                    gender: this.state.gender,    /*  Database  */
                    existingInvestment: this.state.existingInvestment,    /*  Database  */
                    retiralBenefits: this.state.retiralBenefits,    /*  Database  */
                    presentMonthlyExpenses: this.state.presentMonthlyExpenses,    /*  Database  */
                    rentalIncome: this.state.rentalIncome,    /*  Database  */
                    pensionIncome: this.state.pensionIncome,    /*  Database  */
                    newMonthlyInvestment: this.state.newMonthlyInvestment    /*  Database  */
                },
                userId: user.uid
            }).then(result => {

            }).catch(exception => {
                openSnackbar({ message: 'Something went wrong' });
            });
        }
    }

    /* submitResultAgain = (e) => {
        e.preventDefault();
        this.sendDataToServer();
    } */

    panel1 = (e) => {
        e.preventDefault();
        if (this.state.shortageAmount >= 0) {
            if (this.state.panel1 && this.state.editPanel1) {
                this.validationPanel1();
            }
            if (this.state.editPanel1) {
                this.setState({ panel1: true, panel2: false, panel3: false });
            }
        }
    };

    panel2 = (e) => {
        e.preventDefault();
        if (this.state.shortageAmount >= 0) {
            if (this.state.panel2) {
                this.validationPanel2();
            }
            if (this.state.editPanel2) {
                this.setState({ panel1: false, panel2: true, panel3: false });
            }
        }
    };

    refreshPage = () => {
        window.location.reload();
    }


    componentDidMount() {
        let user = firebase.auth().currentUser;
        if (user) {
            this.setState({ userName: user.displayName });
        }
        if (user) {
            axios.post('user/getRetirementPlanner', {
                uid: user.uid
            }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState(result.data.result.inputs);
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: 'Something went wrong' });
            });
        }
    }

    render() {

        const { classes } = this.props;
        let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'Retirement Planner', to: '' }];

        let editPanel1 = null;
        let editPanel2 = null;
        /*  if condition checks wheather "editPanel1" is true  */
        if (this.state.editPanel1) {
            editPanel1 = <Create />;
        }
        /*  if condition checks wheather "editPanel2" is true  */
        if (this.state.editPanel2) {
            editPanel2 = <Create />;
        }

        /* if shortageAmount is less than zero, then the edit pencil icon would not be shown */
        if (this.state.shortageAmount < 0) {
            editPanel1 = null;
            editPanel2 = null;
        }

        return (
            <div>
                <div className="app">
                    <div className="bread__container">
                        <Breadcrumbs list={breadcrumbsList} />
                    </div>
                </div>
                <div className="app">
                    <div className="app__container">
                        <Notifier />
                        <Grid container>
                            <Grid item
                                container
                                justify="center"
                                xs={12}
                            >
                                <Grid item
                                    container
                                    justify="center"
                                    xs={12}
                                >
                                    <Typography component="h2" variant="h4" className={classes.heading} gutterBottom>Retirement Planner</Typography>
                                </Grid>
                                <Grid item
                                    container
                                    justify="center"
                                    xs={12}
                                >
                                    <Typography component="h3" variant="body2" className={classes.subheading} gutterBottom>Find out how much you need for a happy and worry free retired life<br /><br /></Typography>
                                </Grid>
                            </Grid>

                            <Grid item
                                xs={12}
                            >
                                <form>
                                    <Grid item
                                        xs={12}
                                    >
                                        <ExpansionPanel
                                            expanded={this.state.shortageAmount >= 0 && this.state.panel1 === true}
                                            onChange={this.panel1}
                                        >
                                            <ExpansionPanelSummary
                                                className={this.state.panel1 ? classes.afterExpansionColor : classes.beforeExpansionColor}
                                                expandIcon={editPanel1}
                                            >
                                                <Grid item
                                                    container
                                                    justify="center"
                                                    xs={12}
                                                >
                                                    <Typography variant="h6" className={this.state.panel1 ? classes.afterSectionHeading : classes.beforeSectionHeading}>PERSONAL INFORMATION</Typography>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    <Grid item
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <Grid item
                                                            container
                                                            justify="center"
                                                        >
                                                            <TextField
                                                                id="filled-name"
                                                                label="Name"
                                                                className={classes.nameTextField}
                                                                margin="normal"
                                                                onChange={this.onNameChange}
                                                                value={this.state.userName}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        className="gender"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <Grid item
                                                            container
                                                            justify="center"
                                                            xs={12}
                                                            md={10}
                                                            lg={10}
                                                        >
                                                            <Grid item
                                                                container
                                                                justify="center"
                                                                xs={12}
                                                                md={10}
                                                                lg={10}
                                                            >
                                                                <RadioGroup
                                                                    name="gender"
                                                                    value={this.state.gender}
                                                                    onChange={this.onGender}
                                                                    row
                                                                >
                                                                    <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" className={classes.radioMargin} />
                                                                    <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" className={classes.radioMargin} />
                                                                </RadioGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <div>
                                                            <FormLabel component="legend" className={classNames(classes.formLabel)}>Select your present age</FormLabel>
                                                            <div className={classNames(classes.root, "form--range-input")}>
                                                                <Slider
                                                                    classes={{ container: classes.slider }}
                                                                    value={this.state.age}
                                                                    min={18}
                                                                    max={65}
                                                                    step={1}
                                                                    onChange={this.onAgeChange}
                                                                />
                                                                <span className="form--range-display">{this.state.age}</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <div>
                                                            <FormLabel component="legend" className={classNames(classes.formLabel)}>Select your retirement age</FormLabel>
                                                            <div className={classNames(classes.root, "form--range-input")}>
                                                                <Slider
                                                                    classes={{ container: classes.slider }}
                                                                    value={this.state.retirementAge}
                                                                    min={40}
                                                                    max={80}
                                                                    onChange={this.onRetirementAge}
                                                                />
                                                                <span className="form--range-display">{this.state.retirementAge}</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <Button onClick={this.validationPanel1} type="button" variant="contained" className={classes.button}>
                                                            Proceed
                                                    </Button>
                                                    </Grid>
                                                </Grid>

                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>


                                    <Grid item
                                        xs={12}
                                    >
                                        <ExpansionPanel
                                            expanded={this.state.shortageAmount >= 0 && this.state.panel2 === true}
                                            onChange={this.panel2}
                                        >
                                            <ExpansionPanelSummary
                                                className={this.state.panel2 ? classes.afterExpansionColor : classes.beforeExpansionColor}
                                                expandIcon={editPanel2}
                                            >
                                                <Grid item
                                                    container
                                                    justify="center"
                                                    xs={12}
                                                >
                                                    <Typography variant="h6" className={this.state.panel2 ? classes.afterSectionHeading : classes.beforeSectionHeading}>FINANCIAL INFORMATION</Typography>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    <Grid item
                                                        className="center-content"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <TextField
                                                            id="filled-number"
                                                            label="Existing Investment"
                                                            className={classes.textField}
                                                            onChange={this.existingInvestment}
                                                            value={this.state.existingInvestment}
                                                            type="number"
                                                            margin="normal"
                                                        />
                                                        <Tooltip classes={{ tooltip: classes.customTooltip }} title="Current value of invested amount for retirement" placement="top-end" enterDelay={100} leaveDelay={500}>
                                                            <Info className={classes.infoIcon} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item
                                                        className="center-content"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <TextField
                                                            id="filled-number"
                                                            label="Rental Income"
                                                            className={classes.textField}
                                                            onChange={this.rentalIncome}
                                                            value={this.state.rentalIncome}
                                                            type="number"
                                                            margin="normal"
                                                        />
                                                        <Tooltip classes={{ tooltip: classes.customTooltip }} title="Current value of any rental / regular income which will continue post retirement too" placement="top-end" enterDelay={100} leaveDelay={500}>
                                                            <Info className={classes.infoIcon} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item
                                                        className="center-content"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <TextField
                                                            id="filled-number"
                                                            label="Retiral Benefits"
                                                            className={classes.textField}
                                                            onChange={this.retiralBenefits}
                                                            value={this.state.retiralBenefits}
                                                            type="number"
                                                            margin="normal"
                                                        />
                                                        <Tooltip classes={{ tooltip: classes.customTooltip }} title="(Bonus + PF + Gratuity) Expected lump sum amount at retirement" placement="bottom-end" enterDelay={100} leaveDelay={500}>
                                                            <Info className={classes.infoIcon} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item
                                                        className="center-content"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <TextField
                                                            id="filled-number"
                                                            label="Pension Income"
                                                            className={classes.textField}
                                                            onChange={this.pensionIncome}
                                                            value={this.state.pensionIncome}
                                                            type="number"
                                                            margin="normal"
                                                        />
                                                        <Tooltip classes={{ tooltip: classes.customTooltip }} title="Pension or any fixed regular income expected post retirement" placement="bottom-end" enterDelay={100} leaveDelay={500}>
                                                            <Info className={classes.infoIcon} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item
                                                        className="center-content"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <TextField
                                                            id="filled-number"
                                                            label="Present Monthly Expenses"
                                                            className={classes.textField}
                                                            onChange={this.presentMonthlyExpenses}
                                                            value={this.state.presentMonthlyExpenses}
                                                            type="number"
                                                            margin="normal"
                                                        />
                                                        <Tooltip classes={{ tooltip: classes.customTooltip }} title="Minimum amount needed for monthly sustenance at current lifestyle" placement="bottom-end" enterDelay={100} leaveDelay={500}>
                                                            <Info className={classes.infoIcon} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item
                                                        container
                                                        justify="center"
                                                        xs={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <Grid item
                                                            xs={12}
                                                            md={8}
                                                            lg={8}
                                                        >
                                                            <Button type="button" variant="contained" className={classes.submitButton} onClick={this.validationPanel2}>
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>


                                    <Grid item
                                        xs={12}
                                    >
                                        <ExpansionPanel
                                            expanded={this.state.panel3 === true}
                                        >
                                            <ExpansionPanelSummary
                                                className={this.state.panel3 ? classes.afterExpansionColor : classes.beforeExpansionColor}
                                            >
                                                <Grid item
                                                    container
                                                    justify="center"
                                                    xs={12}
                                                >
                                                    <Typography variant="h6" className={this.state.panel3 ? classes.afterSectionHeading : classes.beforeSectionHeading}>SUMMARY</Typography>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container
                                                    justify="center"
                                                >
                                                    {
                                                        this.state.shortageAmount >= 0 ?
                                                            <Grid item
                                                                container
                                                            >
                                                                {/* <div id="summary" className="hidden"> */}
                                                                < Grid item
                                                                    container
                                                                    justify="center"
                                                                    className="form--summary"
                                                                >
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={10}
                                                                        lg={10}
                                                                        xl={8}
                                                                    >
                                                                        <Typography variant="body2" className="form--summary-details" gutterBottom>
                                                                            When you retire, today’s monthly expense of <span id="presentMonthlyExpenses" className="form--summary-output"></span> will be <span id="monthlyRequirementFuture" className="form--summary-output"></span> due to inflation.
                                                                    </Typography>
                                                                        <br />
                                                                    </Grid>
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={10}
                                                                        lg={10}
                                                                        xl={8}
                                                                    >
                                                                        <Typography variant="body2" className="form--summary-details" gutterBottom>
                                                                            Based on your requirement, <span id="expectedCorpusRequired" className="form--summary-output"></span> is the corpus you need in retirement.
                                                                    </Typography>
                                                                        <br />
                                                                    </Grid>
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={10}
                                                                        lg={10}
                                                                        xl={8}
                                                                    >
                                                                        <Typography variant="body2" className="form--summary-details" gutterBottom>
                                                                            Considering investments and other incomes you may receive on or post retirement, the shortage is <span id="shortageAmount" className="form--summary-output"></span>.
                                                                    </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    <div className="meter">
                                                                        <div className="gauge-meter">
                                                                            <img src={conservativeImage}
                                                                                alt="" className="conservative"
                                                                                onClick={() => this.conservativeInvestmentOutput()} />
                                                                            <img
                                                                                src={moderateImage}
                                                                                alt="" className="moderate"
                                                                                onClick={() => this.moderateInvestmentOutput()} />
                                                                            <img src={aggressiveImage}
                                                                                alt="" className="aggressive"
                                                                                onClick={() => this.aggressiveInvestmentOutput()} />
                                                                            <img src={pointer}
                                                                                alt="" className="pointer"
                                                                                id="pointer" />
                                                                        </div>
                                                                    </div>
                                                                    <p className="form--output center-content">
                                                                        <span id="output"></span>
                                                                    </p>
                                                                </Grid>
                                                                <Grid item
                                                                    container
                                                                    justify="center"
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <div id="riskProfileNote1" className="center-content">
                                                                            <Typography variant="subtitle2" align="center" className="center-content">
                                                                                <span className="note1"><strong>Note* </strong></span> Choose from the risk profiles shown above to know how much you should invest monthly starting today to retire comfortably.
                                                                    </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <div id="riskProfileNote2" className="center-content">
                                                                            <Typography variant="subtitle2" align="center" className="center-content hidden">
                                                                                <span className="note2"><strong>Note** </strong></span>Choose from the risk profile above to find how much can you spend monthly when you retire at today's value.
                                                                    </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <div id="tryNewParagraph" className="center-content">
                                                                            <Typography variant="body2" align="center">
                                                                                Click TRY NEW to enter a monthly investment of your choice or OK to begin investing towards retirement.
                                                                    </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item
                                                                        xs={12}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <div id="monthlyInvestmentParagraph" className="center-content hidden">
                                                                            <Typography variant="body2" align="center">
                                                                                Enter Monthly Investment of your choice.
                                                                </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    <div id="tryNewOkButton">
                                                                        <Grid item
                                                                            container
                                                                            justify="space-between"
                                                                        >
                                                                            <Button type="button" variant="contained" className={classes.button} onClick={this.tryNew}>Try New</Button>
                                                                            <a type="button" href="http://fundnow.investography.in/" target="_blank" className={classes.okLink}>OK</a>
                                                                        </Grid>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    <div id="newMonthlyInvestmentForm" className="hidden">
                                                                        <Grid item
                                                                            container
                                                                            justify="center"
                                                                        >
                                                                            <Grid item
                                                                                className="center-content"
                                                                                xs={12}
                                                                                md={6}
                                                                                lg={6}
                                                                            >
                                                                                <TextField
                                                                                    id="filled-number"
                                                                                    label="Monthly Investment"
                                                                                    className={classes.textField}
                                                                                    value={this.state.newMonthlyInvestment}
                                                                                    onChange={this.newMonthlyInvestment}
                                                                                    type="number"
                                                                                    margin="normal"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item
                                                                                container
                                                                                justify="center"
                                                                            >
                                                                                <Grid item
                                                                                    className="center-content"
                                                                                    xs={12}
                                                                                    md={6}
                                                                                    lg={6}
                                                                                >
                                                                                    <div className="center-content">
                                                                                        <Button type="button" variant="contained" id="recalculate" className={classes.button} onClick={this.recalculate}>Calculate</Button>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item
                                                                    container
                                                                    justify="center"
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    <Typography variant="caption" align="center">*The above figures are projections calculated at 8% p.a for a conservative profile, at 10% p,a for a moderate profile and at 12% p.a for an aggressive profile. </Typography>
                                                                </Grid>
                                                                <Grid item
                                                                    container
                                                                    justify="center"
                                                                    className={classes.banner}
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    <Typography variant="subtitle1" align="center" className={classes.whiteText}>
                                                                        If you’d like to talk to us about planning for your retirement, please write to us on <a href="mailto:wecare@investography.in" className={classes.link}>wecare@investography.in</a>
                                                                    </Typography>
                                                                </Grid>
                                                                {/* </div> */}
                                                            </Grid>
                                                            : <div>
                                                                <Typography variant="h5" align="center">Good Job</Typography>
                                                                <Typography variant="h6" align="center">You are on the right track & have your retirement covered</Typography>
                                                                <div className="center-content">
                                                                    <Button type="button" variant="contained" className={classes.button} onClick={this.refreshPage}>Try Again</Button>
                                                                </div>
                                                            </div>
                                                    }
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div >
        );
    }
};


RetirementPlanner.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RetirementPlanner);
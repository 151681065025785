import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
// import Blog1 from '../../images/blog-1.jpg';
import Blog2 from '../../images/Bulletproof1.jpg';
import Blog3 from '../../images/blog3.jpg';
import Blog1 from '../../images/latte.JPG';

const style = theme => ({
    blog: {
        /* backgroundColor: '#F0F8FF', */
        backgroundColor: '#FFFFFF',
        padding: '4rem 0',
    },
    container: {
        width: '80%',
        padding: '0 15px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    image: {
        width: '100%',
        height: '200px',
        [theme.breakpoints.up('xl')]: {
          height: '300px'
        }
    },
    box: {
        textAlign: 'center',
        transition: 'all .3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
        },
    },
    blogHeading: {
        padding: '20px 10px',
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '1px',
    },
    button: {
        marginTop: '20px',
        fontSize: '14px',
        letterSpacing: '1px',
        fontWeight: 400,
    },
    heading: {
        paddingBottom: '3rem',
        letterSpacing: '1px',
        fontWeight: 400,
        fontSize: '2.5rem',
    },
    blogText: {
        padding: '0 1rem',
        fontSize: '14px',
    },
})

const Blog = props => {
    const { classes } = props;
    return (
        <div className={classes.blog}>
            <Grid
                item
                container
                justify="center"
                xs={12}
            >
                <Typography
                    variant="h4"
                    className={classes.heading}
                >
                    BLOG
                </Typography>
            </Grid>
            <Grid
                container
                justify="center"
                spacing={32}
                className={classes.container}
            >
                <Grid
                    item
                    xs={12}
                    lg={4}
                    className={classes.box}
                >
                    <Paper elevation={15}>
                        <img className={classes.image} src={Blog1} alt=" " />
                        <Typography className={classes.blogHeading} variant="h6">Better Latte Than Never</Typography>
                        <Typography style={{ paddingBottom: '20px' }} variant="body1" className={classes.blogText} >Distinguishing between your wants and your needs is touted by personal finance experts as the holy grail to a better financial life.</Typography>
                        <Link style={{ textDecoration: 'none', color: '#29B5C3'}} to="/magazine/blog-details?title=better-latte-than-never&blog=DpzjED80IvWeI3VWgkI8">LEARN MORE</Link>
                    </Paper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={4}
                    className={classes.box}
                >
                    <Paper elevation={15} >
                        <img className={classes.image} src={Blog2} alt=" " />
                        <Typography className={classes.blogHeading} variant="h6">You Need A Bulletproof Fund</Typography>
                        <Typography style={{ paddingBottom: '20px' }} variant="body1" className={classes.blogText}>How would you feel if you had an invisibility cloak or a bullet proof vest? We would say, safe and protected.</Typography>
                        <Link style={{ textDecoration: 'none', color: '#29B5C3' }} to="/magazine/blog-details?title=you-need-a-bulletproof-fund&blog=TjbePzzcXSlsf6XwadHx">LEARN MORE</Link>
                    </Paper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={4}
                    className={classes.box}
                >
                    <Paper elevation={15}>
                        <img className={classes.image} src={Blog3} alt=" " />
                        <Typography className={classes.blogHeading} variant="h6">Do Millennials Need Money Managers?</Typography>
                        <Typography style={{ paddingBottom: '20px' }} variant="body1" className={classes.blogText}>When Ikea was setting up shop in India, they realised that one of the trickier things they would have to deal with was to help...</Typography>
                        <Link style={{ textDecoration: 'none', color: '#29B5C3'}} to="/magazine/blog-details?title=do-millennials-need-money-managers-&blog=f552X7hVUG24ErQAmnnJ">LEARN MORE</Link>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

Blog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Blog);
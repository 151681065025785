import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from '../../axios/axios';
import Notifier, { openSnackbar } from '../utility/Notifier';
import Button from '@material-ui/core/Button';
import './admin.css';
import { Typography } from '@material-ui/core';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    formDiv: {
        display: 'grid',
        gridTemplateColumns: '300px 1fr 80px',
        gridColumnGap: '20px',
        '&>div': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    input: {
        fontSize: '15px',
        width: '100%',
        height: '25px',
        border: 'none',
        borderBottom: '1px solid',
        marginLeft: '10px'
    },
    headingText: {
        margin: '10px 0',
        fontSize: '22px',
        fontWeight: '400',
        textAlign: 'center',
        cursor: 'pointer',
        '&::after': {
            display: 'block',
            width: '15em',
            height: '3px',
            backgroundColor: '#29B5C3',
            content: '" "',
            margin: '2px auto',
            borderRadius: '2px'
        }
    },
});

class BlogSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sections: [],
            section: '',
            open: false,
            token: sessionStorage.getItem('jwtToken'),
            name: '',
            description: '',
            id: '',
            previousName: ""
        }
    }

    fetchSections = () => {
        axios.get('blog/fetchSection',
            { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState({
                            sections: result.data.sections
                        });
                    } else {
                        openSnackbar({ message: "Something went wrong" });
                    }
                }
            }).catch(exception => {
                openSnackbar({ message: exception.message });
            });
    }

    saveSection = () => {
        if (this.state.id) {
            axios.post('blog/updateSection', {
                id: this.state.id,
                name: this.state.name,
                description: this.state.description
            },
                { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                    if (result.status === 200) {
                        if (result.data.success) {
                            openSnackbar({ message: result.data.msg });
                            this.setState({
                                id: "",
                                name: "",
                                description: "",
                                previousName: ""
                            });
                            this.fetchSections();
                        } else {
                            openSnackbar({ message: "Something went wrong" });
                        }
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                }).catch(exception => {
                    openSnackbar({ message: exception.message });
                });
        } else {
            axios.post('blog/insertSection', {
                name: this.state.name,
                description: this.state.description
            },
                { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
                    if (result.status === 200) {
                        if (result.data.success) {
                            openSnackbar({ message: result.data.msg });
                            this.setState({
                                id: "",
                                name: "",
                                description: "",
                                previousName: ""
                            });
                            this.fetchSections();
                        } else {
                            openSnackbar({ message: "Something went wrong" });
                        }
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                }).catch(exception => {
                    openSnackbar({ message: exception.message });
                });
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.fetchSections();
    }

    inputChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }}
                    />
                </div>
                <div id="Author">
                    <Fragment>
                        <Notifier />
                        {
                            this.state.id ? <Typography variant="h5" className={classes.headingText} onClick={() => {
                                this.setState({
                                    id: "",
                                    name: "",
                                    description: ""
                                });
                            }}>Updating <span style={{ color: "#ff9933" }}>{this.state.previousName}</span>, click here to clear update</Typography> : null
                        }
                        <form className={classes.formDiv} onSubmit={(e) => {
                            e.preventDefault();
                            this.saveSection();
                        }}>
                            <div>
                                <label>Name</label>
                                <input type="text" name="name" value={this.state.name} className={classes.input} onChange={this.inputChangeHandler} />
                            </div>
                            <div>
                                <label>Description</label>
                                <input type="text" name="description" value={this.state.description} className={classes.input} onChange={this.inputChangeHandler} />
                            </div>
                            <button>Save</button>
                        </form>
                        <Paper className={classes.root}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell>Name</CustomTableCell>
                                        <CustomTableCell>Description</CustomTableCell>
                                        <CustomTableCell>Actions</CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.sections.map((section) => {
                                        return <TableRow className={classes.row} key={section.id}>
                                            <CustomTableCell>{section.name}</CustomTableCell>
                                            <CustomTableCell>{section.description}</CustomTableCell>
                                            <CustomTableCell>
                                                <div>
                                                    <button style={{
                                                        border: 'none',
                                                        background: 'transparent',
                                                        textDecoration: 'underline',
                                                        textTransform: 'uppercase',
                                                        color: '#29B5C3',
                                                        cursor: 'pointer'
                                                    }} onClick={() => {
                                                        this.setState({
                                                            id: section.id,
                                                            name: section.name,
                                                            description: section.description,
                                                            previousName: section.name
                                                        });
                                                    }}>Update</button>
                                                    <br />
                                                    <button style={{
                                                        border: 'none',
                                                        background: 'transparent',
                                                        textDecoration: 'underline',
                                                        textTransform: 'uppercase',
                                                        color: '#29B5C3',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => {
                                                            this.setState({ section: section, open: true });
                                                        }}>Delete</button>
                                                </div>
                                            </CustomTableCell>
                                        </TableRow>
                                    })}
                                    {this.state.section !== null ?
                                        <Dialog
                                            open={this.state.open}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={this.handleClose}
                                            aria-labelledby="alert-dialog-slide-title"
                                            aria-describedby="alert-dialog-slide-description"
                                        >
                                            <DialogTitle id="scroll-dialog-title">Section Details</DialogTitle>
                                            <DialogContent>

                                                <div className="modal-content">
                                                    <strong><p>Are you sure?</p></strong>

                                                    <button className="modalFetchEventBT addButton" onClick={() => {
                                                        axios.post('blog/deleteSection', {
                                                            id: this.state.section.id
                                                        },
                                                            { headers: { authorization: `Bearer ${this.state.token}` } })
                                                            .then(result => {
                                                                if (result.status === 200) {
                                                                    if (result.data.success) {
                                                                        this.setState({
                                                                            open: false
                                                                        }, () => {
                                                                            this.fetchSections();
                                                                        });
                                                                        openSnackbar({ message: result.data.msg });
                                                                    } else {
                                                                        openSnackbar({ message: result.data.msg });
                                                                    }
                                                                }
                                                            }).catch(exception => {
                                                                openSnackbar({ message: exception });
                                                            });
                                                    }}>Delete</button>
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={this.handleClose} color="primary">
                                                    Cancel</Button>
                                            </DialogActions>
                                        </Dialog>
                                        : null}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Fragment>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(BlogSection);
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import axios from '../../axios/axios';
import Notifier, { openSnackbar } from '../utility/Notifier';

const styles = theme => ({
    subscribe: {
        background: 'linear-gradient(45deg, #29B5C3 30%, #21CBF3 90%)',
        textAlign: 'center',
        color: '#FFFFFF',
    },
    heading: {
        fontSize: '24px',
        padding: '2em 0',
        letterSpacing: '1px',
        '&::after': {
            position: 'absolute',
            display: 'block',
            borderBottom: '1px solid #FFFFFF',
            width: '3em',
            height: '.2em',
            background: '#FFFFFF',
            content: '" "',
            left: '50%',
            marginLeft: '-25px',
            marginTop: '10px',
        }
    },
    subscribeForm: {
        padding: '2em 0',
    },
    subscribeInput: {
        width: 300,
        margin: theme.spacing.unit,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    cssLabel: {
        color: '#FFFFFF',
        fontSize: '1rem',
        '&$cssFocused': {
            color: '#FFFFFF',
        },
    },
    cssFocused: {},
    cssOutlinedInput: {
        borderColor: '#FFFFFF',
        '&$cssFocused $notchedOutline': {
            borderColor: '#FFFFFF',
        },
    },
    notchedOutline: {},
    subscribeButton: {
        marginTop: '20px',
        marginLeft: '9px',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        color: '#FFFFFF',
        letterSpacing: '2px',
        fontSize: '13px',
        fontWeight: 700,
    },
    subscribeText: {
        paddingLeft: '15px',
        paddingRight: '15px',
    }
});

class Subscribe extends Component {

    state = {
        email: '',
        name: ''
    }

    nameChangeHandler = (event) => {
        let val = event.target.value;
        this.setState({
            name: val
        });
    };

    emailChangeHandler = (event) => {
        let val = event.target.value;
        this.setState({
            email: val
        });
    };

    subscribeData = (e) => {
        e.preventDefault();
        if (this.state.name === '' || this.state.name === null || this.state.name === undefined) {
            openSnackbar({ message: 'Name is empty' });
        } else if (this.state.email === '' || this.state.email === null || this.state.email === undefined) {
            openSnackbar({ message: 'Email is empty' });
        } else {

            axios.post('contactUs/subscribe', this.state)
                .then(result => {
                    openSnackbar({ message: result.data.msg });
                }).catch(exception => {
                    openSnackbar({ message: 'Something went wrong' });
                });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid className={classes.subscribe} container spacing={0}>
                <Notifier />
                <Grid item xs={12}>
                    <h4 className={classes.heading}>STAY UP TO DATE</h4>
                    <p className={classes.subscribeText}>Subscribe to our newsletter and stay updated on our market insights and news on what we've been up to. We promise not to spam you.</p>
                    <br />
                    <form className={classes.subscribeForm}>
                        <TextField
                            type="text"
                            label="Name"
                            variant="outlined"
                            required
                            onChange={this.nameChangeHandler}
                            className={classes.subscribeInput}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                        />
                        <TextField
                            type="email"
                            label="Email"
                            required
                            variant="outlined"
                            onChange={this.emailChangeHandler}
                            className={classes.subscribeInput}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                        />
                        <Button
                            className={classes.subscribeButton}
                            variant="contained"
                            onClick={this.subscribeData}
                        >
                            Subscribe
                        </Button>
                    </form>
                </Grid>
            </Grid>
        );
    }
};

Subscribe.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Subscribe);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { theme } from '../../theme';


const styles = theme => ({
    margin: {
        margin: '2em 0',
    },
    textAlign: {
        textAlign: 'center',
    },
});


class PrivacyPolicy extends Component {


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container
                    justify="center"
                    className={classes.margin}
                >
                    <Grid item
                        className={classes.margin}
                        xs={12}
                    >
                        <Typography variant="h4" className={classes.textAlign} gutterBottom><b>PRIVACY POLICY</b></Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            We do not sell, forward, or rent your personal information that you share with Investography Pvt. Ltd., to any third party for any purposes like marketing etc. without your consent. This privacy policy is applicable to our services available under this domain. Your use of Investography Site will signify your acceptance of the Investography Privacy Policy.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            Wherever your Personal Information is held within Investography, the company shall take reasonable steps to protect it from unauthorized access or disclosure. Investography is committed to protecting the security of your personal information. We store the personal information you provide on computer systems with limited access, which are located in controlled facilities. When we transmit highly confidential information, we protect it with encryption.

                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            You may choose whether or not to provide Personal Information to Investography. If you choose not to provide the Personal Information then you may be unable to access certain options, offers, and services that involve our interaction with you. If you chose to have a relationship with Investography, Investography will continue to contact you in connection with that business relationship.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            Where Investography gets your Personal Information on the Site, Investography intends to explain why Personal Information will be collected and whether Investography plans to share such Personal Information outside or with those working on Investography behalf. Unless the transfer of Personal Information is legally required, Investography will not transfer Personal Information without your permission to third parties.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            In the event that you do provide Personal Information, Investography shall maintain accurate Personal Information that you have provided. Where Investography collects Personal Information from you on the Site, its aim is to provide a means of contacting Investography whenever you need to update or correct that Information.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            Personal Information will be kept confidential and will be used for our research, marketing, and strategic client analysis objectives and other internal business purposes only. We do not sell or rent Personal Information, except that your Personal Information shall be shared with our business partners/franchisees and you shall be deemed to have given consent to the same. We will share Personal Information only under the circumstances where we are compelled by law (including court orders) to do so.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            By using the Site, you consent to the terms of Investography’s Privacy Policy and processing of Personal Information for the purposes given above. When your Personal information is needed, Investography will not be obligated to let you know at the time of collection, how it will use the personal information. Generally the personal information Investography collects is used only by it to respond to your inquiry, process an order or allow you to access specific services. You will be offered an opportunity to limit access to your information. In case Investography does not receive any response from you to limit the access of your information, it shall be deemed that you consent to such distribution of your Personal Information as mentioned above.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={11}
                        sm={10}
                        md={9}
                        lg={8}
                    >
                        <Typography variant="body2">
                            Please note that this Privacy Policy may keep changing from time to time without notice at the sole discretion of Investography. If you have any questions about Investography Privacy Policy, collection, use, or disclosure of your personal information, please e-mail us at wecare@investography.in.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PrivacyPolicy);
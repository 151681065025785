import React, { Component } from 'react';
import axios from '../../../axios/axios';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import './financialInputs.css';
import FiveStep from '../../../images/five_step.jpg';
import Result1D from '../../../images/report_1-10_desktop.png';
import Result2D from '../../../images/report_11-15_desktop.png';
import Result3D from '../../../images/report_16-20_desktop.png';

import Result1M from '../../../images/0-10.png';
import Result2M from '../../../images/11-15.png';
import Result3M from '../../../images/16-20.png';
import Triangle from '../../../images/triangle.png';
import { firebase } from '../../../firebase/firebase';
import Notifier, { openSnackbar } from '../../utility/Notifier';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import { Typography } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#6FBE63',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Lato',
            'sans-serif',
        ].join(','),
        htmlFontSize: '12',
        body2: {
            fontSize: '16px',
            marginBottom: '10px',
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                color: '#7B818C !important',
            },
        },
        MuiFormLabel: {
            root: {
                color: '#4B818C',
                fontSize: '16px',
                lineHeight: '1.2',
                fontWeight: 'bold',
                margin: '10px 0',
            },
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '14px',
                marginTop: '7px',
            }
        },
        MuiSvgIcon: {
            root: {
                width: '20px',
            }
        },
        MuiStepper: {
            root: {
                padding: '20px 0px',
            }
        },
        MuiStepIcon: {
            text: {
                fill: '#FFFFFF',
                fontSize: '14px',
            },
        },
        MuiStepLabel: {
            label: {
                fontSize: '14px',
            }
        }
    },
});

const styles = theme => ({
    container: {
        backgroundColor: '#C7EAC2',
        padding: '1em 0',
    },
    breadcrumbs: {
        width: '80%',
        paddingBottom: '1em',
        margin: '0 auto 1em auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    financialCheckup: {
        width: '80%',
        backgroundColor: '#FFF',
        borderRadius: '5px',
        padding: '1em 0',
        margin: '2em auto',
        webkitBoxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        mozBoxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    heading: {
        color: '#444',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '0.07em',
        wordSpacing: '0.3em',
    },
    subheading: {
        fontSize: '16px',
        color: '#666',
        textAlign: 'center',
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
        marginBottom: '1.5em',
    },
    image: {
        margin: '0 auto',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        color: '#fff',
        background: '#6FBE63',
        letterSpacing: '0.4em',
        margin: '1em',
    },
    link: {
        textDecoration: 'none',
        letterSpacing: '0.1em',
        color: '#fff',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        padding: '0.5em 0.8em 0.5em 0.8em',
        borderRadius: '7px',
    }
});

class FinancialHealthCheckup extends Component {

    state = {
        activeStep: 0,

        age: '',
        maritalStatus: '',
        work: '',
        income: '',
        finnacialDependency: '',

        percentSavingPoints: null,
        percentAsidePoints: null,
        investmentPoints: null,
        taxLiabilityPoints: null,
        investmentConfidentPoints: null,

        spentEMIPoint: null,
        loan: '',
        lifeInsurancePoint: null,
        medicalInsurancePoint: null,

        financialGoalsPoint: null,
        financialPriority: '',
        manageMoneyPoint: null,
        retire: '',
        financialAdvisorPoint: null
    };

    getSteps = () => {
        return ['Start Now', 'About you', 'Savings & Investments', 'Liabilities and Protections', 'Financial Goals'];
    }

    onHandleChangeAge = e => {
        this.setState({ age: e.target.value })
    }

    onHandleChangeMarital = e => {
        this.setState({ maritalStatus: e.target.value })
    }

    onHandleChangeWork = e => {
        this.setState({ work: e.target.value })
    }

    onHandleChangeIncome = e => {
        this.setState({ income: e.target.value })
    }

    onHandleChangeFDepend = e => {
        this.setState({ finnacialDependency: e.target.value })
    }

    onHandleChangeLoan = e => {
        this.setState({ loan: e.target.value })
    }

    onHandleChangeFPrior = e => {
        this.setState({ financialPriority: e.target.value })
    }

    onHandleChangeRetire = e => {
        this.setState({ retire: e.target.value })
    }



    percentSaving = (event) => {
        let val = parseInt(event.currentTarget.value);
        this.setState(() => ({
            percentSavingPoints: val
        }));
    }

    percentAside = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            percentAsidePoints: val
        }));
    }

    investment = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            investmentPoints: val
        }));
    }

    taxLiability = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            taxLiabilityPoints: val
        }));
    }

    investmentConfident = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            investmentConfidentPoints: val
        }));
    }

    spentEMIPercent = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            spentEMIPoint: val
        }));
    }

    lifeInsurance = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            lifeInsurancePoint: val
        }));
    }

    medicalInsurance = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            medicalInsurancePoint: val
        }));
    }

    financialGoals = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            financialGoalsPoint: val
        }));
    }

    manageMoney = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            manageMoneyPoint: val
        }));
    }

    financialAdvisor = (event) => {
        let val = parseInt(event.target.value);
        this.setState(() => ({
            financialAdvisorPoint: val
        }));
    }

    totalScore = () => {
        let score = 0;
        const points = Object.values(this.state);
        points.forEach(value => {
            if (isNaN(value)) {
                console.log(value);
            } else {
                score += value;
            }
        });
        score -= this.state.activeStep;
        localStorage.setItem('score', score);
        return score;
    }



    handleNext = () => {
        if (this.state.activeStep === 1) {
            if (this.state.age === '' || this.state.age === undefined || this.state.age === null) {
                openSnackbar({ message: "Age is not selected" });
                return;
            } else if (this.state.maritalStatus === '' || this.state.maritalStatus === undefined || this.state.maritalStatus === null) {
                openSnackbar({ message: "Marital Status is not selected" });
                return;
            } else if (this.state.work === '' || this.state.work === undefined || this.state.work === null) {
                openSnackbar({ message: "About work is not selected" });
                return;
            } else if (this.state.income === '' || this.state.income === undefined || this.state.income === null) {
                openSnackbar({ message: "Annual Income is not selected" });
                return;
            } else if (this.state.finnacialDependency === '' || this.state.finnacialDependency === undefined || this.state.finnacialDependency === null) {
                openSnackbar({ message: "Financial dependency not selected" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 2) {
            if (this.state.percentSavingPoints === '' || this.state.percentSavingPoints === undefined || this.state.percentSavingPoints === null) {
                openSnackbar({ message: "Income Saving not selected" });
                return;
            } else if (this.state.percentAsidePoints === '' || this.state.percentAsidePoints === undefined || this.state.percentAsidePoints === null) {
                openSnackbar({ message: "Savings for financial emergency not selected" });
                return;
            } else if (this.state.investmentPoints === '' || this.state.investmentPoints === undefined || this.state.investmentPoints === null) {
                openSnackbar({ message: "Investment in Stock not selected" });
                return;
            } else if (this.state.taxLiabilityPoints === '' || this.state.taxLiabilityPoints === undefined || this.state.taxLiabilityPoints === null) {
                openSnackbar({ message: "Tax liability not selected" });
                return;
            } else if (this.state.investmentConfidentPoints === '' || this.state.investmentConfidentPoints === undefined || this.state.investmentConfidentPoints === null) {
                openSnackbar({ message: "Investment Confident not selected" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 3) {
            if (this.state.spentEMIPoint === '' || this.state.spentEMIPoint === undefined || this.state.spentEMIPoint === null) {
                openSnackbar({ message: "Spent on EMI not selected" });
                return;
            } else if (this.state.loan === '' || this.state.loan === undefined || this.state.loan === null) {
                openSnackbar({ message: "Loan option not selected" });
                return;
            } else if (this.state.lifeInsurancePoint === '' || this.state.lifeInsurancePoint === undefined || this.state.lifeInsurancePoint === null) {
                openSnackbar({ message: "Life Insurance is not selected" });
                return;
            } else if (this.state.medicalInsurancePoint === '' || this.state.medicalInsurancePoint === undefined || this.state.medicalInsurancePoint === null) {
                openSnackbar({ message: "Medical Insurance is not selected" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else if (this.state.activeStep === 4) {
            if (this.state.financialGoalsPoint === '' || this.state.financialGoalsPoint === undefined || this.state.financialGoalsPoint === null) {
                openSnackbar({ message: "Financial Goal not selected" });
                return;
            } else if (this.state.financialPriority === '' || this.state.financialPriority === undefined || this.state.financialPriority === null) {
                openSnackbar({ message: "Financial Goal priority not selected" });
                return;
            } else if (this.state.manageMoneyPoint === '' || this.state.manageMoneyPoint === undefined || this.state.manageMoneyPoint === null) {
                openSnackbar({ message: "Manage Money for living not selected" });
                return;
            } else if (this.state.retire === '' || this.state.retire === undefined || this.state.retire === null) {
                openSnackbar({ message: "Retire Option not selected" });
                return;
            } else if (this.state.financialAdvisorPoint === '' || this.state.financialAdvisorPoint === undefined || this.state.financialAdvisorPoint === null) {
                openSnackbar({ message: "Financial Advisor option not selected" });
                return;
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1,
                }));
            }
        } else {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
            }));
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };


    componentDidMount() {
        let user = firebase.auth().currentUser;
        if (user) {
            console.log(user.uid);
            axios.post("user/getFinancialHealthCheckup", { uid: user.uid })
                .then(result => {
                    if (result.status === 200) {
                        if (result.data.success) {
                            this.setState(result.data.result.inputs, () => {
                                this.setState({
                                    activeStep: 0
                                });
                            });
                        }
                    }
                }).catch(exception => {
                    openSnackbar({ message: exception });
                });
        }
    }

    saveData = (totalResult) => {
        let user = firebase.auth().currentUser;
        if (user) {
            axios.post("user/saveFinancialHealthCheckup",
                {
                    inputs: this.state,
                    finalScore: totalResult,
                    userId: user.uid
                })
                .then(result => {

                }).catch(exception => {
                    console.log(exception);
                });
        }
    };

    render() {
        const { classes } = this.props;
        let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'Financial Check-up', to: '' }];

        const steps = this.getSteps();
        const { activeStep } = this.state;


        const getStepContent = (step) => {
            switch (step) {
                case 0:
                    return (
                        <Grid container
                            justify="center"
                        >
                            <Grid item
                                xs={12}
                                md={10}
                                lg={7}
                                xl={6}
                            >
                                <img src={Triangle} style={{ width: '100%', height: 'auto' }} alt="Start" />
                            </Grid>
                        </Grid>
                    );
                case 1:
                    return (
                        <MuiThemeProvider theme={theme}>
                            <section className="financial-section--about">
                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Age</FormLabel>
                                    <RadioGroup
                                        aria-label="Age"
                                        name="age"
                                        value={this.state.age}
                                        onChange={this.onHandleChangeAge}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="18 -24" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="25 - 35" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="36 - 49" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="50 and above" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Marital Status</FormLabel>
                                    <RadioGroup
                                        aria-label="Marital Status"
                                        name="maritalStatus"
                                        value={this.state.maritalStatus}
                                        onChange={this.onHandleChangeMarital}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="Single" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="Married or in a domestic partnership" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="Separated or divorced" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="Widowed" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">About Work</FormLabel>
                                    <RadioGroup
                                        aria-label="About Work"
                                        name="work"
                                        value={this.state.work}
                                        onChange={this.onHandleChangeWork}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="Salaried/Professional (Full time/part time)" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="Unemployed" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="Other (Stay at home mum/dad, student)" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="Semi-retired" />
                                        <FormControlLabel value="e" control={<Radio color="primary" />} label="Retired" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Annual Income</FormLabel>
                                    <RadioGroup
                                        aria-label="Annual Income"
                                        name="income"
                                        value={this.state.income}
                                        onChange={this.onHandleChangeIncome}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="₹1 lakh - ₹5 lakh" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="₹5 lakh - ₹15 lakh" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="₹15 lakh - ₹30 lakh" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="₹30 lakh+" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Financial dependencies</FormLabel>
                                    <RadioGroup
                                        aria-label="Financial dependencies"
                                        name="finnacialDependency"
                                        value={this.state.finnacialDependency}
                                        onChange={this.onHandleChangeFDepend}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>
                            </section >
                        </MuiThemeProvider>);
                case 2:
                    return (
                        <MuiThemeProvider theme={theme}>
                            <section className="financial-section--about">
                                <div className="financial-section--questions">
                                    <FormLabel component="legend">How much of your income do you save annually?</FormLabel>
                                    <RadioGroup
                                        aria-label="Annual Income"
                                        name="saving"
                                        value={this.state.percentSavingPoints + ""}
                                        onChange={this.percentSaving}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="₹1 lakh - ₹5 lakh" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="₹5 lakh - ₹15 lakh" />
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="₹15 lakh - ₹30 lakh" />
                                        <FormControlLabel value="4" control={<Radio color="primary" />} label="₹30 lakh+" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Do you have 3-6 months of savings set aside and which can be immediately accessed for financial emergencies?</FormLabel>
                                    <RadioGroup
                                        aria-label="Do you have 3-6 months of savings set aside and which can be immediately accessed for financial emergencies?"
                                        name="financialEmergency"
                                        value={this.state.percentAsidePoints + ""}
                                        onChange={this.percentAside}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Do you have investments currently in mutual funds and/ or stocks?</FormLabel>
                                    <RadioGroup
                                        aria-label="Do you have investments currently in mutual funds and/ or stocks?"
                                        name="investment"
                                        value={this.state.investmentPoints + ""}
                                        onChange={this.investment}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Are you investing to potentially reduce your tax liabilities?</FormLabel>
                                    <RadioGroup
                                        aria-label="Are you investing to potentially reduce your tax liabilities?"
                                        name="taxLiability"
                                        value={this.state.taxLiabilityPoints + ""}
                                        onChange={this.taxLiability}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">How confident are you in your investment strategy to deal with market fluctuations, emergencies and inflation in order to meet your goals?</FormLabel>
                                    <RadioGroup
                                        aria-label="How confident are you in your investment strategy to deal with market fluctuations, emergencies and inflation in order to meet your goals?"
                                        name="investmentConfidence"
                                        value={this.state.investmentConfidentPoints + ""}
                                        onChange={this.investmentConfident}
                                    >
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="Very confident" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Somewhat confident" />
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Not so confident" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="Unsure and not confident" />
                                    </RadioGroup>
                                </div>
                            </section>
                        </MuiThemeProvider>
                    );
                case 3:
                    return (
                        <MuiThemeProvider theme={theme}>
                            <section className="financial-section--about">

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">What percentage (%) of your income is spent on EMIs?</FormLabel>
                                    <RadioGroup
                                        aria-label="What percentage (%) of your income is spent on EMIs?"
                                        name="spentEMI"
                                        value={this.state.spentEMIPoint + ""}
                                        onChange={this.spentEMIPercent}
                                    >
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="0-10%" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="11-20%" />
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="21-30%" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="30%+" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Which of the following loans do you have?</FormLabel>
                                    <RadioGroup
                                        aria-label="Which of the following loans do you have?"
                                        name="loans"
                                        value={this.state.loan}
                                        onChange={this.onHandleChangeLoan}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="Home Loan" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="Car Loan" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="Personal Loan" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="Education Loan" />
                                        <FormControlLabel value="e" control={<Radio color="primary" />} label="None" />
                                        <FormControlLabel value="f" control={<Radio color="primary" />} label="More than one" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Do you have life insurance in place of at least 10 times your annual income?( beyond any employer provided insurance)</FormLabel>
                                    <RadioGroup
                                        aria-label="Do you have life insurance in place of at least 10 times your annual income?( beyond any employer provided insurance)"
                                        name="lifeInsurance"
                                        value={this.state.lifeInsurancePoint + ""}
                                        onChange={this.lifeInsurance}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Do you have additional medical insurance cover apart from an employer provided one?</FormLabel>
                                    <RadioGroup
                                        aria-label="Do you have additional medical insurance cover apart from an employer provided one?"
                                        name="medicalInsurance"
                                        value={this.state.medicalInsurancePoint + ""}
                                        onChange={this.medicalInsurance}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>
                            </section>
                        </MuiThemeProvider>
                    );
                case 4:
                    return (
                        <MuiThemeProvider theme={theme}>
                            <section className="financial-section--about">
                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Have you set financial goals for yourself?</FormLabel>
                                    <RadioGroup
                                        aria-label="Have you set financial goals for yourself?"
                                        name="financialGoals"
                                        value={this.state.financialGoalsPoint + ""}
                                        onChange={this.financialGoals}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Choose among the following financial goals as your top priority today?</FormLabel>
                                    <RadioGroup
                                        aria-label="Choose among the following financial goals as your top priority today?"
                                        name="financialPriority"
                                        value={this.state.financialPriority}
                                        onChange={this.onHandleChangeFPrior}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="Child's education" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="Child's wedding" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="Vacation" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="Retirement" />
                                        <FormControlLabel value="e" control={<Radio color="primary" />} label="Home" />
                                        <FormControlLabel value="f" control={<Radio color="primary" />} label="Car" />
                                        <FormControlLabel value="g" control={<Radio color="primary" />} label="Self education" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">How would you cope if you had to manage on less money or if your living costs went up?</FormLabel>
                                    <RadioGroup
                                        aria-label="How would you cope if you had to manage on less money or if your living costs went up?"
                                        name="manageMoney"
                                        value={this.state.manageMoneyPoint + ""}
                                        onChange={this.manageMoney}
                                    >
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="I don't know; haven’t thought about it" />
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="I'm not sure, have made some plans but think there might be some struggle" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Fine; made some plans and will manage OK" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">When do you think you'll retire?</FormLabel>
                                    <RadioGroup
                                        aria-label="When do you think you'll retire?"
                                        name="retire"
                                        value={this.state.retire}
                                        onChange={this.onHandleChangeRetire}
                                    >
                                        <FormControlLabel value="a" control={<Radio color="primary" />} label="In less than 2 years" />
                                        <FormControlLabel value="b" control={<Radio color="primary" />} label="In 2 to 10 years" />
                                        <FormControlLabel value="c" control={<Radio color="primary" />} label="In 11 to 25 years" />
                                        <FormControlLabel value="d" control={<Radio color="primary" />} label="In more than 25 years" />
                                    </RadioGroup>
                                </div>

                                <div className="financial-section--questions">
                                    <FormLabel component="legend">Do you have a financial advisor?</FormLabel>
                                    <RadioGroup
                                        aria-label="Do you have a financial advisor?"
                                        name="retire"
                                        value={this.state.financialAdvisorPoint + ""}
                                        onChange={this.financialAdvisor}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup>
                                </div>
                            </section >
                        </MuiThemeProvider>
                    );
                default:
                    return 'Unknown step';
            }
        }

        const scoreResult = (result) => {
            if (result >= 0 && result <= 10) {
                //this.saveData(this.totalScore());

                return (
                    <Grid container
                        justify="center"
                    >
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            lg={7}
                        >
                            <img src={Result1M} className={classes.image} alt="Conservative" />
                        </Grid>
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            md={12}
                            lg={8}
                        >
                            <MuiThemeProvider theme={theme}>
                                <Typography variant="body2" align="center">
                                    <br />
                                    <br />
                                    Now that you have your financial health report, you know what needs to change. Get in touch with us on <a href="mailto:wecare@investography.in">wecare@investography.in </a>and we can begin.
                                    <br />
                                </Typography>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                );

            } else if (result >= 11 && result <= 15) {
                //this.saveData(this.totalScore());

                return (
                    <Grid container
                        justify="center"
                    >
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            lg={7}
                        >
                            <img src={Result2M} className={classes.image} alt="Moderate" />
                        </Grid>
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            md={12}
                            lg={8}
                        >
                            <MuiThemeProvider theme={theme}>
                                <Typography variant="body2" align="center">
                                    <br />
                                    <br />
                                    Now that you have your financial health report, you know what needs to change. Get in touch with us on <a href="mailto:wecare@investography.in">wecare@investography.in </a>and we can begin.
                                    <br />
                                </Typography>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                );

            } else {
                //this.saveData(this.totalScore());

                return (
                    <Grid container
                        justify="center"
                    >
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            lg={7}
                        >
                            <img src={Result3M} className={classes.image} alt="Aggressive" />
                        </Grid>
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            md={12}
                            lg={8}
                        >
                            <MuiThemeProvider theme={theme}>
                                <Typography variant="body2" align="center">
                                    <br />
                                    <br />
                                    Now that you have your financial health report, you know what needs to change. Get in touch with us on <a href="mailto:wecare@investography.in">wecare@investography.in </a>and we can begin.
                                    <br />
                                </Typography>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                );

            }
        }

        return (
            <div className={classes.container}>
                <div className={classes.breadcrumbs}>
                    <Breadcrumbs list={breadcrumbsList} />
                </div>

                <Notifier />

                <Grid container
                    className={classes.financialCheckup}
                >
                    <Grid item
                        xs={12}
                    >
                        <Typography component="h2" variant="h4" className={classes.heading} gutterBottom>Financial Check-up</Typography>
                        <Typography component="h3" variant="body2" className={classes.subheading} gutterBottom>Would you trust your doctor who offered remedies before you had a complete health check-up?</Typography>
                    </Grid>
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            justify="center"
                            xs={12}
                            lg={8}
                        >
                            <MuiThemeProvider theme={theme}>
                                <br />
                                <Typography variant="body2" align="center">
                                    Your overall financial health is comprised of many elements, from learning how to live within a budget, to building a good credit history; from controlling debt and excessive spending, to developing short and long-term financial goals and learning to invest. Sometimes, it's a lot to wrap your head around. However, you may be surprised to find that you're already in pretty good shape. Or maybe not.
                                </Typography>
                                <Typography variant="body2" align="center">
                                    Test your financial health with our quick questionnaire. This will give you an idea of your current relationship with your money and pinpoint areas where you may need more work.
                            </Typography>
                                <br />
                                <br />
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid item
                        xs={12}
                    >
                        <MuiThemeProvider theme={theme}>
                            <Stepper activeStep={activeStep} alternativeLabel id="stepper">
                                {steps.map(label => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </MuiThemeProvider>
                    </Grid>
                    <div>
                        {this.state.activeStep === steps.length ? (
                            <div>
                                {/* <div className={classes.instructions}>All steps completed</div> */}
                                <div>
                                    {scoreResult(this.totalScore())}
                                    {this.saveData(this.totalScore())}
                                </div>
                                {/* <Button onClick={this.handleReset}>Reset</Button> */}
                            </div>
                        ) : (
                                <div>
                                    <div>
                                        {getStepContent(activeStep)}
                                    </div>
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            className={classes.button}
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                        >
                                            Back
                                            </Button>
                                        <Button variant="contained"
                                            className={classes.button}
                                            onClick={this.handleNext}
                                            href="#stepper"
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </Grid>
            </div>
        );
    }
};

FinancialHealthCheckup.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(FinancialHealthCheckup);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import employer from '../../images/financialWellness1.png';
import employee from '../../images/financialWellness2.png';
import Hero from '../herohome/hero/Hero';
import ContactSmall from '../contact/contactSmall/ContactSmall';


const styles = theme => ({
    container: {
        width: '80%',
        padding: '1em 0',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    heading: {
        letterSpacing: '0.07em',
        wordSpacing: '0.1em',
    },
    sectionPadding: {
        padding: '1em 0',
    },
    sectionMargin: {
        margin: '1em',
    },
    margin: {
        margin: '1em 0',
    },
    fontSize: {
        fontSize: '18px',
    },
    bullet: {
        display: 'inline-block',
        content: '',
        webkitBorderRadius: '0.375rem',
        borderRadius: '0.375rem',
        height: '0.5rem',
        width: '0.5rem',
        marginRight: '0.5rem',
        backgroundColor: '#29B5C3',
    },
    list: {
        paddingLeft: '1.5em',
        textIndent: '-1.25em',
    },
    image: {
        width: '60%',
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            width: '70%',
            height: '100%',
        },
        [theme.breakpoints.down('md')]: {
            width: '80%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            height: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            height: '90%',
        },
    },

    backgroundBlue: {
        backgroundColor: 'rgb(35,140,170)',
    },
    backgroundOrange: {
        backgroundColor: '#EA5622',
    },
    bannerText: {
        color: '#fff',
        textAlign: 'center',
        margin: '0.5em 1.5em'
    },
    quote: {
        fontSize: '20px',
        textAlign: 'center',
        padding: '1em',
        borderTop: '1px solid #29B5C3',
        borderBottom: '1px solid #29B5C3',
    },
    quoteLink: {
        textDecoration: 'none',
        color: '#29B5C3',
    },
    quoteMargin: {
        margin: '3em auto',
    },
});

class FinancialWellness extends Component {

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
            <div>
                <Hero image="wellness.png" />
                <Grid container
                    justify="center"
                    className={classes.container}
                >
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            xs={12}
                        >
                            <Grid item
                                className={classNames(classes.sectionPadding, classes.margin)}
                                xs={12}
                                md={6}
                                lg={6}
                            >
                                <Typography variant="h5" className={classNames(classes.heading, classes.margin)} gutterBottom>
                                    Financial Wellness Solutions Personalised for Your Company
                                    </Typography>
                                <Typography variant="body1" className={classes.margin} gutterBottom>
                                    Let’s bring the fun in finance through our:
                                    </Typography>
                                <div className={classes.section}>
                                    <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                        <span className={classes.bullet}></span>Personal finance workshops covering a range of topics
                                        </Typography>
                                    <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                        <span className={classes.bullet}></span>One-to-one financial counselling
                                        </Typography>
                                    <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                        <span className={classes.bullet}></span>Customised approach to new recruits, soon to be retirees and new parents
                                        </Typography>
                                    <Typography variant="body2" className={classNames(classes.list, classes.fontSize)} gutterBottom>
                                        <span className={classes.bullet}></span>Focused solutions for women through our campaign The Equality Collective
                                </Typography>
                                </div>
                            </Grid>
                            <Grid item
                                container
                                justify="center"
                                className={classNames(classes.sectionPadding, classes.margin)}
                                xs={12}
                                md={6}
                                lg={6}
                            >
                                <img src={employer} alt="Employer Benefits" className={classes.image} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item
                        container
                        justify="center"
                        className={classes.backgroundOrange}
                        xs={12}
                    >
                        <Typography variant="subtitle1" className={classes.bannerText} gutterBottom>
                            It’s not just another tick in the box wellness exercise; financial literacy initiatives boost productivity and engagement, attracts the best talent and make business sense.
                            </Typography>
                    </Grid>

                    <Grid item
                        container
                        justify="center"
                        className={classes.quoteMargin}
                        xs={12}
                    >
                        <Grid item
                            xs={11}
                            sm={8}
                        >
                            <Typography variant="body2" className={classes.quote}>
                                <b><i>More than half (52%) of respondents from PricewaterhouseCoopers’ </i><a href="http://www.pwc.com/us/en/private-company-services/publications/financial-well-being-retirement-survey.html" target="_blank" className={classes.quoteLink}>2016 Employee Financial Wellness Survey </a><i>said they’re stressed about their overall finances; 28 per cent said their financial problems were a distraction at work.</i></b>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item
                        container
                        justify="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            xs={12}
                        >
                            <Grid item
                                container
                                justify="center"
                                className={classNames(classes.sectionPadding, classes.margin)}
                                xs={12}
                                md={6}
                                lg={6}
                            >
                                <img src={employee} alt="Employee Benefits" className={classes.image} />
                            </Grid>
                            <Grid item
                                container
                                justify="center"
                                alignItems="center"
                                className={classNames(classes.sectionPadding, classes.margin)}
                                xs={12}
                                md={6}
                                lg={6}
                            >
                                <Typography variant="subtitle1" className={classes.heading} gutterBottom>
                                    <b>Our most important financial decisions—retirement planning, health and life insurance selection, childcare planning—are often made with employer assisted programmes.</b>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item
                            xs={12}
                            className={classes.backgroundBlue}
                        >
                            <Typography variant="subtitle1" className={classes.bannerText} gutterBottom>
                                GIVE THEM MORE THAN JUST A PAYCHEQUE. GIVE THEM PEACE OF MIND
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

            </div>
            <ContactSmall title="We’d Love to Connect With You" page="Financial Wellness" />
            </React.Fragment>
        );
    }
};

FinancialWellness.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FinancialWellness);
import Event1 from '../../images/event1.jpeg';
import Event2 from '../../images/event2.jpeg';
import Event3 from '../../images/event3.jpeg';
import Event4 from '../../images/event4.jpeg';
import Event5 from '../../images/event5.jpeg';
import Event6 from '../../images/event6.jpeg';
import Event7 from '../../images/event7.jpeg';
import Event8 from '../../images/event8.jpeg';
import Event9 from '../../images/event9.jpeg';
import Event10 from '../../images/event10.jpeg';
import Event11 from '../../images/event11.jpeg';
import Event12 from '../../images/event12.jpeg';
import Event13 from '../../images/event13.jpeg';
import Event14 from '../../images/event14.jpeg';
import Event15 from '../../images/event15.jpeg';
import Event16 from '../../images/event16.jpeg';
import Event17 from '../../images/event17.jpeg';
import Event18 from '../../images/event18.jpeg';
import Event19 from '../../images/event19.jpeg';
import Event20 from '../../images/event20.jpeg';
import Event21 from '../../images/event21.jpeg';
import Event22 from '../../images/event22.jpeg';
import Event23 from '../../images/event23.jpeg';
import Event24 from '../../images/event24.jpeg';
import Event25 from '../../images/event25.jpeg';
import Event26 from '../../images/event26.jpeg';


export default [
    { src: Event1, width: 4, height: 3 },
    { src: Event2, width: 4, height: 2 },
    { src: Event3, width: 3, height: 2 },
    { src: Event4, width: 4, height: 3 },
    { src: Event5, width: 4, height: 2 },
    { src: Event6, width: 3, height: 2 },
    { src: Event7, width: 4, height: 3 },
    { src: Event8, width: 4, height: 3 },
    { src: Event9, width: 4, height: 2 },
    { src: Event10, width: 2, height: 2 },
    { src: Event11, width: 4, height: 2 },
    { src: Event12, width: 4, height: 2 },
    { src: Event13, width: 3, height: 2 },
    { src: Event14, width: 3, height: 2 },
    { src: Event15, width: 4, height: 2 },
    { src: Event16, width: 4, height: 3 },
    { src: Event17, width: 4, height: 3 },
    { src: Event18, width: 4, height: 3 },
    { src: Event19, width: 3, height: 3 },
    { src: Event20, width: 4, height: 3 },
    { src: Event21, width: 4, height: 3 },
    { src: Event22, width: 4, height: 2 },
    { src: Event23, width: 4, height: 2 },
    { src: Event24, width: 2, height: 2 },
    { src: Event25, width: 2, height: 2 },
    { src: Event26, width: 4, height: 2 },
];
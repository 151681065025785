import React, { Fragment } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import propTypes from 'prop-types';
import Video from '../media/Media';
import invest from '../../images/invest.png';
import largecap from '../../images/largecap.png';
import fathersday from '../../images/fathersday.jpg';
import balanced from '../../images/balanced.jpg';
import fathersday2 from '../../images/fathersday2.jpg';
import got from '../../images/got.jpg';
import Hero from '../herohome/hero/Hero';
import Tagline from '../taglineexcel/TaglineExcel';
import Subscribe from '../subscribe/Subscribe';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';

let breadcrumbsList = [{ name: 'Home', to: '/' }, { name: 'Media', to: '' }];

const styles = ({
    text: {
        fontSize: '14px',
        marginTop: '10px',
    },
    image: {
      width: '297px',
      height: '226px'
    }
});

const Media = props => {
    const { classes } = props
    return (
        <Fragment>
            <Hero
                image="media_hero.png"
            />
            <Grid container justify="center">
                <Grid item xs={11} sm={11} lg={9}>
                    <Grid item xs={12} sm={12} lg={12} container>
                        <Breadcrumbs list={breadcrumbsList} />
                        <Typography className={classes.text} variant="body2">We are passionate about ensuring people manage their money well so that it can take care of them. We are often sought after for our views and so, are in the media eye quite a bit. Catch us on TV, print or online. Some of our latest appearances are given below. Follow us on our social media channels to connect with us and get the latest about us.</Typography>
                    </Grid>
                    <Video />
                    <br /><br /><br />
                    <Grid container justify="center">
                        <Grid item container xs={12} sm={12} lg={12}>
                            <Typography className="heading-media" variant="h4">From Press<br /><br /></Typography>
                        </Grid>
                        <Grid item container justify="center" spacing={24} xs={12} sm={12} lg={12}>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>

                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">LiveMint - The family needs to know</Typography>
                                </Grid>
                                <div>
                                    <a href="https://economictimes.indiatimes.com/wealth/tax/best-tax-saving-option-for-fy-2018-19-heres-a-comparison-of-top-10-instruments/articleshow/67393477.cms?">
                                        <img className={ classes.image } src="https://images.livemint.com/rf/Image-621x414/LiveMint/Period2/2019/01/14/Photos/Processed/nominee-U20573096912fED--621x414@LiveMint.jpg" alt="invest" />
                                    </a>
                                </div>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Jan 19, 2019</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Economic Times - Tax Saving Investments</Typography>
                                </Grid>
                                <a href="https://economictimes.indiatimes.com/wealth/tax/best-tax-saving-option-for-fy-2018-19-heres-a-comparison-of-top-10-instruments/articleshow/67393477.cms?">
                                    <img className={ classes.image } src="https://img.etimg.com/thumb/msid-67393462,width-300,imgsize-45189,resizemode-4/tax5-getty.jpg" alt="largecaps fund" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Jan 07, 2019</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Moneycontrol - Equity Savings Funds</Typography>
                                </Grid>
                                <a href="https://www.moneycontrol.com/news/business/personal-finance/are-equity-savings-funds-worth-your-time-a-look-at-their-expense-ratios-3332991.html?fbclid=IwAR1LqXdWsW3s7xPOrqOccEOqO0XuGpm3JITNu5SAKRp1IB72KanMQ0osZzU">
                                    <img className={ classes.image } src="https://static-news.moneycontrol.com/static-mcnews/2018/03/moneymutualfunds4-770x433.jpg" alt="shweta jaina and her father" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Jan 01, 2019</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">LiveMint - Financial planning</Typography>
                                </Grid>
                                <a href="https://www.livemint.com/Money/V59VWPz6WqxEEP9S8dW9VK/Your-Money-in-2019-Financial-planning-for-a-new-beginning.html">
                                    <img className={ classes.image} src="https://images.livemint.com/rf/Image-621x414/LiveMint/Period2/2019/01/02/Photos/Processed/personal-finance-k6bF--621x414@LiveMint.jpg" alt="fathers day" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Jan 01, 2019</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">LiveMint - Cut out investing excuses</Typography>
                                </Grid>
                                <a href="https://www.livemint.com/Money/NeEOMIVW1yBXPHcC6SAG1H/Eight-excuses-that-prevent-you-from-investing-right.html">
                                    <img className={classes.image} src="https://images.livemint.com/rf/Image-621x414/LiveMint/Period2/2018/12/03/Photos/Processed/investing-kwiF--621x414@LiveMint.jpg" alt="balanced" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Dec 03, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Moneycontrol - Diversifying globally</Typography>
                                </Grid>
                                <a href="https://www.moneycontrol.com/news/business/personal-finance/want-to-make-some-smart-moves-while-investing-think-glocal-3136101.html?">
                                    <img className={ classes.image } src="https://static-news.moneycontrol.com/static-mcnews/2017/04/thinking-woman-laptop-1280X720-770x433.jpg" alt="got" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Nov 08, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Moneycontrol - Handling volatility</Typography>
                                </Grid>
                                <a href="https://www.moneycontrol.com/news/business/personal-finance/mutual-fund-lesson-what-should-you-do-in-volatile-markets-3119051.html?fbclid=IwAR0vOs-Y4OJzE2hCGXXTnGpYM9QXzU0K1OS1YruZW8pshcApSAwGM1Isklo">
                                    <img className={ classes.image } src="https://static-news.moneycontrol.com/static-mcnews/2017/05/young-man-worried-thinking-1280X720-770x433.jpg" alt="got" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Nov 02, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">LiveMint - Kickstart your start-up</Typography>
                                </Grid>
                                <a href="https://www.livemint.com/Opinion/WHxHfDTBXU1zqEXYkXR8xN/Kickstarting-your-startup-journey-Plan-Plan-Plan.html">
                                    <img className={ classes.image } src="https://images.livemint.com/rf/Image-621x414/LiveMint/Period2/2018/10/22/Photos/Processed/pppic-kPlB--621x414@LiveMint.jpg" alt="got" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Oct 22, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">LiveMint - Midcap funds</Typography>
                                </Grid>
                                <a href="https://www.livemint.com/Money/mq832RAvR5dyN1wmjxDP1N/Should-you-invest-in-midcap-mutual-funds-now.html">
                                    <img className={ classes.image } src="https://images.livemint.com/rf/Image-621x414/LiveMint/Period2/2018/07/30/Photos/Processed/stocks-kXkB--621x414@LiveMint-kSqC--621x414@LiveMint.jpg" alt="got" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">July 30, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">LiveMint - Evaluating AMC IPOs</Typography>
                                </Grid>
                                <a href="https://www.livemint.com/Money/hNHbP0bMFJEYpLFvXioXuJ/How-to-evaluate-HDFC-MF-IPO.html">
                                    <img className={ classes.image } src="https://images.livemint.com/rf/Image-621x414/LiveMint/Period2/2018/07/25/Photos/Processed/HDFCAMCIPO-kLoC--621x414@LiveMint.jpg" alt="got" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">July 25, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>

                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">ECONOMIC TIMES</Typography>
                                </Grid>
                                <div>
                                    <a href="https://economictimes.indiatimes.com/mf/analysis/new-mutual-fund-investors-are-losing-nerve-selling-their-investments/articleshow/64570949.cms">
                                        <img src={invest} alt="invest" />
                                    </a>
                                </div>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Jun 13, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">ECONOMIC TIMES</Typography>
                                </Grid>
                                <a href="https://economictimes.indiatimes.com/mf/analysis/tone-down-your-return-expectations-from-largecap-mutual-funds-say-advisors/articleshow/64777439.cms">
                                    <img src={largecap} alt="largecaps fund" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Jun 13, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Bank Bazaar</Typography>
                                </Grid>
                                <a href="https://blog.bankbazaar.com/be-your-dads-financial-hero-this-fathers-day/">
                                    <img src={fathersday} alt="shweta jaina and her father" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">June 17, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Bank Bazaar</Typography>
                                </Grid>
                                <a href="https://economictimes.indiatimes.com/mf/analysis/tone-down-your-return-expectations-from-largecap-mutual-funds-say-advisors/articleshow/64777439.cms">
                                    <img src={fathersday2} alt="fathers day" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">June 17, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">India.com</Typography>
                                </Grid>
                                <a href="http://www.india.com/business/balanced-funds-reduce-dividend-payout-after-fall-in-stock-markets-3122503/">
                                    <img src={balanced} alt="balanced" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">July 16, 2018</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container justify="center" xs={12} sm={6} lg={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="text-align">Money Control</Typography>
                                </Grid>
                                <a href="https://www.moneycontrol.com/news/business/personal-finance/winter-is-coming-financial-advice-that-will-help-you-win-the-game-of-goals-2382389.html">
                                    <img src={got} alt="got" />
                                </a>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-align">Sep 08, 2017</Typography>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <br /><br /><br />
            <Tagline />
            <Subscribe />
        </Fragment>
    );

}

Media.propTypes = {
    classes: propTypes.object.isRequired,
};

export default withStyles(styles)(Media);

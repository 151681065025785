import React, { Component } from 'react';
import axios from '../../axios/axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';
import Slide from '@material-ui/core/Slide';
import Notifier, { openSnackbar } from '../utility/Notifier';
import './admin.css';


const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});

class FetchEvent extends Component {

    state = {
        events: [],
        token: sessionStorage.getItem('jwtToken'),
        open: false,
        event: null
    }


    handleClickOpen = (event) => {
        this.setState({
            open: true,
            event: event
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        axios.get("admin/getEvents", { headers: { authorization: `Bearer ${this.state.token}` } })
            .then(result => {
                if (result.status === 200) {
                    if (result.data.success) {
                        this.setState(() => ({
                            events: result.data.events
                        }))
                    }
                } /* else {
                    window.Materialize.toast(result.data.msg, 3000);
                    
                    this.props.history.push("/");
                    return <Redirect to="/" />
                } */
            }).catch(exception => {
                openSnackbar({ message: exception });
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }}
                    />
                </div>
                <div id="fetchEvents">
                    <Notifier />
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell>Sr. no</CustomTableCell>
                                    <CustomTableCell>City</CustomTableCell>
                                    <CustomTableCell>Title</CustomTableCell>
                                    <CustomTableCell>Date</CustomTableCell>
                                    <CustomTableCell>Duration</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.events.map((event, index) => {
                                    return <TableRow className={classes.row} key={event.id}>

                                        <CustomTableCell>{index + 1}</CustomTableCell>
                                        <CustomTableCell>{event.eventCity}</CustomTableCell>
                                        <CustomTableCell onClick={() => this.handleClickOpen(event)}>{event.eventTitle}
                                        </CustomTableCell>
                                        <CustomTableCell>{event.eventDate}</CustomTableCell>
                                        <CustomTableCell>{event.duration}</CustomTableCell>

                                    </TableRow>
                                })}
                                {this.state.event !== null ?
                                    <Dialog
                                        open={this.state.open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={this.handleClose}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle id="scroll-dialog-title">Event Details</DialogTitle>
                                        <DialogContent>

                                            <div className="modal-content">
                                                <p><b>Title: </b>{this.state.event.eventTitle}</p>

                                                <p><b>Description: </b>{this.state.event.eventDescription}</p>

                                                <p><b>Key Note Speaker: </b>{this.state.event.keyNoteSpeaker}</p>

                                                <p><b>Venue: </b>{this.state.event.venue}</p>

                                                <p><b>Start Time: </b>{this.state.event.startTime}</p>

                                                <button className="modalFetchEventBT addButton" onClick={() => {
                                                    sessionStorage.setItem('event', JSON.stringify(this.state.event));
                                                    this.props.history.push("/dashboard/updateEvent");
                                                    return <Redirect to={{
                                                        pathname: "/dashboard/updateEvent"
                                                    }} />
                                                }}>Update</button>

                                                <button className="modalFetchEventBT addButton" onClick={() => {
                                                    axios.get('admin/deleteEvent/' + this.state.event.id,
                                                        { headers: { authorization: `Bearer ${this.state.token}` } })
                                                        .then(result => {
                                                            if (result.status === 200) {
                                                                if (result.data.success) {
                                                                    this.componentDidMount();
                                                                    this.setState({
                                                                        open: false
                                                                    });
                                                                    openSnackbar({ message: result.data.msg });
                                                                } else {
                                                                    openSnackbar({ message: result.data.msg });
                                                                }
                                                            }
                                                        }).catch(exception => {
                                                            openSnackbar({ message: exception });
                                                        });
                                                }}>Delete</button>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} color="primary">
                                                Cancel</Button>
                                        </DialogActions>
                                    </Dialog>
                                    : null}
                            </TableBody>
                        </Table>
                    </Paper>
                    <button className="addButton" onClick={() => {
                        this.props.history.push("/dashboard/saveEvent");
                        return <Redirect to="/dashboard/saveEvent" />
                    }}>Add Event</button>
                </div>
            </div>

        );
    }
}

FetchEvent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FetchEvent);

const cleanUrl = url => {
  let str = url
  str = str.toLowerCase().replace(/[^A-Z0-9]/ig, '-')
  return str;
}

const getQueryStringValue = (key) => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export {
  cleanUrl,
  getQueryStringValue
}
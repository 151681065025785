import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './UserProfile.css';
import retirementPlanner from '../../images/retirement.png';
import moneyMaps from '../../images/moneymaps.png';
import riskProfile from '../../images/riskprofile.png';
import financialHealth from '../../images/financialhealth.png';
import advert from '../../images/advert.png';
import { firebase } from '../../firebase/firebase';
import Notifier, { openSnackbar } from '../utility/Notifier';


const styles = theme => ({
    textField: {
        width: '92%',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    button: {
        display: 'block',
        width: '92%',
        color: '#fff',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        textAlign: 'center',
        letterSpacing: '0.4em',
        marginTop: '2em',
        marginBottom: '1.2em',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    color: {
        color: '#fff',
    },
});

class UserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            contact: "",
            dateOfBirth: "",
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    }

    profileForm = () => {
        const profileForm = document.getElementById('profileForm');
        profileForm.classList.remove('hidden');

        const changePasswordForm = document.getElementById('changePasswordForm');
        changePasswordForm.classList.add('hidden');

        const accountForm = document.getElementById('accountForm');
        accountForm.classList.add('hidden');
    };

    changePasswordForm = () => {
        const changePasswordForm = document.getElementById('changePasswordForm');
        changePasswordForm.classList.remove('hidden');

        const profileForm = document.getElementById('profileForm');
        profileForm.classList.add('hidden');

        const accountForm = document.getElementById('accountForm');
        accountForm.classList.add('hidden');
    };

    accountForm = () => {
        const accountForm = document.getElementById('accountForm');
        accountForm.classList.remove('hidden');

        const profileForm = document.getElementById('profileForm');
        profileForm.classList.add('hidden');

        const changePasswordForm = document.getElementById('changePasswordForm');
        changePasswordForm.classList.add('hidden');
    };

    name = (e) => {
        e.preventDefault();
        const name = e.target.value;
        this.setState(() => ({ name }));
    };

    email = (e) => {
        e.preventDefault();
        const email = e.target.value;
        this.setState(() => ({ email }));
    };

    contact = (e) => {
        e.preventDefault();
        const contact = e.target.value;
        this.setState(() => ({ contact }));
    };

    dateOfBirth = (e) => {
        e.preventDefault();
        const dateOfBirth = e.target.value;
        this.setState(() => ({ dateOfBirth }));
    };

    currentPassword = (e) => {
        e.preventDefault();
        const currentPassword = e.target.value;
        this.setState(() => ({ currentPassword }));
    };

    newPassword = (e) => {
        e.preventDefault();
        const newPassword = e.target.value;
        this.setState(() => ({ newPassword }));
    };

    confirmPassword = (e) => {
        e.preventDefault();
        const confirmPassword = e.target.value;
        this.setState(() => ({ confirmPassword }));
    };

    profileFormValidation = (e) => {
        e.preventDefault();
        const name = this.state.name;
        const email = this.state.email;
        const contact = this.state.contact;
        const dateOfBirth = this.state.dateOfBirth;
        let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let contactFormat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (name === null || name === "") {
            openSnackbar({ message: "Please enter the name" });
        } else if (name.length < 3) {
            openSnackbar({ message: "Name should be atleast 3 characters" });
        } else if (email === null || email === "") {
            openSnackbar({ message: "Email is required" });
        } else if (!email.match(emailFormat)) {
            openSnackbar({ message: "Please enter a valid email address" });
        } else if (contact === null || contact == "") {
            openSnackbar({ message: "Contact is required" });
        } else if (!contact.match(contactFormat)) {
            openSnackbar({ message: "Please enter valid contact number" });
        } else {

            var user = firebase.auth().currentUser;
            if (user) {
                // User is signed in.
                user.updateProfile({
                    displayName: this.state.name
                }).then(result => {
                    user.updateEmail(this.state.email)
                        .then(result => {
                            user.updatePhoneNumber(this.state.contact)
                                .then(result => {
                                    openSnackbar({ message: 'Successfully updated' });
                                }).catch(exception => {
                                    openSnackbar({ message: 'Contact didn\'t updated' });
                                });
                        }).catch(exception => {
                            openSnackbar({ message: 'Email didn\'t updated' });
                        });
                }).catch(exception => {
                    console.log(exception);
                    openSnackbar({ message: 'Something went wrong' });
                });
            } else {
                // No user is signed in.
            }
        }
    };

    passwordFormValidation = (e) => {
        e.preventDefault();
        const currentPassword = this.state.currentPassword;
        const newPassword = this.state.newPassword;
        const confirmPassword = this.state.confirmPassword;
        /* let passwordFormat = /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@&]?)(?!.*[iIoO])\S{8,30}$/;
        if (currentPassword === null || currentPassword === "") {
            openSnackbar({ message: "Please enter your current password" });
        } else if (newPassword === null || newPassword === "") {
            openSnackbar({ message: "New password is required" });
        } else if (!newPassword.match(passwordFormat)) {
            openSnackbar({ message: "Password should be atleast 8 to 30 characters long and must contain atleast one Uppercase, one Lowercase and one number" });
        } else if (confirmPassword !== newPassword) {
            openSnackbar({ message: "Confirm password should match New password" });
        } */
        if (currentPassword === null || currentPassword === "") {
            openSnackbar({ message: "Please enter your current password" });
        } else if (newPassword === null || newPassword === "") {
            openSnackbar({ message: "New password is required" });
        } else if (confirmPassword !== newPassword) {
            openSnackbar({ message: "Confirm password should match New password" });
        } else {

            var user = firebase.auth().currentUser;
            if (user) {
                // User is signed in.
                user.updatePassword(this.state.newPassword).then(function () {
                    // Update successful.
                    openSnackbar({ message: 'Password successfully changed' });
                }).catch(function (error) {
                    // An error happened.
                    openSnackbar({ message: 'Something went wrong' });
                });
            } else {
                // No user is signed in.
            }
        }
    };

    getUserDetails = () => {
        let user = firebase.auth().currentUser;
        if (user) {
            this.setState({
                name: user.displayName,
                email: user.email,
                contact: user.phoneNumber,
            });
        }
    }

    componentDidMount() {
        this.getUserDetails();
    }

    result = (e) => {
        e.preventDefault();
    };

    render() {
        const { classes } = this.props;
        return (
            <div className="user">
                <Notifier />
                <div className="user__container">
                    <Grid container>
                        <Grid item
                            xs={12}
                            md={3}
                            lg={3}
                        >
                            <div className="navigation">
                                <div className="navigation__nav">
                                    <a href="#" className="navigation__nav--link" onClick={this.profileForm}>Profile</a>
                                    <a href="#" className="navigation__nav--link" onClick={this.changePasswordForm}>Change Password</a>
                                    <a href="#" className="navigation__nav--link" onClick={this.accountForm}>Transact</a>
                                </div>
                                <div className="navigation__advert">
                                    <img src={advert} alt="Advertisement" className="navigation__advert--image" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item
                            className="form"
                            xs={12}
                            md={9}
                            lg={9}
                        >
                            <Grid container
                                justify="center"
                            >
                                {/* Edit Profile form */}
                                <Grid item
                                    id="profileForm"
                                    xs={12}
                                    md={8}
                                    lg={8}
                                >
                                    <Grid container justify="center">
                                        <form onSubmit={this.profileFormValidation}>
                                            <Grid item>
                                                <TextField
                                                    id="filled-name"
                                                    label="Name"
                                                    className={classes.textField}
                                                    value={this.state.name}
                                                    onChange={this.name}
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="filled-email-input"
                                                    label="Email"
                                                    className={classes.textField}
                                                    value={this.state.email}
                                                    onChange={this.email}
                                                    type="email"
                                                    name="email"
                                                    autoComplete="email"
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="filled-number"
                                                    label="Contact"
                                                    className={classes.textField}
                                                    value={this.state.contact}
                                                    onChange={this.contact}
                                                    type="number"
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button type="submit" variant="contained" className={classes.button}>
                                                    Save
                                            </Button>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Change Password form */}
                            <Grid container
                                justify="center"
                            >
                                <Grid item
                                    id="changePasswordForm"
                                    className="hidden"
                                    xs={12}
                                    md={8}
                                    lg={8}
                                >
                                    <Grid container justify="center">
                                        <form onSubmit={this.passwordFormValidation}>
                                            <Grid item>
                                                <TextField
                                                    id="filled-password-input"
                                                    label="New Password"
                                                    className={classes.textField}
                                                    value={this.state.newPassword}
                                                    onChange={this.newPassword}
                                                    type="password"
                                                    autoComplete="new-password"
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="filled-password-input"
                                                    label="Confirm Password"
                                                    className={classes.textField}
                                                    value={this.state.confirmPassword}
                                                    onChange={this.confirmPassword}
                                                    type="password"
                                                    autoComplete="confirm-password"
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button type="submit" variant="contained" className={classes.button}>
                                                    Update
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Account form */}
                            <Grid container
                                justify="center"
                            >
                                <Grid item
                                    id="accountForm"
                                    className="hidden"
                                    xs={12}
                                    md={8}
                                    lg={8}
                                >
                                    <form>
                                        <Grid container>
                                            <Grid item
                                                className="app-icon"
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                onClick={() => {
                                                    this.props.history.push("/retirement-planner");
                                                }}
                                            >
                                                <img src={retirementPlanner} alt="retirement planner app" className="app-icon__icon" />
                                                <span className="app-icon__name">Retirement Planner</span>
                                            </Grid>
                                            <Grid item
                                                className="app-icon"
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                onClick={() => {
                                                    this.props.history.push("/moneyMap");
                                                }}
                                            >
                                                <img src={moneyMaps} alt="Money Maps app" className="app-icon__icon" />
                                                <span className="app-icon__name">Money Maps</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item
                                                className="app-icon"
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                onClick={() => {
                                                    this.props.history.push("/riskProfileResult");
                                                }}
                                            >
                                                <img src={riskProfile} alt="Risk Profile app" className="app-icon__icon" />
                                                <span className="app-icon__name">Risk Profile</span>
                                            </Grid>
                                            <Grid item
                                                className="app-icon"
                                                xs={6}
                                                md={6}
                                                lg={6}
                                                onClick={() => {
                                                    this.props.history.push("/financialHealthResult");
                                                }}
                                            >
                                                <img src={financialHealth} alt="Financial Health Checkup app" className="app-icon__icon" />
                                                <span className="app-icon__name">Financial Health Checkup</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="app-transaction">
                                            <Grid item>
                                                <h3 className="app-transaction--heading">Transaction</h3>
                                            </Grid>
                                            <Grid item
                                                className="app-transaction--links"
                                            >
                                                <a href="http://fundnow.investography.in/" target="_blank" className="app-transaction--links-link">Invest Now</a>
                                                <a href="http://fundnow.investography.in/" target="_blank" className="app-transaction--links-link">View Portfolio</a>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
};


UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserProfile);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import NavbarLink from './NavbarLink';
import NavbarLinkMobile from './NavbarLinkMobile';
import Investography from '../../images/brain.png';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';
import LoginContainer from '../account/LoginContainer';
import GetStartedButton from '../account/GetStartedButton';

const Brand = <a href="/"><img src={ Investography } alt="investography logo" /></a>

const styles = theme => ({
    navbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    navbar: {
        display: 'flex',
    },
    navbarItems: {
        display: 'flex',
        alignItems: 'center',
    },
    scrolled: {
        top: '-50px',
    },
});

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            previousScroll: window.pageYOffset,
        };
    };

    toggleDrawer = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    animateNavbar = () => {
        const { classes } = this.props;
        let currentScroll = window.pageYOffset;
        if(this.state.previousScroll < currentScroll) {
            document.body.getElementsByTagName("header")[0].classList.add(classes.scrolled);
        } else {
            document.body.getElementsByTagName("header")[0].classList.remove(classes.scrolled);
        }
        this.setState({ previousScroll: currentScroll });
    };

    componentDidMount() {
        window.addEventListener("scroll", this.animateNavbar);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.animateNavbar);
    }

    render() {
        const { classes } = this.props;
        return (
            <AppBar>
                <Toolbar className={ classes.navbarContainer }>
                    <div className={ classes.navbar }>
                        <div className={ classes.navbarItems }>
                            <Hidden only={[ 'lg', 'xl']}>
                                <IconButton
                                    color="primary"
                                    aria-label="open drawer"
                                    onClick={ this.toggleDrawer }
                                >
                                    <Menu />
                                </IconButton>
                            </Hidden>
                            { Brand }
                            <Hidden only={[ 'xs', 'sm', 'md' ]}>
                                <NavbarLink />
                            </Hidden>
                        </div>
                    </div>
                    <div className={ classes.button }>
                        <GetStartedButton />
                        <LoginContainer />
                    </div>
                </Toolbar>
                <Hidden only={[ 'xl', 'lg' ]}>
                    <NavbarLinkMobile
                        open={ this.state.mobileOpen }
                        onClose={ this.toggleDrawer }
                    />
                </Hidden>
            </AppBar>
        );
    }
};

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navbar);
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Default from '../../images/default.jpg';
import Header from './Header';
import axios from '../../axios/axios'
import { withRouter } from 'react-router-dom';
import { cleanUrl, getQueryStringValue } from '../../functions'


const styles = theme => ({
  container: {
    marginTop: '3rem',
    padding: '0 15px',
    [theme.breakpoints.up('lg')]: {
      width: '885px',
      padding: '0',
      margin: '3rem auto 0 auto'
    }
  },
  item1: {
    '& h2': {
      fontFamily: 'Playfair Display, serif',
      fontSize: '36px',
      fontWeight: 400,
      marginBottom: '20px'
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '27px',
      letterSpacing: '0.5px',
      marginBottom: '45px'
    },
    '& .menu': {
      borderLeft: '2px solid #CBC8C5',
      marginBottom: '60px',
      '&>div': {
        marginTop: '30px',
        '&:nth-of-type(1)': {
          marginTop: '0'
        }
      },
      '& span': {
        display: 'inline-block',
        color: 'hsl(20,4%,16%)',
        textDecoration: 'none',
        textTransform: 'uppercase',
        marginLeft: '20px',
        '&:hover': {
          color: '#29B5C3',
          cursor: 'pointer'
        },
      },
      '& .active': {
        color: '#29B5C3'
      }
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '350px',
    }
  },
  item2: {
    cursor: 'pointer',
    '& .banner': {
      marginBottom: '35px',
      '& img': {
        width: '100%',
        marginBottom: '25px'
      },
    },
    '& span': {
      display: 'inline-block',
      color: '#29B5C3',
      textDecoration: 'none',
      textTransform: 'uppercase'
    },
    '& h4, & h5': {
      fontFamily: 'Playfair Display, serif',
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 400,
      marginTop: '10px'
    },
    '& h5': {
      fontSize: '18px',
      lineHeight: 1.33,
      cursor: 'pointer'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '500px'
    }
  },
  articles: {
    marginTop: '60px',
    '&:not(:first-child)': {
      borderTop: '1px solid #CBC8C5',
    },
    '& .article': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '20px 0',
      borderBottom: '1px solid #CBC8C5',
      '& figure': {
        width: '90px',
        height: '90px',
        marginRight: '15px',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }
      },
      '&>div': {
        width: 'calc(100% - 90px)'
      }
    }
  }
})

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],

      blogId: "",
      bannerImage: Default,
      bannerLink: 'Disrupt Money',
      bannerText: 'A Quick History of Women, Voting, and Equality',

      selectedSection: "",
      section: {},
      articles: [],
      selectedId: ""
    }
  }

  componentDidMount() {
    this.fetchAllSection();
  }

  getSection = (section) => {
    return section.id === getQueryStringValue("section");
  }

  componentDidUpdate() {
    if (this.state.selectedSection !== getQueryStringValue("section")) {
      this.setState({
        section: this.state.sections.find(this.getSection),
        selectedSection: getQueryStringValue("section")
      }, () => {
        this.fetchBlogBySection();
      });
    }
  }

  fetchAllSection = () => {
    axios.get('blog/fetchSection')
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({
              sections: result.data.sections,
              section: result.data.sections.find(this.getSection),
              selectedSection: getQueryStringValue("section")
            }, () => {
              this.fetchBlogBySection();
            });
          } else {
            // openSnackbar({ message: result.data.msg })
          }
        } else {
          // openSnackbar({ message: result.data.msg })
        }
      }).catch(exception => {
        // openSnackbar({ message: exception.message });
      });
  }

  fetchBlogBySection = () => {
    axios.post('blog/bySection', {
      section: this.state.selectedSection,
      offset: 0,
      limit: 50
    })
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({
              articles: result.data.blogs,
              bannerImage: result.data.blogs[0] ? result.data.blogs[0].imageURL : Default,
              bannerLink: result.data.blogs[0] ? result.data.blogs[0].sectionName : "",
              bannerText: result.data.blogs[0] ? result.data.blogs[0].title : "",
              blogId: result.data.blogs[0] ? result.data.blogs[0].id : ""
            });
          } else {
            // openSnackbar({ message: result.data.msg })
          }
        } else {
          // openSnackbar({ message: result.data.msg })
        }
      }).catch(exception => {
        // openSnackbar({ message: exception.message });
      });
  }

  viewBlogDetailsPage = (title, id) => {
    const URL = `/magazine/blog-details?title=${cleanUrl(title)}&blog=${id}`
    this.props.history.push(URL);
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Header />
        <Grid container justify="space-between" alignItems="flex-start" className={classes.container}>
          <Grid item container xs={12} lg={5} className={classes.item1}>
            <h2>{this.state.section ? this.state.section.name : ""}</h2>
            <p>{this.state.section ? this.state.section.description : ""}</p>
            <Grid item xs={12} className="menu">
              {this.state.sections.map(link => {
                return (
                  <div key={link.id}>
                    <span className={this.state.selectedSection === link.id ? 'active' : ''} onClick={() => {
                      const URL = `/magazine/section?section=${link.id}`
                      this.props.history.push(URL);
                    }}>{link.name}</span>
                  </div>
                )
              })}
            </Grid>
          </Grid>
          <Grid onClick={() => {
            this.setState({
              selectedId: this.state.blogId
            }, () => {
              this.viewBlogDetailsPage(this.state.bannerText, this.state.selectedId);
            });
          }} item container xs={12} lg={7} className={classes.item2}>
            <Grid item xs={12} className="banner">
              <img src={this.state.bannerImage} alt="" />
              <div>
                <span>{this.state.bannerLink}</span>
                <h4>{this.state.bannerText}</h4>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.articles}>
              {
                this.state.articles.slice(1).map(article => {
                  return (
                    <article key={article.id} className="article">
                      <figure>
                        <img src={article.imageURL} alt="" />
                      </figure>
                      <div onClick={() => {
                        this.viewBlogDetailsPage(article.title, article.id);
                      }}>
                        <span>{article.sectionName}</span>
                        <h5>{article.title}</h5>
                      </div>
                    </article>
                  )
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

Category.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(Category));
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Notifier, { openSnackbar } from '../utility/Notifier'
import axios from '../../axios/axios';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQueryStringValue } from '../../functions'
import Header from './Header'

const styles = theme => ({
  container: {
    marginTop: '3rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    maxWidth: '775px',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    }
  },
  heading: {
    margin: '50px 0',
    '& h6': {
      color: '#29B5C3',
      textDecoration: 'none',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      letterSpacing: '2px',
    },
    '& h1': {
      fontSize: '42px',
      fontFamily: 'Playfair Display,serif',
      lineHeight: 1,
      letterSpacing: 'normal',
      margin: '10px 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      }
    },
  },
  author: {
    display: 'flex',
    alignsItems: 'flex-start',
    marginTop: '30px',
    marginBottom: '50px',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& img': {
      width: '65px',
      marginRight: '25px',
      maxWidth: '100%',
      borderRadius: '50%',
    },
    '& div': {
      '& h5': {
        fontSize: '15px',
        letterSpacing: '2px',
        lineHeight: 1.5,
      }
    }
  },
  socialLinks: {
    position: 'sticky',
    top: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      fontSize: '2rem',
      margin: '5px 0'
    }
  },
  content: {
    fontFamily: 'Merriweather, serif',
    fontWeight: 300,
    lineHeight: 1.8,
    letterSpacing: '.5px',
    '& p': {
      margin: '20px 0',
      '& img': {
        display: 'block',
        width: '75%',
        margin: '0 auto'
      },

    },
    '& blockquote': {
      padding: '15px',
      background: '#34495E',
      color: '#fff',
      borderRadius: '10px',
      margin: '20px 0',
    },
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '10px',
    }
  },
  facebook: {
    color: '#4267b2',
  },
  twitter: {
    color: '#38A1F3',
  },
  linkedin: {
    color: '#0077B5',
  },
  youtube: {
    color: '#ED3833',
  },
  instagram: {
    color: '#e1306c',
  },
  socialBottom: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '40px',

    '& a': {
      marginRight: '15px',
      fontSize: '1.5rem',
    }
  },
  authorBottom: {
    display: 'grid',
    gridTemplateColumns: '75px 1fr',
    gridTemplateRows: '2',
    gridGap: '15px',
    '& img': {
      width: '75px',
      height: '75px',
      flexShrink: 0,
      objectFit: 'cover',
      [theme.breakpoints.up('lg')]: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 3,
      }
    },
    '& .authorName': {
      '& h5': {
        display: 'inline-block',
      },
      '& a': {
        marginLeft: '20px',
      }
    },
    '& .aboutAuthor': {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2,
      [theme.breakpoints.up('lg')]: {
        gridColumnStart: 2,
      }
    },
  }
})

class BlogDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      blog: {},
      fetched: false
    }
  }

  componentDidMount() {
    axios.get('blog/blog', {
      params: {
        blog: getQueryStringValue("blog")
      }
    }).then(result => {
      if (result.status === 200) {
        if (result.data.success) {
          console.info(result.data.blog.author);
          this.setState({
            blog: result.data.blog,
            fetched: true
          });
        } else {
          openSnackbar({ message: result.data.msg });
        }
      } else {
        openSnackbar({ message: result.data.msg });
      }
    }).catch(exception => {
      openSnackbar({ message: exception.message });
    });
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Header />
        <Notifier />
        {
          this.state.fetched ?
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.heading}>
                <h6>{this.state.blog.sectionName}</h6>
                <h1>{this.state.blog.title}</h1>
                <div className={classes.author}>
                  <img src={this.state.blog.author.imageUrl} alt="coffee" />
                  <div>
                    <h5>{this.state.blog.author.name}</h5>
                    <i><time date="2018-03-27">{moment(this.state.blog.createdAt).format('LL')}</time></i>
                  </div>
                  <a style={{ fontSize: '1.5rem', marginRight: '20px', marginLeft: '20px' }} className={classes.twitter} target="_blank" rel="noopener noreferrer" href={this.state.blog.author.twitter}><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                  <a style={{ fontSize: '1.5rem', marginRight: '20px' }} className={classes.linkedin} target="_blank" rel="noopener noreferrer" href={this.state.blog.author.linkedin}><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                </div>
              </Grid>

              <Grid item container>
                <Grid item xs={2}>
                  <div className={classes.socialLinks}>
                    <a className={classes.facebook} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/investography.pvt.ltd/"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
                    <a className={classes.twitter} data-dnt="true" data-text="Hi! I found this post interesting on Investography" data-show-count="false" href="https://twitter.com/share?ref_src=twsrc%5Etfw"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                  </div>
                </Grid>
                <Grid item xs={10} className={classes.content}>
                  <Grid item xs={12} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ maxWidth: '100%' }} src={this.state.blog.imageURL} alt="" />
                  </Grid>
                  {
                    <div dangerouslySetInnerHTML={{ __html: this.state.blog.content }} />
                  }
                </Grid>
                <Grid item xs={12} className={classes.socialBottom} >
                  <div>
                    <a className={classes.facebook} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/investography.pvt.ltd/"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
                    <a className={classes.twitter} target="_blank" rel="noopener noreferrer" href="https://twitter.com/i4investography?lang=en"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                  </div>
                </Grid>
                <Grid className={classes.authorBottom} item xs={12}>
                  <img src={this.state.blog.author.imageUrl} alt="" />
                  <div className="authorName">
                    <h5>{this.state.blog.author.name}</h5>
                    <a className={classes.twitter} target="_blank" rel="noopener noreferrer" href={this.state.blog.author.twitter}><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                    <a className={classes.linkedin} target="_blank" rel="noopener noreferrer" href={this.state.blog.author.linkedin}><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                  </div>
                  <div className="aboutAuthor">
                    <p>{this.state.blog.author.description}</p>
                  </div>
                </Grid>
              </Grid>

            </Grid>
            : <p>Loading...</p>
        }
      </React.Fragment>
    )
  }
}

BlogDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BlogDetails)
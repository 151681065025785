import React, { Component, Fragment } from 'react';
import AdminNav from './AdminNav';
import axios from '../../axios/axios';
import Notifier, { openSnackbar } from '../utility/Notifier';
import './admin.css';

class Testimonial extends Component {
    state = {
        token: sessionStorage.getItem('jwtToken'),
        testimonial: '',
        name: '',
        by: ''
    }

    onTestimonialTextChange = (e) => {
        let val = e.target.value;
        this.setState({
            testimonial: val
        });
    }

    onNameChange = (e) => {
        let val = e.target.value;
        this.setState({
            name: val
        });
    }

    onByChange = (e) => {
        let val = e.target.value;
        this.setState({
            by: val
        });
    }

    onBackPressed = () => {
        this.props.history.goBack();
    }

    addNew = (e) => {
        e.preventDefault();
        axios.post('admin/saveTestimonial', {
            testimonial: this.state.testimonial,
            name: this.state.name,
            by: this.state.by
        }, { headers: { authorization: `Bearer ${this.state.token}` } }).then(result => {
            if (result.status === 200) {
                if (result.data.success) {
                    openSnackbar({ message: result.data.msg });
                    this.props.history.goBack();
                } else {
                    openSnackbar({ message: result.data.msg });
                }
            } else {
                openSnackbar({ message: 'Something went wrong' });
            }
        }).catch(exception => {
            openSnackbar({ message: exception });
        });
    }

    render() {
        return (
            <Fragment>
                <div className="containeradmin">
                    <div className="admin-sidebar">
                        <AdminNav
                            logout={() => {
                                sessionStorage.clear('jwtToken');
                                this.props.history.push("/admin");
                            }}
                            dashboard={() => {
                                this.props.history.push("/dashboard");
                            }}
                        />
                    </div>
                    <Notifier />
                    <div id="saveForm">
                        <div className="container">
                            <h4 style={{ textAlign: 'center' }}>Add Testimonial</h4>
                            <br />
                            <form onSubmit={this.addNew}>
                                <div className="form">
                                    <div className="gridFull labelInput">
                                        <label>Name</label>
                                        <input type="text" maxLength="40" onChange={this.onNameChange} />
                                    </div>
                                    <div className="labelInput">
                                        <label>Designation/Company</label>
                                        <input type="text" maxLength="50" onChange={this.onByChange} />
                                    </div>
                                    <div className="gridFull labelInput">
                                        <label>Testimonial</label>
                                        <textarea rows="5" maxLength="200" onChange={this.onTestimonialTextChange}></textarea>
                                    </div>
                                    <br></br>
                                    <button type="button" onClick={this.onBackPressed}>Back</button>
                                    <button type="submit">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Testimonial;
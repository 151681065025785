import React, { Component } from 'react';
import axios from '../../axios/axios';
import './admin.css';
import AdminNav from './AdminNav';
import { Redirect } from 'react-router-dom';
import Notifier, { openSnackbar } from '../utility/Notifier';


function toTimeStampDate(stringDate) {
    var myDate = stringDate;
    console.log(myDate);
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[2] + "/" + myDate[0];
    return newDate;
}

class SaveEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventDate: '',
            eventTitle: '',
            eventDescription: '',
            venue: '',
            eventCity: '',
            startTime: '',
            duration: '',
            keyNoteSpeaker: '',
            token: sessionStorage.getItem('jwtToken')
        }
    }

    handleChangeTitle = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            eventTitle: val
        }));
    }

    handleChangeCity = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            eventCity: val
        }));
    }

    handleChangeVenue = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            venue: val
        }));
    }

    handleChangeDate = (e) => {
        let val = e.target.value;
        this.setState({
            eventDate: toTimeStampDate(val)
        });
    }

    handleChangeDuration = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            duration: val
        }));
    }

    handleChangeStartTime = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            startTime: val
        }));
    }

    handleChangeSpeaker = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            keyNoteSpeaker: val
        }));
    }

    handleChangeDescription = (e) => {
        let val = e.target.value;
        this.setState(() => ({
            eventDescription: val
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.eventTitle === null || this.state.eventTitle === '') {
            openSnackbar({ message: "Please enter event title" });
        } else if (this.state.eventCity===null || this.state.eventCity === '') {
            openSnackbar({ message: "Please enter event city" });
        } else {
            axios.post('admin/saveEvent', this.state,
                { headers: { authorization: `Bearer ${this.state.token}` } })
                .then(result => {
                    if (result.status === 200) {
                        if (result.data.success) {
                            this.props.history.goBack();
                            openSnackbar({ message: result.data.msg });
                        } else {
                            openSnackbar({ message: result.data.msg });
                        }
                    }
                }).catch(exception => {
                    openSnackbar({ message: 'Something went wrong' });
                });
        }
    }

    render() {
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }} />
                </div>
                <div id="saveForm">
                    <div className="container">
                        <h4 style={{ textAlign: 'center' }}>Add New Event</h4>
                        <br />
                        <Notifier />
                        <form onSubmit={this.handleSubmit}>
                            <div className="form">
                                <div className="gridFull labelInput">
                                    <label>Event Title</label>
                                    <input type="text" name="title" maxLength="100" onChange={this.handleChangeTitle} />
                                </div>
                                <div className="labelInput">
                                    <label>Event City</label>
                                    <input type="text" name="eventCity" maxLength="30" onChange={this.handleChangeCity} />
                                </div>
                                <div className="labelInput">
                                    <label>Event Venue</label>
                                    <input type="text" name="venue" maxLength="50" onChange={this.handleChangeVenue} />
                                </div>
                                <div className="labelInput">
                                    <label>Event Date</label>
                                    <input type="date" name="eventDate" onChange={this.handleChangeDate} />
                                </div>
                                <div className="labelInput">
                                    <label>Event Duration</label>
                                    <input type="text" name="duration" maxLength="10" onChange={this.handleChangeDuration} />
                                </div>
                                <div className="labelInput">
                                    <label>Start Time</label>
                                    <input type="time" name="startTime" onChange={this.handleChangeStartTime} />
                                </div>
                                <div className="labelInput">
                                    <label>KeyNoteSpeaker</label>
                                    <input type="text" name="keyNoteSpeaker" maxLength="30" onChange={this.handleChangeSpeaker} />
                                </div>
                                <div className="gridFull labelInput">
                                    <label>Description</label>
                                    <textarea name="description" rows="5" maxLength="250" onChange={this.handleChangeDescription}></textarea>
                                </div>
                                <br></br>
                                <button>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SaveEvent;
import axios from '../../../axios/axios';
import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { withStyles, Grid, Typography, TextField, Button } from '@material-ui/core';
import Notifier, { openSnackbar } from '../../utility/Notifier';

const styles = theme => ({
    container: {
        background: 'linear-gradient(45deg, #29B5C3 30%, #21CBF3 90%)',
        color: '#FFFFFF',
        margin: '0 0 2em 0',
    },
    quote: {
        width: '300px',
        fontSize: '1.5em',
        lineHeight: '1.7',
        color: '#FFFFFF',
        textAlign: 'center',
        margin: '1.5em 0',
    },
    textField: {
        width: '37%',
        margin: '1em 2em',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    }, cssLabel: {
        color: '#FFFFFF',
        fontSize: '1rem',
    },
    cssOutlinedInput: {
        borderColor: '#FFFFFF',
    },
    buttonContainer: {
        width: '40%',
        margin: '2em 1.8em 2em 1.8em',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    button: {
        display: 'block',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        color: '#FFFFFF',
        letterSpacing: '2px',
        fontSize: '13px',
        fontWeight: 700,
    },
});

class ContactSmall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            page: "",
        }
    }

    componentDidMount() {
        this.setState({
            page: this.props.page
        });
    };

    firstName = (e) => {
        e.preventDefault();
        const firstName = e.target.value;
        this.setState(() => ({ firstName }));
    };

    lastName = (e) => {
        e.preventDefault();
        const lastName = e.target.value;
        this.setState(() => ({ lastName }));
    };

    email = (e) => {
        e.preventDefault();
        const email = e.target.value;
        this.setState(() => ({ email }));
    };

    phone = (e) => {
        e.preventDefault();
        const phone = e.target.value;
        this.setState(() => ({ phone }));
    };

    sendMail = (e) => {
        e.preventDefault();
        if (this.state.firstName === '' || this.state.firstName === undefined) {
            openSnackbar({ message: "Please Enter First Name" });
        } else if (this.state.lastName === '' || this.state.lastName === undefined) {
            openSnackbar({ message: "Please Enter Last Name" });
        } else if (this.state.email === '' || this.state.email === undefined) {
            openSnackbar({ message: "Please Enter Valid email" });
        } else if (this.state.phone === '' || this.state.phone === undefined) {
            openSnackbar({ message: "Please Enter Contact" });
        } else {

            axios.post("contactUs/smallContact", this.state)
                .then((result) => {
                    if (result.data.success) {
                        openSnackbar({ message: 'Thank you for contacting us!' });
                        this.setState({
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: ""
                        });
                    } else {
                        openSnackbar({ message: result.data.msg });
                    }
                }).catch(exception => {
                    console.log(exception);
                    openSnackbar({ message: 'Something went wrong' });
                });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container
                justify="center"
                className={classes.container}
            >
                <Notifier />

                <Grid item
                    container
                    xs={12}
                    lg={9}
                >
                    <Grid item
                        container
                        justify="center"
                        xs={12}
                        md={6}
                        lg={6}
                    >
                        <blockquote className={classes.quote}>
                            <i><b>
                                {this.props.title}
                            </b></i>
                        </blockquote>
                    </Grid>
                    <Grid item
                        container
                        xs={11}
                        sm={12}
                        md={6}
                        lg={6}
                    >
                        <form onSubmit={this.sendMail}>
                            <TextField
                                id="filled-name"
                                label="First Name"
                                className={classes.textField}
                                onChange={this.firstName}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                    },
                                }}
                            />
                            <TextField
                                id="filled-name"
                                label="Last Name"
                                className={classes.textField}
                                onChange={this.lastName}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                    },
                                }}
                            />
                            <TextField
                                id="filled-email-input"
                                label="Email"
                                className={classes.textField}
                                onChange={this.email}
                                type="email"
                                name="email"
                                autoComplete="email"
                                margin="normal" InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                    },
                                }}
                            />
                            <TextField
                                id="filled-number"
                                label="Phone"
                                className={classes.textField}
                                onChange={this.phone}
                                type="number"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                    },
                                }}
                            />
                            <Grid item
                                container
                                className={classes.buttonContainer}
                                xs={12}
                            >
                                <Button type="submit" variant="contained" className={classes.button}>
                                    CONNECT
                            </Button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
};

ContactSmall.propTypes = {
    classes: Proptypes.object.isRequired,
};

export default withStyles(styles)(ContactSmall);
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Notifier, { openSnackbar } from '../utility/Notifier'
import { withRouter } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import axios from '../../axios/axios'
import { cleanUrl } from '../../functions'

const styles = theme => ({
  sectionVideo: {
    backgroundColor: '#29B5C3',
    color: '#fff',
    marginTop: '2em',
    padding: '50px 0',
    textAlign: 'center',
  },
  '& h2': {
    fontSize: '30px',
    fontFamily: 'Playfair Display, serif',
    marginBottom: '5px',
    fontWeight: '400'
  },
  sectionArticlePreview: {
    width: '80%',
    paddingTop: '25px',
    paddingBottom: '20px',
    margin: '0 10%',
    cursor: 'pointer',
    '& figure': {
      width: '275px',
      height: '155px',
      marginBottom: '15px',
      '& img': {
        width: '275px',
        height: '155px',
        objectFit: 'cover',
        margin: '0 auto'
      }
    },
    '& div': {
      marginTop: '10px',
      '& h3': {
        fontSize: '18px',
        fontFamily: 'Playfair Display, serif',
        fontWeight: 400,
        textAlign: 'left'
      }
    }
  }
})

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videoBlogs: [],
    }
  }

  componentDidMount() {
    this.fetchAllVideoBlogs()
  }

  fetchAllVideoBlogs = () => {
    const body = {
      tag: "video",
      offset: 0,
      limit: 3
    }
    axios.post('/blog/byTag', body)
      .then(result => {
        if (result.status === 200) {
          if (result.data.success) {
            this.setState({ videoBlogs: result.data.blogs })
          } else {
            openSnackbar({ message: result.data.message })
          }
        } else {
          openSnackbar({ message: result.data.message })
        }
      })
      .catch(err => openSnackbar({ message: err.message }))
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Notifier />
        <Grid container className={classes.sectionVideo} justify="center">
          <Grid item xs={12}>
            <h2>Video</h2>
          </Grid>
          {
            this.state.videoBlogs.map(videoBlog => {
              return (
                <Grid key={videoBlog.id} item xs={12} md={12} lg={3} onClick={() => {
                  const URL = `/magazine/blog-details?title=${cleanUrl(videoBlog.title)}&blog=${videoBlog.id}`
                  this.props.history.push(URL)
                }}>
                  <article className={classes.sectionArticlePreview}>
                    <figure>
                      <img src={videoBlog.imageURL} alt={videoBlog.imageURL} />
                    </figure>
                    <div>
                      <h3>{videoBlog.title}</h3>
                    </div>
                  </article>
                </Grid>
              )
            })
          }
          <Grid item xs={12}>
            <Button style={{ fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', color: '#fff' }} onClick={() => {
              this.props.history.push("/magazine/tag/video");
            }}>More<ArrowRightIcon /></Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

Video.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(Video))
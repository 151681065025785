import React from 'react';
import Services from '../services/Services';
import Goal from '../goal/Goal';
import Planner from '../planners/Planners';
import TaglineExcel from '../taglineexcel/TaglineExcel';
import Subscribe from '../subscribe/Subscribe';
import Blogs from '../blog/Blog';
import HeroHome from '../herohome/HeroHome';
import Testimonial from '../testimonial/Testimonial';
import EventSession from '../events/EventSession';

const Home = () => {
    return (
        <div>
            <HeroHome />
            <Services />
            <Goal />
            <Planner />
            <Subscribe />
            <EventSession />
            <Testimonial />
            <TaglineExcel />
            <Blogs />
        </div>
    );
}

export default Home;
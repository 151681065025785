import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';
import './admin.css';

class ManageBlog extends Component {

    constructor(props) {
        super();
    }

    render() {
        return (
            <div className="containeradmin">
                <div className="admin-sidebar">
                    <AdminNav
                        logout={() => {
                            sessionStorage.clear('jwtToken');
                            this.props.history.push("/admin");
                            return <Redirect to="/admin" />
                        }}
                        dashboard={() => {
                            this.props.history.push("/dashboard");
                            return <Redirect to="/dashboard" />
                        }} />
                </div>
                <section id="blog-dashboard">
                    <h3>Manage Blogs</h3>
                    <div className="blog-dashboard-menu">
                        <div className="menu-box card" onClick={() => {
                            this.props.history.push("/dashboard/manageAuthor");
                        }}>
                            <img src="https://image.flaticon.com/icons/svg/1088/1088266.svg" alt="Authors" />
                            <p><strong>Author Management</strong></p>
                        </div>

                        <div className="menu-box card" onClick={() => {
                            this.props.history.push("/dashboard/blogManagement");
                        }}>
                            <img src="https://image.flaticon.com/icons/svg/1289/1289299.svg" alt="Blog" />
                            <p><strong>Blogs Management</strong></p>
                        </div>
                        <div className="menu-box card" onClick={() => {
                            this.props.history.push("/dashboard/blogSection");
                        }}>
                            <img src="https://image.flaticon.com/icons/svg/1907/1907556.svg" alt="Blog" />
                            <p><strong>Section Management</strong></p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ManageBlog;
import React, { Fragment } from 'react';
import Subscribe from '../subscribe/Subscribe';
import comingSoon from '../../images/coming-soon.png';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    container: {
        width: '70%',
        margin: '0 auto',
        padding: '0 15px',
    },
    responsiveImg: {
        width: '100%',
        height: 'auto',
    },
});

const Coming = props => {
    const { classes } = props
    return (
        <Fragment>
            <div className={classes.container}>
                <img className={classes.responsiveImg} src={comingSoon} alt="coming soon" />
            </div>
            <Subscribe />
        </Fragment>
    );
};

Coming.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Coming);